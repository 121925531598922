import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { BulkMessageReportsDataGrid } from '../BulkMessageReportsDataGrid/BulkMessageReportsDataGrid';
import { SenderFilterSelect } from '../SenderFilterSelect/SenderFilterSelect';

export function BulkMessageReportsTab() {
  const [senderFilter, setSenderFilter] = useState<string | undefined>(undefined);

  return (
    <Stack gap={2} width={800}>
      <SenderFilterSelect
        onSenderFilterChange={(newSenderFilter) => {
          setSenderFilter(newSenderFilter);
        }}
      />
      <BulkMessageReportsDataGrid senderFilter={senderFilter} />
    </Stack>
  );
}
