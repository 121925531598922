import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';
import { confirmQuestionnaireSubmission, discardQuestionnaireSubmission, } from '../../../apis/website/Api';
import { PRIMARY_COLOR } from '../../../util/theme';


interface QuestionnaireDialogContent {
  title: string;
  content: string;
  icon?: React.ReactNode;
  iconColor?: string;
}

const confirmationQuestionnaireSubmissionSuccess: QuestionnaireDialogContent = {
  title: 'Successfully Saved to EHR',
  content: "You are seeing this message because you saved a patient's data submission directly to EHR from an email you received.",
};

const confirmationQuestionnaireSubmissionNotFound: QuestionnaireDialogContent = {
  title: 'This Questionnaire Does Not Exist',
  content: 'This questionnaire does not exist or has been actioned/submitted before.',
  icon: <InfoOutlinedIcon />,
  iconColor: PRIMARY_COLOR,
};

const confirmationQuestionnaireSubmissionPatientOpen: QuestionnaireDialogContent = {
  title: 'Please Try Again',
  content: "There is another instance of this patient's data open in the EHR. Please make sure all other instances are closed and try again.",
  icon: <InfoOutlinedIcon />,
  iconColor: PRIMARY_COLOR,
};

const confirmationQuestionnaireSubmissionNoInstanceOpen: QuestionnaireDialogContent = {
  title: 'Save Unsuccessful',
  content: "Something went wrong. We weren't able to save the patient's data submission. Please check your Electronic Health Record connections and try again.",
  icon: <InfoOutlinedIcon />,
  iconColor: PRIMARY_COLOR,
};

const confirmationQuestionnaireSubmissionError: QuestionnaireDialogContent = {
  title: 'Save Unsuccessful',
  content: "Something went wrong. We weren't able to save the patient's data submission.",
  icon: <InfoOutlinedIcon />,
  iconColor: PRIMARY_COLOR,
};

const discardQuestionnaireSubmissionSuccess: QuestionnaireDialogContent = {
  title: 'Discard Successful',
  content: 'The data submission was successfully discarded. It has not been saved to the EHR.',
};

const discardQuestionnaireSubmissionNotFound: QuestionnaireDialogContent = {
  title: 'This Questionnaire Does Not Exist',
  content: 'This questionnaire does not exist or has been actioned/submitted before.',
  icon: <InfoOutlinedIcon />,
  iconColor: PRIMARY_COLOR,
};

const discardQuestionnaireSubmissionError: QuestionnaireDialogContent = {
  title: 'Discard Unsuccessful',
  content: 'Something went wrong. The data submission was not successfully discarded.',
  icon: <InfoOutlinedIcon />,
  iconColor: PRIMARY_COLOR,
};

enum ActionTypeKey {
  Confirm = 'confirmQuestionnaireId',
  Discard = 'discardQuestionnaireId',
}

interface Props {
  organisation: string;
}

export default function QuestionnaireSubmissionDialog({ organisation }: Props) {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [actionTypeKey, setActionTypeKey] = useState<ActionTypeKey | null>(null);
  const [dialogContent, setDialogContent] = useState<QuestionnaireDialogContent | null>(null)

  // Get the correct params from the URL
  const confirmQuestionnaireId = searchParams.get(ActionTypeKey.Confirm);
  const discardQuestionnaireId = searchParams.get(ActionTypeKey.Discard);

  useEffect(() => {
    setActionTypeKey(null);

    // Call the confirmation or discard endpoints based on the passed params
    if (confirmQuestionnaireId && discardQuestionnaireId) {
      console.warn('Both confirm and discard search params were set. This is not supported.');
    } else if (confirmQuestionnaireId) {
      setLoading(true);
      setActionTypeKey(ActionTypeKey.Confirm);
      confirmQuestionnaireSubmission(organisation, confirmQuestionnaireId)
        .then(() => setDialogContent(confirmationQuestionnaireSubmissionSuccess))
        .catch((error) => {
          const requestStatus = error.cause?.response?.status;
          if (requestStatus === 404) {
            setDialogContent(confirmationQuestionnaireSubmissionNotFound)
          } else if (requestStatus === 409) {
            setDialogContent(confirmationQuestionnaireSubmissionPatientOpen)
          } else if (requestStatus === 502) {
            setDialogContent(confirmationQuestionnaireSubmissionNoInstanceOpen)
          } else {
            setDialogContent(confirmationQuestionnaireSubmissionError)
          }
        })
        .finally(() => setLoading(false));
    } else if (discardQuestionnaireId) {
      setLoading(true);
      setActionTypeKey(ActionTypeKey.Discard);
      discardQuestionnaireSubmission(organisation, discardQuestionnaireId)
        .then(() => setDialogContent(discardQuestionnaireSubmissionSuccess))
        .catch((error) => {
          const requestStatus = error.cause?.response?.status;
          if (requestStatus === 404) {
            setDialogContent(discardQuestionnaireSubmissionNotFound)
          } else {
            setDialogContent(discardQuestionnaireSubmissionError)
          }
        })
        .finally(() => setLoading(false));
    }
  }, [confirmQuestionnaireId, discardQuestionnaireId]);

  if (loading) {
    return (
      <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
    )
  }

  // If there is no action type set nor dialog content, there's no point in
  // rendering anything else!
  if (!actionTypeKey || !dialogContent) {
    return null;
  }

  const closeDialog = () => {
    // Remove the actioned parameter from the URL (the subsequent re-render will
    // make sure the dialog is not displayed)
    setSearchParams((prev) => {
      prev.delete(actionTypeKey);
      return prev;
    })
  }

  return (
    <Dialog open={true} maxWidth="xs" fullWidth={true}>
      <DialogTitle>
        {dialogContent.icon && (
          <Box
            component="span"
            sx={{
              marginRight: '8px',
              display: 'inline-flex',
              verticalAlign: 'middle',
              color: dialogContent.iconColor,
            }}
          >
            {dialogContent.icon}
          </Box>
        )}
        {dialogContent.title}
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">{dialogContent.content}</Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={closeDialog}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  );
}
