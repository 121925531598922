import { z } from 'zod';
import { FrontendDefinitions, OrganisationDetails, OrganisationEmail } from '../../../domain/Organisation';
import { coalesce } from '../../../util/schema';
import { PatientTaskCategory } from '../../../domain/PatientFacing';

const getOrganisationEmailsSchema = z.object({
  emailAddresses: z.array(
    z.object({
      email: z.string(),
      verified: z.boolean(),
    }),
  ),
});

export type GetOrganisationEmailsResponse = z.infer<typeof getOrganisationEmailsSchema>;

export function parseGetOrganisationEmailsResponse(data: unknown): GetOrganisationEmailsResponse {
  return getOrganisationEmailsSchema.parse(data);
}

const organisationDetailsSchema = z
  .object({
    organisation: z.string(),
    name: coalesce(z.string()),
    address: coalesce(
      z.object({
        line1: z.string(),
        line2: coalesce(z.string()),
        line3: coalesce(z.string()),
        line4: coalesce(z.string()),
        town: z.string(),
        postcode: z.string(),
        county: coalesce(z.string()),
        country: coalesce(z.string()),
      }),
    ),
    ehr: coalesce(z.enum(['emis', 'tpp'])),
    contractExpiryDate: coalesce(z.string()),
    emisCdb: coalesce(z.string()),
    onSiteEncryption: coalesce(z.boolean()),
    advancedFiltering: coalesce(z.boolean()),
  })
  .and(getOrganisationEmailsSchema);

const getOrganisationDetailsSchema = z.record(z.string(), organisationDetailsSchema.nullable());

export function parseGetOrganisationDetailsResponse(data: unknown): Record<string, OrganisationDetails | null> {
  return getOrganisationDetailsSchema.parse(data);
}

const cliniciansSchema = z
  .object({
    data: z.object({
      clinicians: z.array(
        z
          .object({
            gp_id: z.string(),
            gp_name: z.string(),
          })
          .transform(({ gp_id, gp_name }) => ({
            gpId: gp_id,
            gpName: gp_name,
          })),
      ),
    }),
  })
  .transform(({ data }) => data);

export type GetCliniciansResponse = z.infer<typeof cliniciansSchema>;

export function parseGetCliniciansResponse(data: unknown): GetCliniciansResponse {
  return cliniciansSchema.parse(data);
}

const frontendDefinitionsSchema = z
  .object({
    data: z.object({
      app_info: z.object({
        action_props: z.record(
          z.string(),
          z
            .object({
              ActionName: z.string(),
            })
            .transform(({ ActionName }) => ({
              actionName: ActionName,
            })),
        ),
        indication_props: z.record(
          z.string(),
          z
            .object({
              AltDescription: z.string(),
            })
            .transform(({ AltDescription }) => ({
              altDescription: AltDescription,
            })),
        ),
      }),
      emis_snomed_terms: z.record(z.string(), z.string()).nullish(),
      messaging_codes: z.record(z.string(), z.string()).nullish(),
    }),
  })
  .transform(({ data }) => ({
    appInfo: {
      actionProps: data.app_info.action_props,
      indicationProps: data.app_info.indication_props,
    },
    emisSnomedTerms: data.emis_snomed_terms ?? undefined,
    messagingCodes: data.messaging_codes ?? undefined,
  }));

export function parseGetFrontendDefinitionsResponse(data: unknown): FrontendDefinitions {
  return frontendDefinitionsSchema.parse(data);
}

const patientTaskCategoriesSchema = z
  .object({
    data: z.array(
      z.object({
        code: z.string(),
        name: z.string(),
      }),
    ),
  })
  .transform(({ data }) => data);

export function parseGetPatientTaskCategoriesResponse(data: unknown): PatientTaskCategory[] {
  return patientTaskCategoriesSchema.parse(data);
}

const searchOrganisationsResponseSchema = z.record(z.string(), organisationDetailsSchema);

export function parseSearchOrganisationResponse(data: unknown): Record<string, OrganisationDetails> {
  return searchOrganisationsResponseSchema.parse(data);
}

const registerOrResendOrganisationEmailSchema = z
  .object({
    data: z.object({
      email: z.string(),
      verified: z.boolean(),
    }),
  })
  .transform(({ data }) => data);

export function parseRegisterOrResendOrganisationEmailResponse(data: unknown): OrganisationEmail {
  return registerOrResendOrganisationEmailSchema.parse(data);
}
