import { RuleOrganisationConfig } from '../domain/ScheduledMessaging';

export const APPOINTMENT_GET_SLOTS_CONFIG_HTTP_TIMEOUT = 20_000;
export const GET_RULE_ANALYTICS_TIMEOUT = 20_000;
export const HTTP_TIMEOUT = 10_000;
export const TIMEOUT = 'timeout';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const UK_DATE_FORMAT = 'DD/MM/YYYY';

export const X_TRACE_ID = 'X-Trace-Id';

export const EXPIRY_DATE_AFTER_CLEARING = '3000-01-01T00:00:00.000Z';
export const WHITELISTED_DOMAINS = ['abtrace.co', 'nhs.net', 'nhs.uk'];
export const WHITELISTED_MAIN_DOMAINS = ['nhs.net', 'nhs.uk'];
export const NOT_CURRENTLY_SET = 'Not currently set';
export const ORGANISATION_EMAIL_VERIFIED = 'Verified';
export const ORGANISATION_EMAIL_UNVERIFIED = 'Unverified';

export const MULTIPLE_CHOICE_SELECT_TAG_LIMIT = 5;

export const APP_API_NAME = 'app';
export const APPOINTMENT_BOOKING_API_NAME = 'appointmentBooking';
export const AUTH_API_NAME = 'auth';
export const MESSAGING_API_NAME = 'messaging';
export const QOFOPT_API_NAME = 'qofopt';
export const PATIENT_FACING_API_NAME = 'patientFacing';
export const SCHEDULED_MESSAGING_API_NAME = 'scheduledMessaging';
export const WEBSITE_API_NAME = 'website';

export const MUI_LICENSE_KEY =
  '6afbbceb354a2672d7b47c2014f1d0d6Tz05OTk3MixFPTE3NjA1NDI1NDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==';
export const QUERY_STRING_ORGANISATION_DETAILS = 'QUERY_STRING_ORGANISATION_DETAILS';

export const SCHEDULED_MESSAGING_DEFAULT_SCHEDULE: RuleOrganisationConfig['schedule'] = {
  daysOfWeek: ['WED'],
  hour: 14,
};

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

// PMT uses extreme values to bypass backend limitations.
// The backend does not provide the minimum or maximum values
// for the population dataset, so PMT sends predefined extreme
// values to ensure all relevant data is captured.

// Specifically, when dealing with 'days overdue', the backend
// defaults to 100,000 when the action aka task was never done.
// To ensure that no data is excluded within the lower or upper bounds,
// PMT sends values above 100,000 for the maximum and -100,000 for the minimum.

// The maximum age value is also set to 100,000 for the sake of simplicity,
// using a consistent extreme value across different parameters rather than
// introducing multiple distinct extreme values.

export const PMT_DAYS_OVERDUE_MIN = -100000;
export const PMT_DAYS_OVERDUE_MAX = 100000;
export const PMT_AGE_MAX = 100000;

export const MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS = 90;
