import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function StaffNoteEditor() {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Controller
      control={control}
      name="staffNote"
      render={({ field }) => (
        <>
          <TextField
            {...field}
            label="Staff note"
            error={Boolean(errors.staffNote)}
            helperText={String(errors.staffNote?.message ?? '')}
            size="small"
            multiline
            minRows={3}
          />

          <Typography textAlign="right" variant="caption" color={field.value?.length > 1000 ? 'error' : 'text.primary'}>
            Number of characters: {field.value?.length ?? 0}/1000
          </Typography>
        </>
      )}
      rules={{
        validate: (value: string) => {
          if (value.length > 1000) {
            return 'Staff note cannot exceed 1000 characters';
          }

          return true;
        },
      }}
    />
  );
}
