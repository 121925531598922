import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { MouseEvent, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import ConfigContext from '../../context/ConfigContext';
import userContext from '../../context/UserContext';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../redux/reducers/notificationsReducer';
import * as AuthApi from '../../apis/auth/AuthApi';
import { friendlierAccountVerificationError } from '../../util/errors/errors';
import { URLS } from '../../util/urls';
import { resendSignUpCode } from 'aws-amplify/auth';
import { ApiError } from '../../services/ApiError';

export default function AccountVerificationPage() {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const config = useContext(ConfigContext);
  const { updateIsVerified } = useContext(userContext);

  async function verifyAccount(username: string, code: string) {
    try {
      setSubmitting(true);
      await AuthApi.verifyAccount(username, code, config.amplify.auth.audience);
      dispatch(enqueueInfoNotification('Account verified. You may now log in'));
      updateIsVerified(true);
      navigate(URLS.POST_VERIFICATION);
    } catch (error) {
      if (error instanceof ApiError) {
        dispatch(
          enqueueErrorNotification(`Unable to verify the account: ${friendlierAccountVerificationError(error)}`),
        );
      } else {
        dispatch(enqueueErrorNotification('Unable to verify the account', error));
      }
    } finally {
      setSubmitting(false);
    }
  }

  async function resendVerificationEmail(username: string) {
    try {
      await resendSignUpCode({ username });
      dispatch(enqueueInfoNotification('Verification e-mail sent, please check your inbox.'));
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to send the account verification e-mail', error));
    }
  }

  const [searchParams] = useSearchParams();

  const username = searchParams.get('username');
  const code = searchParams.get('code');

  return (
    <Stack alignItems="center" justifyContent="center" height="80vh" mt={8}>
      <Typography variant="h5" gutterBottom>
        Verify Account
      </Typography>
      {!username || !code ? (
        <Alert severity="error">Invalid URL, please try again.</Alert>
      ) : (
        <>
          <Stack alignItems="center">
            <Typography>Please click the button to complete registration</Typography>
            <Box mt={2} mb={2}>
              <Box width={150}>
                <LoadingButton
                  variant="contained"
                  onClick={() => verifyAccount(username!, code!)}
                  loading={submitting}
                  fullWidth
                >
                  Verify
                </LoadingButton>
              </Box>
            </Box>

            <Box mb={1}>
              <Typography variant="body1" mt={1}>
                Link not working? Click{' '}
                <Link
                  href="#"
                  onClick={(ev: MouseEvent) => {
                    ev.preventDefault();
                    resendVerificationEmail(username);
                  }}
                >
                  here
                </Link>{' '}
                to get a new one
              </Typography>
            </Box>

            <Divider sx={{ width: '100%' }} />

            <Box mt={1}>
              <Typography variant="body1" textAlign="center">
                Already verified?{' '}
                <Link component={RouterLink} to={URLS.LOGIN}>
                  Sign-in
                </Link>{' '}
              </Typography>
            </Box>
          </Stack>
        </>
      )}
    </Stack>
  );
}
