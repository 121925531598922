import { z } from 'zod';
import {
  EMAIL_AND_SMS,
  EMAIL_ONLY,
  EMAIL_OR_SMS,
  Rule,
  RuleAnalytics,
  RuleExecutionReport,
  RuleExecutions,
  RuleStats,
  ScheduledMessagingOrganisationConfig,
  SMS_ONLY,
  SMS_OR_EMAIL,
} from '../../../domain/ScheduledMessaging';
import { coalesce } from '../../../util/schema';

const patientStatusSchema = z.enum(['green', 'grey', 'yellow', 'orange', 'red', 'black']);

const monthSchema = z.enum([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]);

const sortByColumnSchema = z.enum(['nhs_number', 'status', 'days_overdue', 'dob']);

const sortByOrderSchema = z.enum(['ASC', 'DESC']);

const ruleSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  actions: coalesce(z.array(z.string())),
  indications: coalesce(z.array(z.string())),
  excludedIndications: coalesce(z.array(z.string())),
  queryId: coalesce(z.string()),
  statuses: coalesce(z.array(patientStatusSchema)),
  monthOfBirth: coalesce(monthSchema),
  useCurrentMonthAsDob: coalesce(z.boolean()),
  useNextMonthAsDob: coalesce(z.boolean()),
  gpIds: coalesce(z.array(z.string())),
  daysOverdue: coalesce(
    z.object({
      from: z.number(),
      to: z.number(),
    }),
  ),
  age: coalesce(
    z.object({
      from: z.number(),
      to: z.number(),
    }),
  ),
  sortBy: coalesce(
    z.object({
      column: sortByColumnSchema,
      order: sortByOrderSchema,
    }),
  ),
  organisation: coalesce(z.string()),
  version: coalesce(z.literal('v1')),
});

export function parseRuleResponse(response: unknown): Rule {
  return ruleSchema.parse(response);
}

const rulesSchema = z.array(ruleSchema);

export function parseRulesResponse(response: unknown): Rule[] {
  return rulesSchema.parse(response);
}

const sendPreferenceSchema = z.enum([EMAIL_AND_SMS, EMAIL_OR_SMS, SMS_OR_EMAIL, EMAIL_ONLY, SMS_ONLY]);

const ruleConfigSchema = z.object({
  enabled: z.boolean(),
  patientSelectionCriteria: z.object({
    maxDefinedByPractice: z.number(),
    maxAllowedByAbtrace: z.number(),
    minDaysBetweenMessagingRounds: z.number(),
    maxMessagingRounds: z.number(),
    doubleBookingPrevention: coalesce(
      z.object({
        enabled: z.boolean(),
        embargoWindow: z.object({
          fromDaysAgo: z.number().min(0),
          toDaysAhead: z.number().min(0),
        }),
        slotTypes: z.array(z.string()),
        gpIds: z.array(z.string()),
      }),
    ),
  }),
  reportsEmailAddress: coalesce(z.string()),
  preference: coalesce(sendPreferenceSchema),
  emailTemplate: coalesce(
    z.object({
      subject: z.string(),
      content: z.string(),
    }),
  ),
  smsTemplate: coalesce(z.object({ message: z.string() })),
  appointments: z.object({
    addInvitationLink: z.boolean(),
    description: coalesce(z.string()),
    selfBooking: coalesce(
      z.object({
        bookableWithinDays: z.number().min(1),
        siteIds: z.array(z.string()).nonempty().nullish(),
        staffIds: z.array(z.string()).nonempty().nullish(),
        slotTypes: z.array(z.string()).nonempty().nullish(),
      }),
    ),
  }),
  messageRecordingChoice: z.enum(['freetext', 'attachment']),
  extraCodes: z.array(z.string()),
  staffNote: coalesce(z.string()),
  patientNeedsSummary: coalesce(z.boolean()),
  schedule: z.object({
    daysOfWeek: z.array(z.enum(['MON', 'TUE', 'WED', 'THU', 'FRI'])),
    hour: z.number().int(),
  }),
});

export const organisationConfigSchema = z.object({
  available: z
    .boolean()
    .nullish()
    .transform((value) => value ?? true),
  reportsRecipientUserId: coalesce(z.string()),
  reportsEmailAddress: coalesce(z.string()),
  partnerApiCredentials: coalesce(
    z.object({
      username: z.string(),
      password: z.string(),
    }),
  ),
  rules: coalesce(z.record(z.string(), ruleConfigSchema)),
});

export function parseOrganisationConfigResponse(response: unknown): ScheduledMessagingOrganisationConfig {
  return organisationConfigSchema.parse(response);
}

const ruleExecutionHeaderSchema = z.object({
  executionId: z.string(),
  status: z.enum(['SUCCESS', 'FAILURE', 'RUNNING']),
  startedAt: z.string().pipe(z.coerce.date()),
  finishedAt: coalesce(z.string().pipe(z.coerce.date())),
});

const getRuleExecutionsResponseSchema = z.object({
  executions: z.array(ruleExecutionHeaderSchema),
  nextScheduledRun: coalesce(z.string().pipe(z.coerce.date())),
});

export function parseRuleExecutionHistoryResponse(response: unknown): RuleExecutions {
  return getRuleExecutionsResponseSchema.parse(response);
}

const ruleConfigSnapshotSchema = z.object({
  enabled: z.boolean(),
  patientSelectionCriteria: z.object({
    maxDefinedByPractice: z.number(),
    maxAllowedByAbtrace: z.number(),
    minDaysBetweenMessagingRounds: z.number(),
    maxMessagingRounds: z.number(),
    doubleBookingPrevention: coalesce(
      z.object({
        enabled: z.boolean(),
        embargoWindow: z.object({
          fromDaysAgo: z.number().min(0),
          toDaysAhead: z.number().min(0),
        }),
        slotTypes: z.array(z.string()),
        gpIds: z.array(z.string()),
      }),
    ),
  }),
  preference: sendPreferenceSchema,
  emailTemplate: coalesce(
    z.object({
      subject: z.string(),
      content: z.string(),
    }),
  ),
  smsTemplate: coalesce(z.object({ message: z.string() })),
  appointments: z.object({
    addInvitationLink: z.boolean(),
    description: coalesce(z.string()),
    selfBooking: coalesce(
      z.object({
        bookableWithinDays: z.number().min(1),
        siteIds: z.array(z.string()).nonempty().nullish(),
        staffIds: z.array(z.string()).nonempty().nullish(),
        slotTypes: z.array(z.string()).nonempty().nullish(),
      }),
    ),
  }),
  messageRecordingChoice: z.enum(['freetext', 'attachment']),
  extraCodes: z.array(z.string()),
  staffNote: coalesce(z.string()),
  patientNeedsSummary: coalesce(z.boolean()),
  schedule: coalesce(
    z.object({
      daysOfWeek: z.array(z.enum(['MON', 'TUE', 'WED', 'THU', 'FRI'])),
      hour: z.number().int(),
    }),
  ),
});

const ruleExecutionReportSchema = z.object({
  executionId: z.string(),
  status: z.enum(['SUCCESS', 'FAILURE', 'RUNNING']),
  startedAt: z.string().pipe(z.coerce.date()),
  finishedAt: coalesce(z.string().pipe(z.coerce.date())),
  ruleSnapshot: z.object({}).passthrough(),
  ruleConfigSnapshot: ruleConfigSnapshotSchema,
  reportEmailed: coalesce(z.boolean()),
  reportS3Path: coalesce(z.string()),
  summary: coalesce(
    z.object({
      matchedPatients: z.number().int(),
      surveyed: z.number().int(),
      selected: z.number().int(),
      emailed: coalesce(z.number().int()),
      emailsDeliveryFailed: coalesce(z.number().int()),
      texted: coalesce(z.number().int()),
      textsDelivered: coalesce(z.number().int()),
      textsDeliveryFailed: coalesce(z.number().int()),
      unreachable: z.number().int(),
      patientRecordUpdateErrors: z.number().int(),
      hadPatientsOpenInTpp: coalesce(z.boolean()),
    }),
  ),
  error: coalesce(z.string()),
});

const getRuleExecutionReportResponseSchema = z
  .object({
    execution: ruleExecutionReportSchema,
  })
  .transform(({ execution }) => execution);

export function parseRuleExecutionReportResponse(response: unknown): RuleExecutionReport {
  return getRuleExecutionReportResponseSchema.parse(response);
}

const csvReportSchema = z.string();

export function parseCsvReportResponse(response: unknown): string {
  return csvReportSchema.parse(response);
}

const ruleStatsSchema = z.object({
  matchingPatients: z.number().int(),
});

export function parseRuleStatsResponse(response: unknown): RuleStats {
  return ruleStatsSchema.parse(response);
}

const ruleAnalyticsSchema = z.object({
  breakdown: coalesce(
    z.object({
      undergoingRecall: z.record(z.string(), z.array(z.string())),
      outsideRecall: z.record(z.string(), z.array(z.string())),
      missingContactDetails: z.array(z.string()),
      dissented: z.array(z.string()),
      maxedOutMessagingRounds: z.array(z.string()),
      tooOverdue: z.array(z.string()),
      excluded: z.array(z.string()),
    }),
  ),
  totals: z.object({
    undergoingRecall: z.record(z.string(), z.number().int()),
    outsideRecall: z.record(z.string(), z.number().int()),
    missingContactDetails: z.number().int(),
    dissented: z.number().int(),
    maxedOutMessagingRounds: z.number().int(),
    tooOverdue: z.number().int(),
    excluded: z.number().int(),
  }),
});

export function parseRuleAnalyticsResponse(response: unknown): RuleAnalytics {
  return ruleAnalyticsSchema.parse(response);
}
