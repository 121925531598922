import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React from 'react';
import { useOrganisationDetails } from '../../../hooks/useOrganisationDetails/useOrganisationDetails';
import { currentTheme } from '../../../util/theme';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import { NoDataToDisplay } from '../../shared/NoDataToDisplay/NoDataToDisplay';
import { PatientContactDetails } from './PatientContactDetails/PatientContactDetails';
import { PatientTasks } from './PatientTasks/PatientTasks';

interface Props {
  organisation: string;
  enabled: boolean;
}

export function PatientFacingServices({ organisation, enabled }: Props) {
  const { organisations: organisationsDetails, loading: isLoadingEhr } = useOrganisationDetails([organisation]);
  const ehr = organisationsDetails ? organisationsDetails[organisation]?.ehr : '';
  return (
    <Box
      sx={{
        '& .PatientFacingTypography': {
          paddingTop: '16px',
        },
      }}
    >
      <Typography variant="subtitle1" className="PatientFacingTypography">
        Configure what your patients can see and do on the patient-facing website.
      </Typography>

      <Divider className="PatientFacingTypography" />

      <Typography variant="h6" className="PatientFacingTypography">
        Practice Contact Details
      </Typography>
      <Typography variant="subtitle1" className="PatientFacingTypography">
        Set your practice&apos;s contact details. If you choose not to configure these contact details, no specific
        contact information will be displayed.
      </Typography>
      <PatientContactDetails organisation={organisation} enabled={enabled} />

      <Divider className="PatientFacingTypography" />

      <Typography variant="h6" className="PatientFacingTypography">
        Patient Tasks
      </Typography>

      <Typography variant="subtitle1" className="PatientFacingTypography">
        You may add instructions for your patients about how they may take action for a given task. You can do so for
        each status a task may appear in.
      </Typography>
      <Typography variant="subtitle1" className="PatientFacingTypography">
        Default text will display for any active tasks if you provide no content.
      </Typography>

      <Typography variant="subtitle1" className="PatientFacingTypography">
        <Link
          sx={{ color: currentTheme.palette.nhsPrimary.main, textDecorationColor: 'inherit' }}
          href="https://help.abtrace.co/how-to-configure#patient-tasks"
          target="_blank"
          rel="noopener"
        >
          Learn more about Patient Tasks
        </Link>
      </Typography>

      <LoadingSection loading={isLoadingEhr}>
        {ehr && <PatientTasks organisation={organisation} enabled={enabled} ehr={ehr} />}
        {!ehr && <NoDataToDisplay customMessage="Couldn't load data" />}
      </LoadingSection>
    </Box>
  );
}
