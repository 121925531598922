import { AxiosInstance } from 'axios';
import { AUTH_API_NAME } from '../../util/constants';
import { getApiClient, httpGet, resetApiClient } from '../../util/api';

function client(): AxiosInstance {
  return getApiClient(AUTH_API_NAME);
}

export async function verifyAccount(username: string, code: string, audience: string): Promise<void> {
  return httpGet(client(), '/verify', {
    config: {
      params: {
        username,
        code,
        clientId: audience,
      },
    },
    publicApi: true,
  }) as Promise<void>;
}

export function resetClient() {
  resetApiClient(AUTH_API_NAME);
}
