import groupBy from '../../../../util/groupBy';

type Mark = { value: number; label?: string };

export type MarkCreationConfig = {
  max: number;
  labeledMarks?: Mark[];
};

export function getStep(value: number) {
  if (value < 50) return 1;
  if (value < 100) return 2;
  if (value < 250) return 5;
  if (value < 500) return 10;
  if (value < 1000) return 20;
  return 50;
}

export default function createMarks(config: MarkCreationConfig) {
  const { max, labeledMarks = [] } = config;

  if (max < 1) throw new Error('Max cannot be less than 1');

  const labeledMarkMap = groupBy(labeledMarks, 'value');
  const marks: Mark[] = [];

  function push(value: number) {
    const mark: Mark = { value };

    if (value === 1) mark.label = '1';
    if (value === max) mark.label = `${max}`;

    if (labeledMarkMap[value]) {
      const [labeled] = labeledMarkMap[value];

      mark.label = labeled.label ?? `${value}`;
    }

    marks.push(mark);
  }

  let step = 0;
  let value = 1;

  while (value < max) {
    push(value);

    step = getStep(value);
    value = value + step;
  }

  push(max);

  return marks;
}
