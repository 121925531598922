import { USER_INVITATIONS_QUERY_KEY } from '../../util/queries';
import * as Api from '../../apis/website/Api';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Invitation } from '../../domain/Invitation';

export interface UseInvitationsResult {
  invitationsQuery: UseQueryResult<Invitation[]>;
  accept: (organisation: string) => Promise<void>;
  decline: (organisation: string) => Promise<void>;
}

export function useInvitations(): UseInvitationsResult {
  const queryClient = useQueryClient();

  const invitationsQuery = useQuery({
    queryKey: [USER_INVITATIONS_QUERY_KEY],
    queryFn: () => Api.loadUserInvitations(),
    retry: false,
  });

  const acceptMutation = useMutation({
    mutationFn: (organisation: string) => Api.acceptInvitation(organisation),
    onSuccess: (_data, organisation) => {
      queryClient.setQueryData([USER_INVITATIONS_QUERY_KEY], (previousInvitations: Invitation[] | undefined) =>
        previousInvitations!.filter((invitation) => invitation.organisation !== organisation),
      );
    },
  });

  const declineMutation = useMutation({
    mutationFn: (organisation: string) => Api.declineInvitation(organisation),
    onSuccess: (_data, organisation) => {
      queryClient.setQueryData([USER_INVITATIONS_QUERY_KEY], (previousInvitations: Invitation[] | undefined) =>
        previousInvitations!.filter((invitation) => invitation.organisation !== organisation),
      );
    },
  });

  async function accept(organisation: string): Promise<void> {
    await acceptMutation.mutateAsync(organisation);
  }

  async function decline(organisation: string): Promise<void> {
    await declineMutation.mutateAsync(organisation);
  }

  return { invitationsQuery, accept, decline };
}
