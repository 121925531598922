import { AxiosInstance } from 'axios';
import { AppointmentSlotsConfig, AppointmentsConfig } from '../../domain/Appointments';
import {
  APPOINTMENT_BOOKING_API_NAME,
  APPOINTMENT_GET_SLOTS_CONFIG_HTTP_TIMEOUT,
  ISO_DATE_FORMAT,
} from '../../util/constants';
import dayjs from 'dayjs';
import { getApiClient, httpGet, httpPost, httpPut, resetApiClient } from '../../util/api';

function client(): AxiosInstance {
  return getApiClient(APPOINTMENT_BOOKING_API_NAME);
}

export async function getAppointmentsConfig(organisation: string): Promise<AppointmentsConfig> {
  return httpGet(client(), `/organisations/${organisation}/config`) as Promise<AppointmentsConfig>;
}

export async function updateAppointmentConfig(
  organisation: string,
  enabled: boolean,
  organisationEmail: string | null,
): Promise<void> {
  return httpPut(client(), `/organisations/${organisation}/config`, {
    enabled,
    organisationEmail: {
      address: organisationEmail,
    },
  }) as Promise<void>;
}

export async function verifyOrganisationEmail(organisation: string, verificationCode: string): Promise<void> {
  return httpPost(client(), `/organisations/${organisation}/config/verifyEmail`, {
    code: verificationCode,
  }) as Promise<void>;
}

export async function resendVerificationCode(organisation: string): Promise<void> {
  return httpPost(client(), `/organisations/${organisation}/config/resendEmail`, {}) as Promise<void>;
}

export async function getAppointmentSlotsConfig(
  organisation: string,
  ehr: string,
  dateFrom: Date,
  dateTo: Date,
): Promise<AppointmentSlotsConfig> {
  return httpGet(client(), `/organisations/${organisation}/slots/config`, {
    config: {
      params: {
        ehr,
        dateFrom: dayjs(dateFrom).format(ISO_DATE_FORMAT),
        dateTo: dayjs(dateTo).format(ISO_DATE_FORMAT),
      },
      timeout: APPOINTMENT_GET_SLOTS_CONFIG_HTTP_TIMEOUT,
    },
  }) as Promise<AppointmentSlotsConfig>;
}

export function resetClient() {
  resetApiClient(APPOINTMENT_BOOKING_API_NAME);
}
