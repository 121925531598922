import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import userContext from '../../../../context/UserContext';
import { LoadingSlider, LoadingSliderProps } from '../../../shared/LoadingSlider/LoadingSlider';
import createMarks, { MarkCreationConfig } from './createMarks';

type StrictlyMarkedSliderProps = Omit<LoadingSliderProps, 'min' | 'max' | 'marks' | 'step'> & {
  markCreationConfig: MarkCreationConfig;
};
function StrictlyMarkedSlider(props: StrictlyMarkedSliderProps) {
  const { markCreationConfig, ...rest } = props;
  const marks = useMemo(() => createMarks(markCreationConfig), [markCreationConfig.max]);

  return (
    <LoadingSlider
      {...rest}
      min={1}
      max={markCreationConfig.max}
      marks={marks}
      step={null}
      slots={{ mark: () => null }}
      sx={{ width: 'auto', flexGrow: 1, m: 0 }}
    />
  );
}

export function MaxPatientsSlider() {
  const { control } = useFormContext();

  const context = useContext(userContext);

  const user = context.user;
  const isSuperUser = user?.superUser;
  const isSupportUser = user?.supportUser;
  const canEditMaxCap = isSuperUser || isSupportUser;

  return (
    <Controller
      name="maxPatients"
      control={control}
      render={({
        field: {
          onChange,
          value: [maxDefinedByPractice, maxAllowedByAbtrace],
        },
      }) => (
        <Box display="flex" alignItems="center" gap={2} height={42}>
          <Typography color="text.secondary">Maximum number of patients to contact</Typography>
          {canEditMaxCap ? (
            <StrictlyMarkedSlider
              getAriaLabel={(index: number) =>
                index === 0
                  ? 'Maximum number of patients to contact'
                  : 'Maximum allowed number of patients to contact (Abtrace defined)'
              }
              markCreationConfig={{ max: 2000 }}
              value={[maxDefinedByPractice, maxAllowedByAbtrace]}
              onChange={(_ev, values) => onChange(values as number[])}
              onChangeCommitted={(_ev, values) => onChange(values as number[])}
              valueLabelFormat={(currentValue, index) =>
                index === 0 ? `Practice limit: ${currentValue}` : `Abtrace limit: ${currentValue}`
              }
              valueLabelDisplay="auto"
              loading={false}
              track={false}
              disableSwap
            />
          ) : (
            <StrictlyMarkedSlider
              aria-label="Maximum number of patients to contact"
              markCreationConfig={{ max: maxAllowedByAbtrace }}
              value={maxDefinedByPractice}
              onChange={(_ev, value) => onChange([value as number, maxAllowedByAbtrace])}
              onChangeCommitted={(_ev, value) => onChange([value as number, maxAllowedByAbtrace])}
              valueLabelDisplay="auto"
              loading={false}
            />
          )}
        </Box>
      )}
    />
  );
}
