import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../redux/reducers/notificationsReducer';
import { verifyOrganisationEmail } from '../../apis/app/AppApi';
import { friendlierEmailVerificationError } from '../../util/errors/errors';
import { ApiError } from '../../services/ApiError';
import { LoadingActionButton } from '../../components/shared/LoadingActionButton/LoadingActionButton';

export default function EmailVerificationPage() {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  async function verifyEmail(organisation: string, code: string) {
    try {
      setSubmitting(true);
      await verifyOrganisationEmail(organisation, code);
      dispatch(enqueueInfoNotification('Email verified.'));
      navigate(`/organisations/${organisation}`);
    } catch (error) {
      if (error instanceof ApiError) {
        dispatch(enqueueErrorNotification(`Unable to verify the email: ${friendlierEmailVerificationError(error)}`));
      } else {
        dispatch(enqueueErrorNotification('Unable to verify the email', error));
      }
      navigate('/');
    }
  }

  const [searchParams] = useSearchParams();

  const organisation = searchParams.get('org');
  const code = searchParams.get('code');

  return (
    <Stack alignItems="center" justifyContent="center" height="80vh" mt={8}>
      <Typography variant="h5" gutterBottom>
        Verify Email
      </Typography>
      {!organisation || !code ? (
        <Alert severity="error">Invalid URL, please try again.</Alert>
      ) : (
        <>
          <Stack alignItems="center">
            <Typography>Please click the button to complete registration</Typography>
            <Box mt={2} mb={2}>
              <Box width={150}>
                <LoadingActionButton
                  variant="contained"
                  onClick={() => verifyEmail(organisation, code)}
                  loading={submitting}
                  fullWidth
                >
                  Verify
                </LoadingActionButton>
              </Box>
            </Box>
          </Stack>
        </>
      )}
    </Stack>
  );
}
