import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { UseDialogConnector } from '../../../hooks/useDialog/useDialog';
import { Control, FieldError, FieldValues, useForm } from 'react-hook-form';
import NhsNetEmailField from '../../shared/NhsNetEmailField/NhsNetEmailField';

export interface EmailDialogConfig {
  email: string | null;
}

export function EmailDialog({ connector }: { connector: UseDialogConnector<EmailDialogConfig, string | null> }) {
  const [open, setOpen] = useState(connector.config !== null);
  const [submitting, setSubmitting] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      email: connector.config?.email ?? '',
    },
  });

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
      reset({
        email: connector.config?.email ?? '',
      });
    }
  }, [connector.config]);

  const close = () => {
    if (submitting) {
      return;
    }

    setOpen(false);
  };

  async function onSubmit({ email }) {
    setSubmitting(true);

    return connector
      // Whitespace is handled by the NhsNetEmailField component, but best to be
      // careful
      .submit(email.trim() || null)
      .then(close)
      .catch(() => { })
      .finally(() => {
        setSubmitting(false);
      });
  }

  if (!connector.config) {
    return <></>;
  }

  return (
    <form>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Appointment request forms E-Mail</DialogTitle>
        <DialogContent sx={{ width: '350px' }}>
          <Stack gap={2}>
            <Typography variant="body2">Appointment request forms will be sent to:</Typography>
            <FormControl fullWidth>
              <NhsNetEmailField
                id="email"
                name={'email'}
                control={control as unknown as Control<FieldValues>}
                error={errors.email as FieldError}
                rules={{}}
                label="E-Mail address"
                autoFocus={true}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={close} disabled={submitting}>
              Cancel
            </Button>
            <LoadingButton
              loading={submitting}
              disabled={!isDirty}
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>
  );
}
