import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios, { Axios, AxiosInstance, AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';
import { HTTP_TIMEOUT } from './constants';
import { handleApiError } from './errors/handleApiError';

const axiosInstances: Record<string, AxiosInstance> = {};

async function getIdToken(): Promise<string> {
  const session = await fetchAuthSession();
  const idToken = session.tokens?.idToken;

  if (!idToken) {
    throw new Error('Missing ID Token in session');
  }
  return idToken.toString();
}

export async function httpGet<Return>(
  client: AxiosInstance,
  url: string,
  options?: {
    config?: AxiosRequestConfig;
    nullOnError?: boolean;
    publicApi?: boolean;
  },
): Promise<Return | null> {
  return callApi(client.get, url, undefined, options?.config, options?.nullOnError, options?.publicApi);
}

export async function httpPost<Return, Body>(
  client: AxiosInstance,
  url: string,
  body: Body,
  options?: {
    config?: AxiosRequestConfig;
    nullOnError?: boolean;
    publicApi?: boolean;
  },
): Promise<Return | null> {
  return callApi(client.post, url, body, options?.config, options?.nullOnError, options?.publicApi);
}

export async function httpPut<Return, Body>(
  client: AxiosInstance,
  url: string,
  body: Body,
  options?: {
    config?: AxiosRequestConfig;
    nullOnError?: boolean;
    publicApi?: boolean;
  },
): Promise<Return | null> {
  return callApi(client.put, url, body, options?.config, options?.nullOnError, options?.publicApi);
}

export async function httpPatch<Return, Body>(
  client: AxiosInstance,
  url: string,
  body: Body,
  options?: {
    config?: AxiosRequestConfig;
    nullOnError?: boolean;
    publicApi?: boolean;
  },
): Promise<Return | null> {
  return callApi(client.patch, url, body, options?.config, options?.nullOnError, options?.publicApi);
}

export async function httpDelete<Return>(
  client: AxiosInstance,
  url: string,
  options?: {
    config?: AxiosRequestConfig;
    nullOnError?: boolean;
    publicApi?: boolean;
  },
): Promise<Return | null> {
  return callApi(client.delete, url, undefined, options?.config, options?.nullOnError, options?.publicApi);
}

async function callApi<Return, Body>(
  method: Axios['get'] | Axios['patch'] | Axios['post'] | Axios['put'] | Axios['delete'],
  url: string,
  body?: Body,
  config?: AxiosRequestConfig<Body>,
  nullOnError = false,
  publicApi = false,
): Promise<Return | null> {
  try {
    if (publicApi === false) {
      const idToken = await getIdToken();
      if (config === undefined) {
        config = {};
      }
      config.headers = { Authorization: idToken };
    }

    let response: AxiosResponse<Return>;
    if (body !== undefined) {
      response = await (method as Axios['post'] | Axios['patch'] | Axios['put'])(url, body, config);
    } else if (config !== undefined) {
      response = await (method as Axios['get'] | Axios['delete'])(url, config);
    } else {
      response = await method(url);
    }
    return response.data;
  } catch (error) {
    if (nullOnError === true && isAxiosError(error) && error.response?.status === 404) {
      return null;
    }

    handleApiError(error);
  }
}

export function getApiClient(apiName: string): AxiosInstance {
  if (axiosInstances[apiName] !== undefined && axiosInstances[apiName] !== null) {
    return axiosInstances[apiName];
  }

  const baseUrl = Amplify.getConfig().API?.REST?.[apiName]?.endpoint;

  if (!baseUrl) {
    throw new Error(`Missing configuration for API: ${apiName}`);
  }

  axiosInstances[apiName] = axios.create({
    baseURL: baseUrl,
    timeout: HTTP_TIMEOUT,
  });

  return axiosInstances[apiName];
}

export function resetApiClient(apiName: string): void {
  if (apiName in axiosInstances) {
    delete axiosInstances[apiName];
  }
}
