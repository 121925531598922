export const APPOINTMENTS_QUERY_KEY = 'APPOINTMENTS_QUERY_KEY';
export const APPOINTMENT_SLOTS_CONFIG_QUERY_KEY = 'APPOINTMENT_SLOTS_CONFIG_QUERY_KEY';
export const BULK_MESSAGE_REPORTS_QUERY_KEY = 'BULK_MESSAGE_REPORTS_QUERY_KEY';
export const CLINICIANS_QUERY_KEY = 'CLINICIANS_QUERY_KEY';
export const FRONTEND_DEFINITIONS_QUERY_KEY = 'FRONTEND_DEFINITIONS_QUERY_KEY';
export const MESSAGING_QUERY_KEY = 'MESSAGING_QUERY_KEY';
export const ORGANISATION_DETAILS_QUERY_KEY = 'ORGANISATION_DETAILS_QUERY_KEY';
export const OTHER_ORGANISATIONS_QUERY_KEY = 'OTHER_ORGANISATIONS_QUERY_KEY';
export const USERLESS_ORGANISATIONS_QUERY_KEY = 'USERLESS_ORGANISATIONS_QUERY_KEY';
export const PENDING_APPLICATIONS_QUERY_KEY = 'PENDING_APPLICATIONS_QUERY_KEY';
export const SCHEDULED_MESSAGING_QUERY_KEY = 'SCHEDULED_MESSAGING_QUERY_KEY';
export const SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_KEY = 'SCHEDULED_MESSAGING_RULE_EXECUTIONS_QUERY_KEY';
export const SCHEDULED_MESSAGING_RULES_QUERY_KEY = 'SCHEDULED_MESSAGING_RULES_QUERY_KEY';
export const SCHEDULED_MESSAGING_RULE_STATS_QUERY_KEY = 'SCHEDULED_MESSAGING_RULE_STATS_QUERY_KEY';
export const SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_KEY = 'SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_KEY';
export const USER_APPLICATIONS_QUERY_KEY = 'USER_APPLICATIONS_QUERY_KEY';
export const USER_INVITATIONS_QUERY_KEY = 'USER_INVITATIONS_QUERY_KEY';

export const APPOINTMENTS_QUERY_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const APPOINTMENT_SLOTS_CONFIG_QUERY_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const BULK_MESSAGE_REPORTS_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const CLINICIANS_QUERY_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const FRONTEND_DEFINITIONS_QUERY_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const MESSSAGING_QUERY_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const ORGANISATION_DETAILS_STALE_TIME = 24 * 60 * 60 * 1_000; // 24 hours
export const OTHER_ORGANISATIONS_QUERY_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const SCHEDULED_MESSAGING_QUERY_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_STALE_TIME = 60 * 60 * 1_000; // 1 hour
export const SCHEDULED_MESSAGING_RULE_STATS_QUERY_STALE_TIME = 24 * 60 * 60 * 1_000; // 24 hours
export const SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_STALE_TIME = 24 * 60 * 60 * 1_000; // 24 hours
