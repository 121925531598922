import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { MultipleChoiceSelect, Option } from '../../../../shared/MultipleChoiceSelect/MultipleChoiceSelect';

import { useAppointmentSlotsConfig } from '../../../../../hooks/useAppointmentSlotsConfig/useAppointmentSlotsConfig';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Refresh from '@mui/icons-material/Refresh';
import { AppointmentSlotsConfig } from '../../../../../domain/Appointments';
import groupBy from '../../../../../util/groupBy';
import { uniqueBy } from '../../../../../util/uniqueBy';

function getDistinctHolders(holders: AppointmentSlotsConfig['holders']): AppointmentSlotsConfig['holders'] {
  // For EMIS, we need the GUID, so exclude any holders without one
  const applicableHolders = holders.filter((holder) => holder.guid);
  return uniqueBy(applicableHolders, (holder) => `${holder.name}#${holder.guid}`);
}

function filterHoldersWithRepeatedNames(holders: AppointmentSlotsConfig['holders']): Set<string> {
  return new Set(
    holders
      ? Object.entries(groupBy(holders, 'name'))
          .filter(([, group]) => group.length > 1)
          .map(([name]) => name)
      : [],
  );
}

function buildOptions(holders: AppointmentSlotsConfig['holders'] | undefined): Option<string>[] {
  const uniqueHolders = holders ? getDistinctHolders(holders) : [];

  // If two staff members have the same name, we show the id as well
  const repeatedNames = filterHoldersWithRepeatedNames(uniqueHolders);

  return uniqueHolders.map((holder) => ({
    value: holder.guid!,
    label: holder.name,
    sublabel: repeatedNames.has(holder.name) ? holder.guid : '',
  }));
}

export function GpSelectEmis() {
  const form = useFormContext();

  const doubleBookingPreventionEnabled = form.watch('doubleBookingPreventionEnabled');

  const {
    loading: appointmentSlotsConfigLoading,
    config: appointmentSlotsConfig,
    error: appointmentSlotsError,
    refetch: appointmentSlotsRefetch,
  } = useAppointmentSlotsConfig(true);

  const options: Option<string>[] = useMemo(
    () => buildOptions(appointmentSlotsConfig?.holders),
    [appointmentSlotsConfig?.holders],
  );

  return (
    <>
      <Controller
        name="doubleBookingPreventionGpIds"
        control={form.control}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <FormControlLabel
                label={<Typography>With any of these staff members:</Typography>}
                sx={{ display: 'flex', gap: 1, margin: 0 }}
                labelPlacement="start"
                control={
                  <Box flexGrow="1">
                    <MultipleChoiceSelect
                      label="Staff members"
                      placeholder="Staff members"
                      values={value}
                      options={options}
                      noOptionsText="No staff members found"
                      deletedOptionText="This staff member no longer seems to exist"
                      loading={appointmentSlotsConfigLoading}
                      disabled={!doubleBookingPreventionEnabled || !!appointmentSlotsError}
                      onChange={onChange}
                      getInputParams={(params) => ({
                        ...params,
                        error: !!appointmentSlotsError,
                      })}
                      slotProps={{
                        paper: {
                          sx: {
                            border: '1px solid',
                            boxShadow: 1,
                          },
                        },
                      }}
                    />
                  </Box>
                }
              />
            </>
          );
        }}
        defaultValue={[]}
      />

      {!!appointmentSlotsError && (
        <Box marginBottom={2}>
          <Alert
            severity="error"
            action={
              <Button color="inherit" size="small" startIcon={<Refresh />} onClick={() => appointmentSlotsRefetch()}>
                Retry
              </Button>
            }
          >
            There was an error loading staff members
          </Alert>
        </Box>
      )}
    </>
  );
}
