import React, { MouseEvent, useRef, useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

/**
 * Loading button that switches to loading state while `onClick` is running to avoid double clicks.
 *
 * This component is useful for buttons that trigger async actions or side-effects,
 * such as form submissions.
 *
 * When appropriate, this component should be used instead of the standard `LoadingButton`,
 * as simply relying on `useState` to disable the button while the click handler runs is unreliable.
 */
export function LoadingActionButton({ onClick, ...buttonProps }: ButtonProps) {
  // State is unreliable for avoiding double clicks because state updates are asynchronous
  // So we use a ref to track an ongoing click event and guard against subsequent clicks
  const clickEventRef = useRef<MouseEvent<HTMLButtonElement> | null>(null);

  const [loading, setLoading] = useState(false);

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (!onClick) {
      return;
    }

    if (clickEventRef.current) {
      return;
    }

    clickEventRef.current = event;
    setLoading(true);

    try {
      await onClick(event);
    } finally {
      clickEventRef.current = null;
      setLoading(false);
    }
  }

  return (
    <Button {...buttonProps} onClick={handleClick} loading={loading}>
      {buttonProps.children}
    </Button>
  );
}
