import React, { MouseEvent } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { BulkMessageSummaryReport } from '../../../../domain/Messaging';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import { loadBulkMessageReport } from '../../../../apis/messaging/MessagingApi';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import dayjs from 'dayjs';

interface Props {
  id: string;
  summary: BulkMessageSummaryReport;
}

export const CSV_TIMESTAMP_FORMAT = 'YYYY-MM-DD_HH-mm-ss';

async function downloadReport(organisation: string, reportId: string) {
  const { timestamp, details } = await loadBulkMessageReport(organisation, reportId);

  const filename = `BulkMessageReport-${dayjs(timestamp).format(CSV_TIMESTAMP_FORMAT)}`;

  const csvConfig = mkConfig({ useKeysAsHeaders: true, filename });

  const csv = generateCsv(csvConfig)(
    details.map(({ nhsNumber, emailStatus, smsStatus, notes }) => ({
      'NHS Number': nhsNumber,
      'E-Mail status': emailStatus ?? '',
      'SMS status': smsStatus ?? '',
      Notes: notes ?? '',
    })),
  );

  download(csvConfig)(csv);
}

export function ReportSummary({ id, summary }: Props) {
  const { emailsSent, smsSent, notContacted, patientRecordUpdateErrors, hadPatientsOpenInTpp } = summary;

  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const dispatch = useDispatch();

  return (
    <Box m={1}>
      <List subheader={<ListSubheader component="div">Summary</ListSubheader>}>
        {emailsSent !== 'NOT_APPLICABLE' && emailsSent > 0 && (
          <ListItem data-testid="email-report">
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${emailsSent} e-mail${emailsSent === 1 ? '' : 's'} sent`}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {emailsSent === 0 && (
          <ListItem data-testid="email-error-report">
            <ListItemIcon>
              <EmailIcon color="error" />
            </ListItemIcon>
            <ListItemText primary={'No e-mails were sent'} />
          </ListItem>
        )}

        {smsSent !== 'NOT_APPLICABLE' && smsSent > 0 && (
          <ListItem data-testid="sms-report">
            <ListItemIcon>
              <SmsIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${smsSent} SMS message${smsSent === 1 ? '' : 's'} sent`}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {smsSent === 0 && (
          <ListItem data-testid="sms-error-report">
            <ListItemIcon>
              <SmsIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={'No SMS messages were sent'}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {notContacted > 0 && (
          <ListItem data-testid="unreachable-report">
            <ListItemIcon>
              <WarningIcon color="warning" />
            </ListItemIcon>
            <ListItemText
              primary={`${notContacted} patient${notContacted === 1 ? '' : 's'} not contacted`}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {!!patientRecordUpdateErrors && patientRecordUpdateErrors > 0 && (
          <ListItem data-testid="record-update-errors-report">
            <ListItemIcon>
              <ErrorIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={`${patientRecordUpdateErrors} patient record${
                patientRecordUpdateErrors === 1 ? '' : 's'
              } failed to update`}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {hadPatientsOpenInTpp && (
          <ListItem data-testid="patient-open-in-tpp-report">
            <ListItemIcon>
              <WarningIcon color="warning" />
            </ListItemIcon>
            <ListItemText
              primary="Some patients were open in SystmOne and had to be saved manually for the messages to be recorded"
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}
      </List>

      <Button
        href="#"
        onClick={async (event: MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();
          try {
            await downloadReport(organisation, id);
          } catch (error) {
            dispatch(enqueueErrorNotification('Unable to download the report', error));
          }
        }}
        size="small"
        sx={{
          marginLeft: 1,
        }}
      >
        Download full report
      </Button>
    </Box>
  );
}
