import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { OrganisationLevelConfig } from './OrganisationLevelConfig/OrganisationLevelConfig';
import { RulesSection } from './RulesSection/RulesSection';
import { RulesContextProvider } from '../../../context/RulesContext/RulesContext';
import { useScheduledMessagingConfig } from '../../../hooks/useScheduledMessagingConfig/useScheduledMessagingConfig';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueErrorNotification } from '../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';
import { SectionSpinner } from '../../shared/LoadingSection/LoadingSection';

export function ScheduledMessagingConfig() {
  const { config, loading, updateOrganisationConfig } = useScheduledMessagingConfig();
  const [updatingConfig, setUpdatingConfig] = useState(false);

  const dispatch = useDispatch();

  async function onAvailableChange(available: boolean) {
    setUpdatingConfig(true);
    try {
      await updateOrganisationConfig({ available });
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to update the configuration', error));
    } finally {
      setUpdatingConfig(false);
    }
  }

  async function onReportsRecipientUserIdChange(userId: string | undefined) {
    await updateOrganisationConfig({ reportsRecipientUserId: userId ?? null });
  }

  async function onUpdatePartnerApiCredentials(username: string, password: string) {
    await updateOrganisationConfig({ partnerApiCredentials: { username, password } });
  }

  if (loading) return <SectionSpinner mt={3} />;

  return (
    <Box display="flex" flexDirection="column" gap={3} pt={3}>
      <Backdrop open={updatingConfig} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="sched-messaging-spinner" />
      </Backdrop>

      <OrganisationLevelConfig
        config={config}
        onAvailableChange={onAvailableChange}
        onReportsRecipientUserIdChange={onReportsRecipientUserIdChange}
        onUpdatePartnerApiCredentials={onUpdatePartnerApiCredentials}
      />

      {config?.available && (
        <RulesContextProvider>
          <RulesSection />
        </RulesContextProvider>
      )}
    </Box>
  );
}
