import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { OrganisationEmail } from '../../../../domain/Organisation';

interface Props {
  emails: OrganisationEmail[];
}

export function OrganisationEmailsSummary({ emails }: Props) {
  return (
    <Box sx={{ p: 6, border: '1px solid #0A0A0A', borderRadius: 2 }}>
      <Stack direction="row" justifyContent="space-evenly" alignItems="center">
        <Stack data-testid="organisation-emails-metric" spacing={0.5} textAlign="center">
          <Typography variant="h5" fontWeight="bold">
            {emails.length}
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            Active Organisation E-mails
          </Typography>
        </Stack>
        <Stack data-testid="organisation-emails-metric" spacing={0.5} textAlign="center">
          <Typography variant="h5" fontWeight="bold">
            {emails.filter((email) => !email.verified).length}
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            Pending Verification
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
