import React, { useContext, useEffect, useState } from 'react';
import { ExecutionsDataGrid } from '../ExecutionsDataGrid/ExecutionsDataGrid';
import Stack from '@mui/material/Stack';
import { useRuleExecutionHistory } from '../../../../hooks/useRuleExecutions/useRuleExecutions';
import { GridRowsProp } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import userContext from '../../../../context/UserContext';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import rulesContext from '../../../../context/RulesContext/RulesContext';
import { TRIGGER_RULE_DISABLED, TRIGGER_RULE_HIDDEN } from '../ExecutionsDataGrid/Toolbar/Toolbar';

export function RuleExecutionsTab() {
  dayjs.extend(advancedFormat);

  const { getRequiredSelectedRule } = useContext(rulesContext);

  const { history, nextScheduledRun, loading, refresh, triggerRule, error } = useRuleExecutionHistory();

  const { user } = useContext(userContext);

  const dispatch = useDispatch();

  const [rows, setRows] = useState<GridRowsProp>([]);

  const {
    config: { enabled },
  } = getRequiredSelectedRule();

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load execution history for this rule', error));
    }
  }, [error]);

  useEffect(() => {
    if (history) {
      setRows(
        history.map((execution) => ({
          id: execution.executionId,
          date: execution.startedAt,
          status: execution.status,
          duration: execution.finishedAt ? dayjs(execution.finishedAt).diff(execution.startedAt) : null,
          summary: execution.summary,
          error: execution.error,
        })),
      );
    }
  }, [history]);

  return (
    <Stack gap={2} width={600}>
      {nextScheduledRun && (
        <Stack direction="row" gap={1} alignItems="center">
          <EventIcon />
          <Typography>Next run:</Typography>
          <Typography color="text.secondary">{dayjs(nextScheduledRun).format('ddd, MMM Do [a]t h:mma')}</Typography>
        </Stack>
      )}
      <ExecutionsDataGrid
        rows={rows}
        loading={loading}
        triggerRule={!(user?.superUser ?? false) ? TRIGGER_RULE_HIDDEN : enabled ? triggerRule : TRIGGER_RULE_DISABLED}
        refresh={refresh}
      />
    </Stack>
  );
}
