import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Controller, useForm, ValidateResult } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../../hooks/useDialog/useDialog';
import { DEFAULT_SENDER_ID } from '../../../../util/constants';

interface Props {
  senderId: string | null;
  setSenderId: (newSenderId: string | null) => Promise<void>;
  canEditSenderId: boolean;
  canClearSenderId: boolean;
}

function ListRequirement({ label, error }: { label: string; error: ValidateResult }) {
  return (
    <ListItem disablePadding>
      <Box width="1em" display="inline-block">
        <Typography variant="body2" color={error ? 'error' : 'primary.text'}>
          {error ? '✗' : '✓'}
        </Typography>
      </Box>
      <Typography variant="body2" color={error ? 'error' : 'primary.text'}>
        {label}
      </Typography>
    </ListItem>
  );
}

export function SmsSenderIdConfig({ senderId, setSenderId, canEditSenderId, canClearSenderId }: Props) {
  const {
    handleSubmit,
    formState: { errors: formErrors, isDirty, isSubmitting },
    control,
    reset,
  } = useForm({
    values: {
      senderId,
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  function askBeforeClearingSenderId() {
    open({
      title: 'Are you sure you want to remove this Sender ID?',
      content: (
        <Stack gap={2}>
          <Typography variant="body1">
            Unsetting your bespoke Sender ID will delete it and the default SMS Sender ID{' '}
            <strong>{DEFAULT_SENDER_ID}</strong> will take effect in its place.
          </Typography>
          <Typography variant="body1">You cannot undo this action.</Typography>
        </Stack>
      ),
    }).then(() => handleSubmit(() => setSenderId(null))());
  }

  const errors = formErrors.senderId?.types;

  return (
    <>
      <Stack gap={2} maxWidth="700px">
        <Typography variant="h6">SMS Sender ID</Typography>
        {canEditSenderId ? (
          <>
            <Alert icon={<InfoIcon />} severity="info">
              Only no-reply sender IDs are supported. Note that setting a bespoke sender ID can negatively impact SMS
              delivery.
            </Alert>

            <form
              onSubmit={handleSubmit(({ senderId }) => {
                if (senderId) {
                  return setSenderId(senderId);
                } else {
                  return askBeforeClearingSenderId();
                }
              })}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  control={control}
                  name="senderId"
                  rules={{
                    validate: {
                      length: (value) => value?.length === 0 || (!!value && value.length >= 3 && value.length <= 11),
                      characters: (value) => value?.length === 0 || (!!value && !!value.match(/^([A-Z0-9]*)$/i)),
                      letter: (value) => value?.length === 0 || (!!value && !!value.match(/[A-Z]/i)),
                    },
                  }}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <TextField
                      name={name}
                      placeholder={DEFAULT_SENDER_ID}
                      onChange={onChange}
                      size="small"
                      value={value ?? ''}
                      error={!!error}
                      slotProps={{
                        input: {
                          endAdornment: !!senderId && canClearSenderId && (
                            <InputAdornment position="end">
                              <Tooltip title="Clear bespoke Sender ID">
                                <IconButton edge="end" size="small" onClick={askBeforeClearingSenderId}>
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  )}
                />

                <Stack pl={2} direction="row" gap={1}>
                  <Button type="submit" variant="contained" disabled={!!errors || !isDirty || isSubmitting}>
                    Set
                  </Button>
                  <Button disabled={!isDirty} onClick={() => reset()}>
                    Undo
                  </Button>
                </Stack>
              </Box>
            </form>

            <List
              sx={{
                pl: 4,
              }}
            >
              <ListRequirement label="Length: 3—11 characters" error={errors?.length} />
              <ListRequirement label="Characters: Any combination of [a—z][A—Z][0—9] with" error={errors?.characters} />
              <ListRequirement label="Minimum of 1 alphabetical character" error={errors?.letter} />
            </List>
          </>
        ) : (
          <>
            <Alert icon={<InfoIcon />} severity="info">
              Only no-reply sender IDs are supported. Note that setting a bespoke sender ID can negatively impact SMS
              delivery.
            </Alert>

            <Stack direction="row" alignItems="center" gap={1} height={'2em'}>
              <Typography>Current Sender ID:</Typography>
              <Typography color={'text.secondary'}>{senderId ?? `${DEFAULT_SENDER_ID} (default)`}</Typography>
              {senderId && canClearSenderId && (
                <Tooltip title="Clear bespoke Sender ID">
                  <IconButton edge="end" size="small" onClick={askBeforeClearingSenderId}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <Typography>
              Contact{' '}
              <Link underline="always" href="mailto:support@abtrace.co" target="_blank">
                support@abtrace.co
              </Link>{' '}
              to set a bespoke sender ID for SMS for your organisation.
            </Typography>
          </>
        )}
      </Stack>
      <ConfirmationDialog connector={connector} />
    </>
  );
}
