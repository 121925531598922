import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useState } from 'react';
import { UseDialogConnector } from '../../../hooks/useDialog/useDialog';
import TextField from '@mui/material/TextField';
import { searchOrganisation } from '../../../apis/app/AppApi';
import Autocomplete from '@mui/material/Autocomplete';
import { useDebounce } from '../../../util/useDebounce';
import userContext from '../../../context/UserContext';
import CircularProgress from '@mui/material/CircularProgress';

export const isOrganisationCodeValid = (organisationCode: string): boolean => {
  const format = /^[a-zA-Z0-9]{6}$/;
  return format.test(organisationCode);
};

export function ApplyDialog({ connector }: { connector: UseDialogConnector<{}, string> }) {
  const [open, setOpen] = useState(connector.config !== null);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState<string>('');
  const searchString = useDebounce<string>(value, 250);
  const [searchResult, setSearchResult] = useState<{ code: string; label: string }[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<{ code: string; label: string } | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const context = useContext(userContext);
  const user = context.user;
  const isSuperUser = user?.superUser ?? false;

  useEffect(() => {
    const search = async () => {
      setIsSearching(true);

      try {
        const result = await searchOrganisation(searchString);

        const options = Object.entries(result).map(([key, { name }]) => {
          return {
            code: key,
            label: name ? `${name} (${key})` : key,
          };
        });

        // Allow the super user to join a brand new organisation
        if (isSuperUser && isOrganisationCodeValid(searchString) && !result[searchString]) {
          options.push({
            code: searchString,
            label: `${searchString} (New organisation)`,
          });
        }

        setSearchResult(options);
      } catch {
        // Do nothing
      } finally {
        setIsSearching(false);
      }
    };

    if (searchString.length >= 3) {
      search();
    } else {
      setSearchResult([]);
    }
  }, [searchString]);

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
    }
  }, [connector.config]);

  const close = () => {
    setSelectedOrg(null);
    setValue('');
    setSearchResult([]);
    setOpen(false);
  };

  const isApplyButtonEnabled =
    // The user selected an existing organisation
    value === selectedOrg?.label ||
    // The user is the super user and the organisation code looks valid
    (isSuperUser && isOrganisationCodeValid(value));

  async function apply() {
    const organisationCode = value === selectedOrg?.label ? selectedOrg.code : value;

    setSubmitting(true);

    return connector
      .submit(organisationCode)
      .then(close)
      .catch(() => {})
      .finally(() => {
        setSubmitting(false);
      });
  }

  if (!connector.config) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Join organisation</DialogTitle>
      <DialogContent sx={{ width: '350px' }}>
        <Autocomplete
          fullWidth
          loading={isSearching}
          loadingText="Searching..."
          sx={{ py: 1 }}
          value={selectedOrg}
          freeSolo
          forcePopupIcon
          onChange={(_event, newValue: { label: string; code: string } | string | null) => {
            if (newValue && typeof newValue === 'object') {
              setSelectedOrg(newValue);
            }
          }}
          onInputChange={(_event, newValue) => {
            setValue(newValue);
          }}
          options={searchResult}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              onBlur={() => setSearchResult([])}
              label="Search an organisation"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Box sx={{ position: 'relative' }}>
            <LoadingButton
              disabled={!isApplyButtonEnabled}
              onClick={apply}
              variant="contained"
              data-testid="apply"
              loading={submitting}
            >
              Apply
            </LoadingButton>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
