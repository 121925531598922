import Box from '@mui/material/Box';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import React, { useMemo, useRef } from 'react';
import { default as MuiPopper, PopperProps } from '@mui/material/Popper';
import { MULTIPLE_CHOICE_SELECT_TAG_LIMIT } from '../../../util/constants';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@mui/system/styled';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// All values must be of this type
type Value = string | number;

// All options must be of this type
// Note that an option contains a value
export interface Option<V extends Value> {
  value: V;
  label: string;
  sublabel?: string;

  // Deleted options are displayed differently
  deleted?: boolean;
}

export type Props<V extends Value> = Omit<
  Partial<AutocompleteProps<Option<V>, true, false, false>>,
  'onChange' | 'value' | 'options'
> & {
  values: V[];
  options: Option<V>[];
  label: string;
  placeholder?: string;
  deletedOptionText?: string;
  getInputParams?: (params: AutocompleteRenderInputParams) => AutocompleteRenderInputParams;
  onChange: (selectedValues: V[]) => void;
};

function sortByLabel<V extends Value>(options: Option<V>[]): Option<V>[] {
  return options.sort((a, b) => a.label.localeCompare(b.label));
}

/**
 * Receives a set of options and values and returns:
 *
 * - The values transformed into options, including the missing ones
 * - The options extended with the missing values
 *
 * These are then passed onto <Autocomplete />
 */
function reconcileOptionsAndValues<V extends Value>(options: Option<V>[], values: V[]): [Option<V>[], Option<V>[]] {
  const optionsMappedByValue = new Map(options.map((option) => [option.value, option]));

  const valuesAsOptions: Option<V>[] = values.map(
    (v) =>
      optionsMappedByValue.get(v) ?? {
        value: v,
        label: String(v),
        deleted: true,
      },
  );

  const optionsWithMissingValues: Option<V>[] = options.concat(valuesAsOptions.filter((v) => v.deleted));

  return [sortByLabel(optionsWithMissingValues), sortByLabel(valuesAsOptions)];
}

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
  },
}));

export const MultipleChoiceSelect = <V extends Value>(props: Props<V>) => {
  const { label, values, options, placeholder, loading, deletedOptionText, getInputParams, onChange, ...rest } = props;

  const anchorRef = useRef<HTMLButtonElement>(null);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: Option<V>) => `${option.label} ${option.sublabel ?? ''}`,
  });

  const Popper = (popperProps: PopperProps) => {
    return <MuiPopper {...popperProps} anchorEl={anchorRef.current} />;
  };

  const [allOptions, allValues] = useMemo(() => reconcileOptionsAndValues(options, values), [options, values]);

  const actualDeletedOptionText = deletedOptionText ?? 'This option no longer seems to exist';

  return (
    <Box>
      <Autocomplete
        size="small"
        ref={anchorRef}
        options={allOptions}
        value={allValues}
        multiple
        disableCloseOnSelect
        limitTags={MULTIPLE_CHOICE_SELECT_TAG_LIMIT}
        onChange={(_e, selectedOptions: Option<V>[]) => {
          onChange(selectedOptions.map(({ value }) => value));
        }}
        renderTags={(tagOptions, getTagProps) => {
          if (loading) {
            return;
          }

          return tagOptions.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return option.deleted ? (
              // If this option was deleted, surround it with a dashed red border
              <ErrorTooltip key={key} title={actualDeletedOptionText}>
                <Chip
                  label={option.label}
                  size="small"
                  color="error"
                  variant="outlined"
                  sx={{ borderStyle: 'dashed' }}
                  {...tagProps}
                  data-deleted={true}
                />
              </ErrorTooltip>
            ) : (
              <Chip key={key} label={option.label} size="small" {...tagProps} />
            );
          });
        }}
        renderOption={(renderProps, option, { selected }) => (
          <li {...renderProps}>
            <Checkbox size="small" checked={selected} />
            <Stack>
              <Typography>{option.label}</Typography>
              {option.deleted ? (
                <Typography color="error.main" variant="caption">
                  {actualDeletedOptionText}
                </Typography>
              ) : (
                option.sublabel && (
                  <Typography color="text.secondary" variant="caption">
                    {option.sublabel}
                  </Typography>
                )
              )}
            </Stack>
          </li>
        )}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option.label}
        getOptionKey={(option) => option.value}
        isOptionEqualToValue={(option, { value }) => option.value === value}
        renderInput={(params) => {
          const inputParams = getInputParams ? getInputParams(params) : params;
          const inputParamsWithLoadingAdornment = loading
            ? {
                ...inputParams,
                slotProps: {
                  input: {
                    ...inputParams.InputProps,
                    endAdornment: (
                      <>
                        <React.Fragment>
                          <CircularProgress color="inherit" size={20} />
                        </React.Fragment>
                        {inputParams.InputProps.endAdornment}
                      </>
                    ),
                  },
                },
              }
            : inputParams;
          return (
            <TextField
              {...inputParamsWithLoadingAdornment}
              variant="outlined"
              label={label}
              placeholder={placeholder}
            />
          );
        }}
        slots={{
          popper: Popper,
        }}
        {...rest}
      />
    </Box>
  );
};
