import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import OrganisationUsersContext from '../../../context/OrganisationUsersContext/OrganisationUsersContext';
import UserContext from '../../../context/UserContext';
import { OrganisationMember, Permission } from '../../../domain/Organisation';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';
import * as Api from '../../../apis/website/Api';
import {
  RecipientUsageMetadata,
  RemoveRecipientDialog,
  RemoveRecipientDialogConfig,
} from '../RemoveRecipientDialog/RemoveRecipientDialog';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import OrganisationUsersTable from '../OrganisationUsersTable/OrganisationUsersTable';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useRules } from '../../../hooks/useRules/useRules';

interface Props {
  organisation: string;
}

export default function OrganisationUsers({ organisation }: Props) {
  const [updating, setUpdating] = useState(false);

  const dispatch = useDispatch();
  const { users, setUsers, loading: usersLoading } = useContext(OrganisationUsersContext);
  const { user: currentUser } = useContext(UserContext);

  const { fetch: fetchRules, getOrFetch: getOrFetchRules } = useRules({ enabled: false });

  const { open, connector } = useDialog<RemoveRecipientDialogConfig>();

  const updateUserPermissions =
    (user: OrganisationMember, permission: Permission) => async (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newPermissions = new Set(user.permissions);
      if (ev.target.checked) {
        newPermissions.add(permission);
      } else {
        newPermissions.delete(permission);
      }

      setUpdating(true);

      try {
        const updatedUser = await Api.updateUser(user.id, [...newPermissions], organisation);
        setUsers(users.map((u) => (u.id === user.id ? updatedUser : u)));
        dispatch(enqueueInfoNotification(`Updated ${user.email}`));

        if (currentUser?.email === user.email) {
          await fetchAuthSession({ forceRefresh: true });
        }
      } catch (error) {
        dispatch(enqueueErrorNotification(`Unable to update permissions for ${user.email}`, error));
      } finally {
        setUpdating(false);
      }
    };

  const removeUser = async (user: OrganisationMember) => {
    try {
      await Api.forceDeleteUser(user.id, organisation);

      setUsers(users.filter((u) => user.id !== u.id));
      dispatch(enqueueInfoNotification(`Removed ${user.email}`));

      if (currentUser?.email === user.email) {
        await fetchAuthSession({ forceRefresh: true });
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(`Unable to remove ${user.email}`, error));
    }
  };

  const askBeforeRemovingUser = (user: OrganisationMember) => {
    setUpdating(true);

    return (
      Promise.all([
        Api.getUser(user.id, organisation),
        // Rules are only used for name / description, so try to reuse cached data first
        getOrFetchRules(),
      ])
        // Check first if cached rules data has the desired rules. If not, refetch them
        .then(async ([{ configuredAt }, rules]) => {
          const isMissingRuleMetadata = configuredAt.scheduledMessaging.rules.some((rule) => !rules[rule.id]);

          if (isMissingRuleMetadata) {
            const freshRules = await fetchRules();
            return [configuredAt, freshRules] as const;
          } else {
            return [configuredAt, rules] as const;
          }
        })
        .then<RecipientUsageMetadata>(([configuredAt, rules]) => ({
          ...configuredAt,
          scheduledMessaging: {
            rules: configuredAt.scheduledMessaging.rules.map((rule) => ({
              ...rule,
              name: rules[rule.id]?.name ?? rule.id,
              description: rules[rule.id]?.description ?? rule.id,
            })),
            fallback: configuredAt.scheduledMessaging.fallback,
          },
        }))
        .then((recipientUsageMetadata) => {
          open({
            recipientType: 'user',
            recipientValue: user.email,
            recipientUsageMetadata,
          }).then(() => removeUser(user));
        })
        .catch((error) => {
          dispatch(enqueueErrorNotification(`Unable to remove ${user.email}`, error));
        })
        .finally(() => setUpdating(false))
    );
  };

  return (
    <LoadingSection loading={usersLoading}>
      {updating && (
        <Backdrop open={updating} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress data-testid="spinner" />
        </Backdrop>
      )}
      <Box mt={2}>
        <OrganisationUsersTable
          users={users}
          updateUserPermissions={updateUserPermissions}
          removeUser={askBeforeRemovingUser}
          organisation={organisation}
        />
        <RemoveRecipientDialog connector={connector} />
      </Box>
    </LoadingSection>
  );
}
