/**
 * Returns set of elements which repeat in the array.
 */
export const getDuplicatesSet = <T>(array: Array<T>): Set<T> => {
  const set = new Set<T>();
  const duplicates = new Set<T>();

  for (const element of array) {
    if (set.has(element)) {
      duplicates.add(element);
    } else {
      set.add(element);
    }
  }

  return duplicates;
};
