import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect } from 'react';
import { OrganisationEmailsTable } from '../OrganisationEmailsTable/OrganisationEmailsTable';
import { useOrganisationEmails } from '../../../../hooks/useOrganisationEmails/useOrganisationEmails';
import { LoadingActionButton } from '../../../shared/LoadingActionButton';
import { LoadingSection } from '../../../shared/LoadingSection/LoadingSection';
import { useDialog } from '../../../../hooks/useDialog/useDialog';
import { RegisterEmailDialog } from '../RegisterEmailDialog/RegisterEmailDialog';
import UserContext from '../../../../context/UserContext';
import { isOrgAdmin } from '../../../../domain/User';
import { OrganisationEmail } from '../../../../domain/Organisation';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';
import { OrganisationEmailsSummary } from '../OrganisationEmailsSummary/OrganisationEmailsSummary';
import {
  RecipientUsageMetadata,
  RemoveRecipientDialog,
  RemoveRecipientDialogConfig,
} from '../../RemoveRecipientDialog/RemoveRecipientDialog';
import { useAppointmentBookingConfig } from '../../../../hooks/useAppointmentBookingConfig/useAppointmentBookingConfig';
import { useScheduledMessagingConfig } from '../../../../hooks/useScheduledMessagingConfig/useScheduledMessagingConfig';
import { useRules } from '../../../../hooks/useRules/useRules';

interface Props {
  organisation: string;
}

export function OrganisationEmails({ organisation }: Props) {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const isSuperUser = user?.superUser ?? false;
  const isAdmin = isOrgAdmin(user, organisation);

  const canManage = isSuperUser || isAdmin;

  const { emails, isLoading, error, registerEmail, removeEmail, resendVerificationEmail } =
    useOrganisationEmails(organisation);

  const { fetch: fetchAppointments } = useAppointmentBookingConfig({
    enabled: false,
  });
  const { fetch: fetchScheduledMessaging } = useScheduledMessagingConfig({
    enabled: false,
  });
  const { fetch: fetchRules, getOrFetch: getOrFetchRules } = useRules({ enabled: false });

  const { open: openRegisterEmailDialog, connector: registerEmailDialogConnector } = useDialog<
    OrganisationEmail[],
    string
  >();
  const { open: openRemoveEmailDialog, connector: removeEmailDialogConnector } =
    useDialog<RemoveRecipientDialogConfig>();

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load organisation e-mails', error));
    }
  }, [error]);

  function onRegisterEmail() {
    return openRegisterEmailDialog(emails).then((email) =>
      registerEmail(email)
        .then(() => {
          dispatch(enqueueSuccessNotification(`Successfully registered organisation e-mail ${email}`));
        })
        .catch((error) => {
          dispatch(enqueueErrorNotification(`Unable to register organisation e-mail ${email}`, error));
        }),
    );
  }

  function onRemoveEmail(email: string) {
    return (
      Promise.all([
        // Fetch the latest data to ensure the dialog warning is as consistent as possible
        fetchAppointments(),
        fetchScheduledMessaging(),
        // Rules are only used for name / description, so try to reuse cached data first
        getOrFetchRules(),
      ])
        // Check first if cached rules data has the desired rules. If not, refetch them
        .then(async ([appointmentsConfig, scheduledMessagingConfig, rules]) => {
          const isMissingRuleMetadata = Object.entries(scheduledMessagingConfig?.rules ?? {}).some(
            ([ruleId, config]) => !rules[ruleId] && config.reportsEmailAddress === email,
          );

          if (isMissingRuleMetadata) {
            const freshRules = await fetchRules();
            return [appointmentsConfig, scheduledMessagingConfig, freshRules] as const;
          } else {
            return [appointmentsConfig, scheduledMessagingConfig, rules] as const;
          }
        })
        .then<RecipientUsageMetadata>(([appointmentsConfig, scheduledMessagingConfig, rules]) => ({
          appointments: appointmentsConfig.organisationEmail?.address === email,
          scheduledMessaging: {
            rules: Object.entries(scheduledMessagingConfig?.rules ?? {})
              .filter(([_ruleId, config]) => config.reportsEmailAddress === email)
              .map(([ruleId, config]) => ({
                id: ruleId,
                name: rules[ruleId]?.name ?? ruleId,
                description: rules[ruleId]?.description ?? ruleId,
                enabled: config.enabled,
              })),
            fallback: scheduledMessagingConfig?.reportsEmailAddress === email,
          },
        }))
        .then((recipientUsageMetadata) => {
          openRemoveEmailDialog({
            recipientType: 'e-mail address',
            recipientValue: email,
            recipientUsageMetadata,
          }).then(() =>
            removeEmail(email)
              .then(() => {
                dispatch(enqueueSuccessNotification(`Successfully removed organisation e-mail ${email}`));
              })
              .catch((error) => {
                dispatch(enqueueErrorNotification(`Unable to remove organisation e-mail ${email}`, error));
              }),
          );
        })
        .catch((error) => {
          dispatch(enqueueErrorNotification(`Unable to remove organisation e-mail ${email}`, error));
        })
    );
  }

  function onResendVerificationEmail(email: string) {
    return resendVerificationEmail(email)
      .then(() => {
        dispatch(enqueueSuccessNotification(`Successfully resent verification e-mail to ${email}`));
      })
      .catch((error) => {
        dispatch(enqueueErrorNotification(`Unable to resend verification e-mail to ${email}`, error));
      });
  }

  return (
    <>
      <Stack position="relative" spacing={2}>
        {!isLoading && !error && (
          <Box position="absolute" right={0} top={-32}>
            <LoadingActionButton
              variant="contained"
              onClick={onRegisterEmail}
              startIcon={<AddIcon />}
              disabled={!canManage}
            >
              Register New
            </LoadingActionButton>
          </Box>
        )}

        <Typography variant="subtitle1">
          E-mail addresses that are not linked to an Abtrace user account but are in use by the practice can be
          registered and managed here. Within Abtrace, they can be used as recipient addresses for various reports and
          submissions to the practice.
        </Typography>

        <Typography variant="subtitle1">
          An e-mail address must first be{' '}
          <Typography display="inline" component="strong" fontWeight="medium">
            verified
          </Typography>{' '}
          before it can be used.
        </Typography>

        <LoadingSection loading={isLoading}>
          <Stack spacing={3}>
            <OrganisationEmailsSummary emails={emails} />
            <OrganisationEmailsTable
              emails={emails}
              onRemoveEmail={onRemoveEmail}
              onResendVerificationEmail={onResendVerificationEmail}
              canManage={canManage}
            />
          </Stack>
        </LoadingSection>
      </Stack>

      <RegisterEmailDialog connector={registerEmailDialogConnector} />
      <RemoveRecipientDialog connector={removeEmailDialogConnector} />
    </>
  );
}
