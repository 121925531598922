import { RuleAnalytics } from '../domain/ScheduledMessaging';

export interface PatientDetails {
  nhsNumber: string;
  notes: string;
}

export interface CategorisedRuleAnalytics {
  notYet: {
    total: number;
    details: PatientDetails[] | undefined;
  };
  ongoing: {
    total: number;
    details: PatientDetails[] | undefined;
    perRound: Record<number, number>;
  };
  success: {
    total: number;
    details: PatientDetails[] | undefined;
    perRound: Record<number, number>;
  };
  failure: {
    total: number;
    details: PatientDetails[] | undefined;
    missingContactDetails: number;
    dissented: number;
    maxedOutMessagingRounds: number;
  };
  excluded: {
    total: number;
    details: PatientDetails[] | undefined;
    excludedByCriteria: number;
    tooOverdue: number;
  };
}

export function categoriseAnalytics(analytics: RuleAnalytics): CategorisedRuleAnalytics {
  const { totals, breakdown } = analytics;

  const notYetDetails = breakdown?.outsideRecall[0].map((nhsNumber) => ({
    nhsNumber,
    notes: 'Not yet requiring recall',
  }));

  const ongoingDetails = breakdown
    ? Object.entries(breakdown.undergoingRecall).flatMap(([round, patients]) =>
        patients.map((nhsNumber) => ({
          nhsNumber,
          notes:
            parseInt(round) === 0
              ? 'Has had no auto-recalls yet and is in the queue'
              : parseInt(round) === 1
                ? 'Had 1 recall so far'
                : `Had ${round} recalls so far`,
        })),
      )
    : undefined;

  const successDetails = breakdown
    ? Object.entries(breakdown.outsideRecall)
        .filter(([round]) => parseInt(round) > 0)
        .map(([round, patients]) =>
          patients.map((nhsNumber) => ({
            nhsNumber,
            notes: parseInt(round) === 1 ? 'Successful after 1 auto-recall' : `Successful after ${round} auto-recalls`,
          })),
        )
        .flat()
    : undefined;

  const failureDetails = breakdown
    ? breakdown.missingContactDetails
        .map((nhsNumber) => ({
          nhsNumber,
          notes: 'Has missing or invalid contact details',
        }))
        .concat(
          breakdown.dissented.map((nhsNumber) => ({
            nhsNumber,
            notes: 'Dissented to contact by selected contact methods',
          })),
        )
        .concat(
          breakdown.maxedOutMessagingRounds.map((nhsNumber) => ({
            nhsNumber,
            notes: 'Did not get monitoring done after maximum number of auto-recalls',
          })),
        )
    : undefined;

  const excludedDetails = breakdown
    ? breakdown.excluded
        .map((nhsNumber) => ({
          nhsNumber,
          notes: 'Excluded by the defined exclusion criteria',
        }))
        .concat(
          breakdown.tooOverdue.map((nhsNumber) => ({
            nhsNumber,
            notes: 'Too overdue at the beginning of the recall',
          })),
        )
    : undefined;

  return {
    notYet: {
      total: totals.outsideRecall[0],
      details: notYetDetails,
    },
    ongoing: {
      total: Object.values(totals.undergoingRecall).reduce((a, b) => a + b, 0),
      details: ongoingDetails,
      perRound: totals.undergoingRecall,
    },
    success: {
      total: Object.entries(totals.outsideRecall)
        .filter(([round]) => parseInt(round) > 0)
        .map(([, count]) => count)
        .reduce((a, b) => a + b, 0),
      details: successDetails,
      perRound: Object.fromEntries(Object.entries(totals.outsideRecall).filter(([round]) => parseInt(round) > 0)),
    },
    failure: {
      total: totals.missingContactDetails + totals.dissented + totals.maxedOutMessagingRounds,
      details: failureDetails,
      missingContactDetails: totals.missingContactDetails,
      dissented: totals.dissented,
      maxedOutMessagingRounds: totals.maxedOutMessagingRounds,
    },
    excluded: {
      total: totals.excluded + totals.tooOverdue,
      details: excludedDetails,
      excludedByCriteria: totals.excluded,
      tooOverdue: totals.tooOverdue,
    },
  };
}
