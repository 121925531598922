import React, { ReactNode, useState, useContext } from 'react';
import RulesContext from '../../../../context/RulesContext/RulesContext';
import { useRuleAnalytics } from '../../../../hooks/useRuleAnalytics/useRuleAnalytics';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LoadingSection } from '../../../shared/LoadingSection/LoadingSection';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { alpha } from '@mui/system';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import dayjs from 'dayjs';
import sanitize from 'sanitize-filename';
import Tooltip from '@mui/material/Tooltip';
import { categoriseAnalytics, PatientDetails } from '../../../../services/analytics';

export function RuleAnalyticsTab() {
  const { selectedRule } = useContext(RulesContext);
  const { analytics, downloadAllowed, loading, error } = useRuleAnalytics(selectedRule?.id);

  const [open, setOpen] = useState({
    undergoing: false,
    outside: false,
    failed: false,
    excluded: false,
  });

  function CategoryRow(props: {
    title: string;
    subtitle: string;
    total: number;
    expandKey?: string;
    downloadAllowed: boolean;
    details: PatientDetails[] | undefined;
    csvTitle: string;
    backgroundColour: string;
    children?: ReactNode;
  }) {
    const { total, expandKey, details } = props;

    return (
      <>
        <TableRow sx={{ backgroundColor: props.backgroundColour }}>
          <TableCell sx={{ borderBottom: 'none' }}>
            {props.title}
            <Typography variant="caption" color="text.secondary" component="div">
              <em>{props.subtitle}</em>
            </Typography>
          </TableCell>
          <TableCell align="right" sx={{ borderBottom: 'none' }}>
            {total}
          </TableCell>
          {downloadAllowed && (
            <TableCell sx={{ borderBottom: 'none' }} align="center">
              {total === 0 ? (
                <></>
              ) : details ? (
                <Box display="flex" justifyContent="center">
                  <Tooltip title="Download CSV">
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        const options = mkConfig({
                          filename: sanitize(
                            `RuleAnalytics_${selectedRule?.definition.name}_${props.csvTitle}_${dayjs().format('YYYY-MM-DD_HH:mm')}`,
                          ),
                          useKeysAsHeaders: true,
                        });

                        const csv = generateCsv(options)(
                          details.map(({ nhsNumber, notes }) => ({
                            'NHS Number': nhsNumber,
                            Subcategory: notes,
                          })),
                        );
                        download(options)(csv);
                      }}
                      height="24px"
                    >
                      <DownloadIcon />
                    </Link>
                  </Tooltip>
                </Box>
              ) : (
                <Box display="flex" justifyContent="center">
                  <Tooltip title="Download not available">
                    <FileDownloadOffIcon color="disabled" />
                  </Tooltip>
                </Box>
              )}
            </TableCell>
          )}
          <TableCell sx={{ borderBottom: 'none' }}>
            {expandKey ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen({ ...open, [expandKey]: !open[expandKey] });
                }}
              >
                {open[expandKey] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : (
              <></>
            )}
          </TableCell>
        </TableRow>
        {props.children ? (
          <TableRow sx={{ backgroundColor: alpha(props.backgroundColour, 0.75) }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open[expandKey!]} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>{props.children}</Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </>
    );
  }

  const { notYet, ongoing, success, failure, excluded } = categoriseAnalytics(
    analytics ?? {
      totals: {
        undergoingRecall: {},
        outsideRecall: {},
        missingContactDetails: 0,
        dissented: 0,
        maxedOutMessagingRounds: 0,
        tooOverdue: 0,
        excluded: 0,
      },
      breakdown: undefined,
    },
  );

  const grandTotal = notYet.total + ongoing.total + success.total + failure.total + excluded.total;

  const maxMessagingRounds = selectedRule?.config.patientSelectionCriteria.maxMessagingRounds;

  const colours = {
    notYet: 'white',
    undergoingRecall: '#FECF8B',
    outsideRecall: '#A7DEB2',
    failedRecall: '#F6AEAB',
    excluded: '#e5e5e5',
  };

  if (error) {
    return (
      <Alert severity="error" variant="outlined" sx={{ border: 0 }}>
        Unable to load rule analytics
      </Alert>
    );
  }

  return (
    <LoadingSection loading={loading}>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell align="right">Total</TableCell>
              {downloadAllowed && <TableCell align="center">Actions</TableCell>}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <CategoryRow
              title="Not yet"
              subtitle="Patients who do not require recall at this stage"
              total={notYet.total}
              downloadAllowed={downloadAllowed}
              details={notYet.details}
              csvTitle="NotYet"
              backgroundColour={colours.notYet}
            />

            <CategoryRow
              title="Ongoing"
              subtitle="Patients currently being auto-recalled"
              total={ongoing.total}
              expandKey="undergoing"
              downloadAllowed={downloadAllowed}
              details={ongoing.details}
              csvTitle="OngoingRecall"
              backgroundColour={colours.undergoingRecall}
            >
              <List>
                {Object.entries(ongoing.perRound)
                  .map(([round, count]) => [parseInt(round), count])
                  .map(([round, count]) => (
                    <ListItem key={round} disablePadding>
                      {round === 0
                        ? `${count} ${count === 1 ? 'has' : 'have'} had no auto-recalls yet and ${count === 1 ? 'is' : 'are'} in the queue`
                        : round === maxMessagingRounds && count > 0
                          ? `${count} ${count === 1 ? 'has' : 'have'} had ${round} recall${round === 1 ? '' : 's'} so far, the last one within the last 2 weeks`
                          : `${count} ${count === 1 ? 'has' : 'have'} had ${round} recall${round === 1 ? '' : 's'} so far`}
                    </ListItem>
                  ))}
              </List>
            </CategoryRow>

            <CategoryRow
              title="Success"
              subtitle="Patients auto-recalled and had monitoring done"
              total={success.total}
              expandKey="outside"
              downloadAllowed={downloadAllowed}
              details={success.details}
              csvTitle="Success"
              backgroundColour={colours.outsideRecall}
            >
              <List>
                {Object.entries(success.perRound)
                  .map(([round, count]) => [parseInt(round), count])
                  .map(([round, count]) => (
                    <ListItem key={round} disablePadding>
                      {count} {count === 1 ? 'was' : 'were'} successful after {round} auto-recall
                      {round === 1 ? '' : 's'}
                    </ListItem>
                  ))}
              </List>
            </CategoryRow>

            <CategoryRow
              title="Unsuccessful"
              subtitle="Patients for whom auto-recall attempts were not successful"
              total={failure.total}
              expandKey="failed"
              downloadAllowed={downloadAllowed}
              details={failure.details}
              csvTitle="UnsuccessfulRecall"
              backgroundColour={colours.failedRecall}
            >
              <List>
                <ListItem disablePadding>
                  {failure.missingContactDetails} had missing or invalid contact details
                </ListItem>
                <ListItem disablePadding>
                  {failure.dissented} had dissented to contact by selected contact methods
                </ListItem>
                <ListItem disablePadding>
                  {failure.maxedOutMessagingRounds} did not get monitoring done after maximum number of auto-recalls
                </ListItem>
              </List>
            </CategoryRow>

            <CategoryRow
              title="Excluded"
              subtitle="Patients intentionally excluded from auto-recall"
              total={excluded.total}
              expandKey="excluded"
              downloadAllowed={downloadAllowed}
              details={excluded.details}
              csvTitle="Excluded"
              backgroundColour={colours.excluded}
            >
              <List>
                <ListItem disablePadding>
                  {excluded.excludedByCriteria} {excluded.excludedByCriteria === 1 ? 'was' : 'were'} excluded by the
                  defined exclusion criteria
                </ListItem>
                <ListItem disablePadding>
                  {excluded.tooOverdue} {excluded.tooOverdue === 1 ? 'was' : 'were'} excluded for being too overdue at
                  the beginning of the recall
                </ListItem>
              </List>
            </CategoryRow>

            <TableRow>
              <TableCell>
                <strong>Total</strong>
              </TableCell>
              <TableCell align="right">{grandTotal}</TableCell>
              {downloadAllowed && <TableCell></TableCell>}
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </LoadingSection>
  );
}
