import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { OrganisationMember } from '../../../../domain/Organisation';
import OrganisationUsersContext from '../../../../context/OrganisationUsersContext/OrganisationUsersContext';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
  onSenderFilterChange: (senderFilter: string | undefined) => void;
}

export function SenderFilterSelect({ onSenderFilterChange }: Props) {
  const [selectedUser, setSelectedUser] = useState<OrganisationMember | null>(null);
  const [options, setOptions] = useState<OrganisationMember[]>([]);

  const { users: organisationUsers, loading } = useContext(OrganisationUsersContext);

  const byEmail = (a: OrganisationMember, b: OrganisationMember) => a.email.localeCompare(b.email);

  useEffect(() => {
    const newOptions = organisationUsers.sort(byEmail);
    setOptions(newOptions);

    if (!selectedUser || organisationUsers.includes(selectedUser)) {
      setSelectedUser(newOptions.find((option) => option.email === selectedUser?.email) ?? null);
    }
  }, [organisationUsers]);

  async function onChange(newValue: OrganisationMember | string | null) {
    const newlySelectedUser =
      typeof newValue === 'string' ? (options.find((user) => user.email === newValue) ?? null) : newValue;

    onSenderFilterChange(typeof newValue === 'string' ? newValue : newlySelectedUser?.email);
    setSelectedUser(newlySelectedUser);
  }

  return (
    <FormControlLabel
      label="Filter by Sender:"
      control={
        <Autocomplete
          size="small"
          sx={{ ml: 1, width: 350 }}
          isOptionEqualToValue={(option, value) => option.email === value.email}
          getOptionLabel={(option) => (typeof option === 'object' ? option.email : option)}
          defaultValue={selectedUser}
          value={selectedUser}
          onChange={(_event, value) => onChange(value)}
          onInputChange={(_event, value) => {
            // Clear the selection when the current value is not one of the options
            if (!value && !selectedUser) {
              onChange(null);
            }
          }}
          options={options}
          loading={loading}
          freeSolo
          forcePopupIcon
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sender"
              FormHelperTextProps={{ sx: { color: 'warning.main' } }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      }
      labelPlacement="start"
      sx={{
        justifyContent: 'left',
      }}
    />
  );
}
