import { AxiosInstance } from 'axios';
import { OptimisationConfig } from '../../domain/Optimisation';
import { QOFOPT_API_NAME } from '../../util/constants';
import { getApiClient, httpGet, httpPut, resetApiClient } from '../../util/api';
import { parseOptimisationConfig } from './model/response';

function client(): AxiosInstance {
  return getApiClient(QOFOPT_API_NAME);
}

export async function loadOptimisationConfig(organisation: string): Promise<OptimisationConfig | null> {
  const data = await httpGet(client(), `/organisations/${organisation}/config`, { nullOnError: true });
  return data === null ? null : parseOptimisationConfig(data);
}

export async function saveOptimisationConfig(organisation: string, config: OptimisationConfig): Promise<void> {
  await httpPut(client(), `/organisations/${organisation}/config`, config);
}

export function resetClient() {
  resetApiClient(QOFOPT_API_NAME);
}
