import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Application } from '../../../domain/Application';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';
import * as Api from '../../../apis/website/Api';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import OrganisationApplicationsTable from '../OrganisationApplicationsTable/OrganisationApplicationsTable';

interface Props {
  organisation: string;
  onApplicationApproved: () => void;
}

export default function OrganisationApplications({ organisation, onApplicationApproved }: Props) {
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState<Application[]>([]);

  const dispatch = useDispatch();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  useEffect(() => {
    Api.loadOrganisationApplications(organisation)
      .then((newApplications) => {
        setApplications(newApplications);
      })
      .catch((error) => {
        dispatch(enqueueErrorNotification(`Unable to load applications for organisation ${organisation}`, error));
      })
      .finally(() => setLoading(false));
  }, [organisation]);

  const updateApplications = (updatedApplication: Application) => {
    const updatedApplications = applications.map((app) => {
      if (app.status === 'PENDING' && app.id === updatedApplication.id) {
        return updatedApplication;
      } else {
        return app;
      }
    });
    setApplications(updatedApplications);
  };

  const approveApplication = (application: Application) => {
    setLoading(true);
    return Api.approveApplication(application)
      .then((approvedApplication) => {
        updateApplications(approvedApplication);
        onApplicationApproved();
      })
      .catch((error) => {
        dispatch(enqueueErrorNotification(`Unable to approve the application from ${application.email}`, error));
      })
      .finally(() => setLoading(false));
  };

  const denyApplication = (application: Application) => {
    return Api.denyApplication(application)
      .then((deniedApplication) => {
        updateApplications(deniedApplication);
        dispatch(enqueueInfoNotification(`Denied application from ${application.email}`));
      })
      .catch((error) => {
        dispatch(enqueueErrorNotification(`Unable to deny the application from ${application.email}`, error));
      });
  };

  const askBeforeDenyingApplication = (application: Application) => {
    open({
      title: 'Deny Application',
      text: `Are you sure you want to deny the application from ${application.email}?`,
    }).then(() => denyApplication(application));
  };

  return (
    <LoadingSection loading={loading}>
      <OrganisationApplicationsTable
        applications={applications}
        approveApplication={approveApplication}
        denyApplication={askBeforeDenyingApplication}
      />
      <ConfirmationDialog connector={connector} />
    </LoadingSection>
  );
}
