import CircularProgress from '@mui/material/CircularProgress';
import Slider, { SliderProps, SliderThumb } from '@mui/material/Slider';
import React from 'react';

interface LoadingThumbComponentProps extends React.HTMLAttributes<unknown> {
  loading: boolean;
}

export interface LoadingSliderProps extends SliderProps {
  loading: boolean;
}

function LoadingThumb({ loading, children, ...other }: LoadingThumbComponentProps) {
  return (
    <SliderThumb {...other}>
      {children}
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: -2,
            left: -2,
            zIndex: 1,
            color: 'gray',
          }}
        />
      )}
    </SliderThumb>
  );
}

export function LoadingSlider({ loading, ...props }: LoadingSliderProps) {
  return (
    <Slider
      {...props}
      slots={{
        thumb: LoadingThumb,
      }}
      slotProps={{
        thumb: {
          loading,
        } as LoadingThumbComponentProps,
      }}
    />
  );
}
