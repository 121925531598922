import { z } from 'zod';
import {
  OrganisationContactDetails,
  PatientTasksConfiguration,
  PatientTasksDefaultDescriptionPerStatus,
} from '../../../domain/PatientFacing';

const organisationContactDetailsSchema = z.object({
  email: z.string(),
  phone: z.string(),
  url: z.string(),
  urlDescription: z.string(),
  address: z.string(),
});

export function parseOrganisationContactDetails(response: unknown): OrganisationContactDetails {
  return organisationContactDetailsSchema.parse(response);
}

const patientTasksConfigurationSchema = z.object({
  active: z.boolean(),
  tasks: z.array(
    z.object({
      active: z.boolean(),
      upToDateStatusDescription: z.string(),
      dueByStatusDescription: z.string(),
      overdueStatusDescription: z.string(),
      code: z.string(),
    }),
  ),
  taskInputs: z.object({
    active: z.boolean(),
    deliveryEmailAddress: z.string(),
  }),
});

export function parsePatientTasksConfigurationSchema(response: unknown): PatientTasksConfiguration {
  return patientTasksConfigurationSchema.parse(response);
}

const patientTasksDefaultDescriptionsSchema = z.object({
  genericStatusDescription: z.string(),
  upToDateStatusDescription: z.string(),
  overdueStatusDescription: z.string(),
  dueByStatusDescription: z.string(),
});

export function parseParsePatientTasksConfigurationResponse(
  response: unknown,
): PatientTasksDefaultDescriptionPerStatus {
  return patientTasksDefaultDescriptionsSchema.parse(response);
}
