import React from 'react';
import { UseDialogConnector } from '../../../../hooks/useDialog/useDialog';
import { OrganisationEmail } from '../../../../domain/Organisation';

interface Props {
  connector: UseDialogConnector<NonNullable<OrganisationEmail[]>, string>;
}

export function RegisterEmailDialog({ connector: _ }: Props) {
  // TODO
  return <></>;
}
