import { FetchQueryOptions, RefetchOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  SCHEDULED_MESSAGING_RULES_QUERY_KEY,
  SCHEDULED_MESSAGING_QUERY_STALE_TIME,
  SCHEDULED_MESSAGING_QUERY_KEY,
} from '../../util/queries';
import { Rule, ScheduledMessagingOrganisationConfig } from '../../domain/ScheduledMessaging';
import * as ScheduledMessagingApi from '../../apis/scheduledMessaging/ScheduledMessagingApi';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface UseRulesOptions {
  enabled?: boolean;
}

interface UseRulesResult {
  rules: Record<string, Rule> | undefined;
  loading: boolean;
  error: unknown;
  refetch: (options?: RefetchOptions | undefined) => void;
  fetch: () => Promise<Record<string, Rule>>;
  getOrFetch: () => Promise<Record<string, Rule>>;
  removeRule: (ruleId: string) => Promise<void>;
  updateRule: (ruleId: string, data: Rule) => Promise<void>;
}

export function useRules({ enabled }: Partial<UseRulesOptions> = {}): UseRulesResult {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const queryClient = useQueryClient();
  const queryOptions: FetchQueryOptions<Record<string, Rule>> = {
    queryKey: [SCHEDULED_MESSAGING_RULES_QUERY_KEY],
    queryFn: async () => {
      const rules = await ScheduledMessagingApi.loadRules();
      return Object.fromEntries(rules.map((rule) => [rule.id, rule]));
    },
    staleTime: SCHEDULED_MESSAGING_QUERY_STALE_TIME,
    retry: false,
  };

  const { data, isLoading, error, refetch } = useQuery({
    ...queryOptions,
    enabled,
  });

  function fetch(): Promise<Record<string, Rule>> {
    return queryClient.fetchQuery({ ...queryOptions, staleTime: 0 });
  }

  function getOrFetch(): Promise<Record<string, Rule>> {
    return queryClient.ensureQueryData(queryOptions);
  }

  const removeRuleMutation = useMutation({
    mutationFn: async (ruleId: string) => {
      const orgConfig = await ScheduledMessagingApi.removeRule(organisation, ruleId);

      return [ruleId, orgConfig] as const;
    },
    onSuccess: (result: readonly [ruleId: string, ScheduledMessagingOrganisationConfig]) => {
      const [ruleId, orgConfig] = result;

      queryClient.setQueryData<Record<string, Rule>>([SCHEDULED_MESSAGING_RULES_QUERY_KEY], (rules) => {
        if (!rules) return rules;

        const { [ruleId]: removed, ...rest } = rules;

        return rest;
      });

      queryClient.setQueryData([SCHEDULED_MESSAGING_QUERY_KEY, organisation], orgConfig);
    },
  });

  const updateRuleMutation = useMutation({
    mutationFn: async (params: { ruleId: string; newRule: Rule }) => {
      const { ruleId, newRule } = params;

      const res = await ScheduledMessagingApi.updateRule(organisation, ruleId, newRule);

      return [ruleId, res] as const;
    },
    onSuccess: (result: readonly [ruleId: string, Rule]) => {
      const [ruleId, newRule] = result;

      queryClient.setQueryData<Record<string, Rule>>([SCHEDULED_MESSAGING_RULES_QUERY_KEY], (rules) => {
        if (!rules) return rules;

        return { ...rules, [ruleId]: newRule };
      });
    },
  });

  async function removeRule(ruleId: string) {
    await removeRuleMutation.mutateAsync(ruleId);
  }

  async function updateRule(ruleId: string, newRule: Rule) {
    await updateRuleMutation.mutateAsync({ ruleId, newRule });
  }

  return {
    rules: data,
    loading: isLoading,
    error,
    refetch,
    fetch,
    getOrFetch,
    removeRule,
    updateRule,
  };
}
