import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { Box } from '@mui/system';
import React from 'react';
import { useMessagingConfig } from '../../../../hooks/useMessagingConfig/useMessagingConfig';

import Chip from '@mui/material/Chip';

export function MessagingStats() {
  const { config, loading } = useMessagingConfig();

  if (loading) {
    return;
  }

  return (
    <Box width="220px">
      <List subheader={<ListSubheader component="div">Messages sent this month</ListSubheader>}>
        <ListItem
          data-testid="email-report"
          secondaryAction={<Chip label={config?.stats ? Number(config.stats.EMAIL.sent).toLocaleString() : 'n/a'} />}
        >
          <ListItemText
            primary={'E-Mails'}
            primaryTypographyProps={{
              variant: 'body2',
            }}
            sx={{ ml: 2 }}
          />
        </ListItem>
        <ListItem
          data-testid="email-report"
          secondaryAction={<Chip label={config?.stats ? Number(config.stats.SMS.sent).toLocaleString() : 'n/a'} />}
        >
          <ListItemText
            primary={'SMS'}
            primaryTypographyProps={{
              variant: 'body2',
            }}
            sx={{ ml: 2 }}
          />
        </ListItem>
      </List>
    </Box>
  );
}
