import { AxiosInstance } from 'axios';
import { OptimisationConfig } from '../../domain/Optimisation';
import { QOFOPT_API_NAME } from '../../util/constants';
import { getApiClient, httpGet, httpPut, resetApiClient } from '../../util/api';

function client(): AxiosInstance {
  return getApiClient(QOFOPT_API_NAME);
}

export async function loadOptimisationConfig(organisation: string): Promise<OptimisationConfig | null> {
  return httpGet(client(), `/organisations/${organisation}/config`, { nullOnError: true });
}

export async function saveOptimisationConfig(organisation: string, config: OptimisationConfig): Promise<void> {
  return httpPut(client(), `/organisations/${organisation}/config`, config) as Promise<void>;
}

export function resetClient() {
  resetApiClient(QOFOPT_API_NAME);
}
