import React, { useEffect } from 'react';
import { FAILURE, SUCCESS } from '../../../../../domain/ScheduledMessaging';
import { SuccessfulExecutionDetails } from './SuccessfulExecutionDetails';
import { FailedExecutionDetails } from './FailedExecutionDetails';
import { RunningExecutionDetails } from './RunningExecutionDetails';
import LinearProgress from '@mui/material/LinearProgress';
import { useRuleExecution } from '../../../../../hooks/useRuleExecution/useRuleExecution';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../../../../redux/reducers/notificationsReducer';

interface Props {
  executionId: string;
}

export function ExecutionDetails({ executionId }: Props) {
  const { report, downloadAllowed, downloadCsv, loading, error } = useRuleExecution(executionId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load the execution report', error));
    }
  }, [error]);

  if (loading || !report) {
    return <LinearProgress color="info" />;
  }

  const { status, summary, error: reportError } = report;

  return status === SUCCESS ? (
    <SuccessfulExecutionDetails summary={summary!} downloadAllowed={downloadAllowed} downloadCsv={downloadCsv} />
  ) : status === FAILURE ? (
    <FailedExecutionDetails error={reportError!} />
  ) : (
    <RunningExecutionDetails />
  );
}
