import { z } from 'zod';
import { AppointmentsConfig, AppointmentSlotsConfig } from '../../../domain/Appointments';
import { coalesce } from '../../../util/schema';

export const appointmentsConfigSchema = z.object({
  enabled: z.boolean(),
  organisationEmail: coalesce(
    z.object({
      address: z
        .string()
        .nullish()
        .transform((value) => value ?? null),
      verified: z.boolean(),
    }),
  ),
  bookingRequestRecipientUserId: coalesce(z.string()),
});

export function parseAppointmentsConfigResponse(response: unknown): AppointmentsConfig {
  return appointmentsConfigSchema.parse(response);
}

const appointmentSlotsConfigSchema = z.object({
  sites: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    }),
  ),
  holders: z.array(
    z.object({
      id: z.string(),
      guid: coalesce(z.string()),
      name: z.string(),
    }),
  ),
  slotTypes: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    }),
  ),
});

export function parseAppointmentSlotsConfigResponse(response: unknown): AppointmentSlotsConfig {
  return appointmentSlotsConfigSchema.parse(response);
}
