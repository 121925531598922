import React from 'react';
import { OrgDetails } from './helpers';
import Box from '@mui/material/Box';
import { DataGridPro, GridColDef, GridSlots } from '@mui/x-data-grid-pro';
import { ReadOnlyCheckbox } from './ReadOnlyCheckbox';
import dayjs from 'dayjs';
import { ISO_DATE_FORMAT } from '../../util/constants';
import LinearProgress from '@mui/material/LinearProgress';
import { BulkMessagingCheckbox } from './BulkMessagingCheckbox';
import { SingleMessagingCheckbox } from './SingleMessagingCheckbox';
import { AppointmentBookingEnabledCheckbox } from './AppointmentBookingEnabledCheckbox';
import { AutomatedMessagingEnabledCheckbox } from './AutomatedMessagingEnabledCheckbox';
import Link from '@mui/material/Link';
import { OnSiteEncryptionCheckbox } from './OnSiteEncryptionCheckbox';
import { AdvancedFilteringCheckbox } from './AdvancedFilteringCheckbox';

interface Props {
  data: OrgDetails[];
  loading: boolean;
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ODS Code',
    width: 120,
    renderCell: (params) => (
      <Link sx={{ color: 'black' }} href={`/#/organisations/${params.value}`} target="_blank" underline="none">
        {params.value}
      </Link>
    ),
  },
  {
    field: 'name',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Name',
    width: 160,
    renderCell: (params) => (
      <Link sx={{ color: 'black' }} href={`/#/organisations/${params.row.id}`} target="_blank" underline="none">
        {params.value}
      </Link>
    ),
  },
  { field: 'ehr', headerName: 'EHR', width: 60 },
  {
    field: 'contractExpiryDate',
    headerName: 'Contract expiry date',
    width: 100,
    renderCell: (params) => <>{params.value ? dayjs(params.value).format(ISO_DATE_FORMAT) : ''}</>,
  },
  {
    field: 'bulkMessagingEnabled',
    headerName: 'Bulk Messaging Enabled',
    width: 100,
    align: 'center',
    renderCell: (params) => <BulkMessagingCheckbox organisation={params.row.id} value={params.value} />,
  },
  {
    field: 'singleMessagingEnabled',
    headerName: 'Single Messaging Enabled',
    align: 'center',
    renderCell: (params) => <SingleMessagingCheckbox organisation={params.row.id} value={params.value} />,
  },
  {
    field: 'enabled',
    headerName: 'Appointment Booking Enabled',
    width: 100,
    align: 'center',
    renderCell: (params) => (
      <AppointmentBookingEnabledCheckbox
        organisation={params.row.id}
        enabled={params.row.enabled}
        email={params.row.email}
      />
    ),
  },
  { field: 'numOfBetaUsers', align: 'center', headerName: 'Num of Beta Users', width: 120 },
  { field: 'maxRecommendations', align: 'center', headerName: 'Max Recommendations', width: 130 },
  { field: 'smsAllowance', align: 'center', headerName: 'Monthly SMS Allowance', width: 120 },
  {
    field: 'automatedMessagingEnabled',
    headerName: 'Automated Messaging Enabled',
    width: 130,
    align: 'center',
    renderCell: (params) => <AutomatedMessagingEnabledCheckbox organisation={params.row.id} value={params.value} />,
  },
  {
    field: 'numOfAutomatedMessagingSetUp',
    headerName: 'Number of automations',
    width: 140,
    align: 'center',
  },
  {
    field: 'partnerApiSetUp',
    headerName: 'Partner API Set Up',
    width: 110,
    align: 'center',
    renderCell: (params) => <ReadOnlyCheckbox value={params.value} />,
  },
  {
    field: 'lastFullExtractDate',
    headerName: 'Last full extract (date received)',
    width: 100,
    align: 'center',
    renderCell: () => <>Pending</>,
  },
  {
    field: 'lastDelta',
    align: 'center',
    headerName: 'Last delta (date received)',
    width: 100,
    renderCell: () => <>Pending</>,
  },
  { field: 'validDate', align: 'center', headerName: 'Valid date', width: 100, renderCell: () => <>Pending</> },
  {
    field: 'onSiteEncryption',
    align: 'center',
    headerName: 'On site encryption',
    width: 100,
    renderCell: (params) => (
      <OnSiteEncryptionCheckbox organisationId={params.row.id} value={params.value} ehr={params.row.ehr} />
    ),
  },
  {
    field: 'advancedFiltering',
    align: 'center',
    headerName: 'Advanced filtering',
    width: 100,
    renderCell: (params) => <AdvancedFilteringCheckbox organisationId={params.row.id} value={params.value} />,
  },
];

export const OrganisationList: React.FC<Props> = ({ data, loading }) => {
  return (
    <Box pt={2}>
      <DataGridPro
        columnHeaderHeight={80}
        sx={{
          textAlign: 'center',
          height: 'calc(100vh - 170px)',
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            textAlign: 'center',
            lineHeight: 1.2,
          },
        }}
        loading={loading}
        rows={data}
        columns={columns}
        slots={{
          loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
        }}
        initialState={{ pinnedColumns: { left: ['id'] } }}
      />
    </Box>
  );
};
