import { z } from 'zod';
import { OptimisationConfig } from '../../../domain/Optimisation';

export const optimisationConfigSchema = z.object({
  maxRecommendations: z.number().int().or(z.literal('ALL')),
});

export function parseOptimisationConfig(response: unknown): OptimisationConfig {
  return optimisationConfigSchema.parse(response);
}
