import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { SlotTypeSelect } from './SlotTypeSelect/SlotTypeSelect';
import { GpSelect } from './GpSelect/GpSelect';

export function DoubleBookingConfiguration() {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { organisation } = useCurrentOrganisationDetails();

  return (
    <Stack gap={1}>
      <Controller
        name="doubleBookingPreventionEnabled"
        control={control}
        render={({ field: { onChange: onEnabledChange, value: enabled } }) => (
          <Stack gap={1}>
            <FormControlLabel
              control={<Checkbox checked={enabled} size="small" disableRipple onChange={onEnabledChange} />}
              label={
                <Typography display="flex" alignContent="center" alignItems="center" component="div">
                  Do not message patients with appointments booked:
                </Typography>
              }
            />

            <Stack marginLeft="27px" gap={1}>
              {organisation.ehr === 'emis' ? <SlotTypeSelect /> : <GpSelect />}

              <Controller
                name="doubleBookingPreventionEmbargoWindow[0]"
                control={control}
                rules={{
                  required: 'Must be ≥ 0',
                  min: {
                    value: 0,
                    message: 'Must be ≥ 0',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Typography display="flex" alignContent="center" alignItems="center" component="div">
                    In the last
                    <Box mx={1}>
                      <TextField
                        type="number"
                        value={value}
                        disabled={!enabled}
                        onChange={onChange}
                        size="small"
                        inputProps={{ sx: { width: '60px' }, min: 0, 'aria-label': 'Max days ago' }}
                        error={!!errors.doubleBookingPreventionEmbargoWindow?.[0]}
                        helperText={(errors.doubleBookingPreventionEmbargoWindow?.[0] as FieldError)?.message}
                      />
                    </Box>
                    day(s)
                  </Typography>
                )}
              />

              <Controller
                name="doubleBookingPreventionEmbargoWindow[1]"
                control={control}
                rules={{
                  required: 'Must be ≥ 0',
                  min: {
                    value: 0,
                    message: 'Must be ≥ 0',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Typography display="flex" alignContent="center" alignItems="center" component="div">
                    Or within the next
                    <Box mx={1}>
                      <TextField
                        type="number"
                        value={value}
                        disabled={!enabled}
                        onChange={onChange}
                        size="small"
                        inputProps={{ sx: { width: '60px' }, min: 0, 'aria-label': 'Max days ahead' }}
                        error={!!errors.doubleBookingPreventionEmbargoWindow?.[1]}
                        helperText={(errors.doubleBookingPreventionEmbargoWindow?.[1] as FieldError)?.message}
                      />
                    </Box>
                    day(s)
                  </Typography>
                )}
              />
            </Stack>
          </Stack>
        )}
      />
    </Stack>
  );
}
