import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import UserContext from '../../../../context/UserContext';
import { SmsAllowanceSlider } from '../SmsAllowanceSlider/SmsAllowanceSlider';
import { LoadingSection } from '../../../shared/LoadingSection/LoadingSection';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { useMessagingConfig } from '../../../../hooks/useMessagingConfig/useMessagingConfig';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { isOrgAdmin } from '../../../../domain/User';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { buildEmailSignature } from '../../../../util/emails';
import { DEFAULT_SIGNATURE_TYPE } from '../../../../util/constants';
import { SmsSenderIdConfig } from '../SmsSenderIdConfig/SmsSenderIdConfig';

export function MessagingConfig() {
  const { organisation: organisationDetails } = useCurrentOrganisationDetails();
  const { organisation } = organisationDetails;
  const { config, loading, error, updateConfig } = useMessagingConfig();

  const context = useContext(UserContext);
  const user = context.user;
  const signatureType = config?.config.templateSignatureType ?? DEFAULT_SIGNATURE_TYPE;
  const senderId = config?.config.senderId ?? null;

  const dispatch = useDispatch();

  const isSuperUser = user?.superUser ?? false;
  const isSupportUser = user?.supportUser ?? false;
  const isAdmin = isOrgAdmin(user, organisation);

  const isSingleMessagingEnabled = config?.config.singleMessagingEnabled;
  const isBulkMessagingEnabled = config?.config.bulkMessagingEnabled;
  const smsAllowance = config?.stats.SMS.limit;
  const isAddressIncludedInSignature = signatureType === 'NAME_AND_ADDRESS';

  const canEditSingleMessaging = isSuperUser || isSupportUser;
  const canEditBulkMessaging = isSuperUser || isSupportUser;
  const canEditSmsAllowance = isSuperUser || isSupportUser;
  const canEditSignatureType = isSuperUser || isSupportUser || isAdmin;
  const canEditSenderId = isSuperUser || isSupportUser;
  const canClearSenderId = isSuperUser || isSupportUser || isAdmin;

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load the messaging configuration', error));
    }
  }, [error]);

  async function onSingleMessagingChange(checked: boolean) {
    setSaving(true);
    try {
      await updateConfig({ singleMessagingEnabled: checked });
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    } finally {
      setSaving(false);
    }
  }

  async function onBulkMessagingChange(checked: boolean) {
    setSaving(true);
    try {
      await updateConfig({ bulkMessagingEnabled: checked });
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    } finally {
      setSaving(false);
    }
  }

  async function onSaveSmsLimit(limit: number) {
    try {
      await updateConfig({ limits: { sms: limit } });
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    }
  }

  async function onIncludeAddressInSignatureChange(checked: boolean) {
    setSaving(true);
    try {
      await updateConfig({ templateSignatureType: checked ? 'NAME_AND_ADDRESS' : 'NAME_ONLY' });
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    } finally {
      setSaving(false);
    }
  }

  async function onSetSenderId(newSenderId: string | null) {
    setSaving(true);
    try {
      await updateConfig({ senderId: newSenderId });
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to update the SMS Sender ID', err));
    } finally {
      setSaving(false);
    }
  }

  return (
    <LoadingSection loading={loading}>
      <Backdrop open={saving} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>

      <Box pl={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSingleMessagingEnabled}
              disabled={!canEditSingleMessaging}
              onChange={(e) => onSingleMessagingChange(e.target.checked)}
            />
          }
          label={<Typography variant="body1">Allow users to send single messages to patients</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isBulkMessagingEnabled}
              disabled={!canEditBulkMessaging}
              onChange={(e) => onBulkMessagingChange(e.target.checked)}
            />
          }
          label={<Typography variant="body1">Allow users to send bulk messages to patients</Typography>}
        />
      </Box>

      <SmsAllowanceSlider
        limit={smsAllowance}
        saveSmsLimit={(newLimit) => onSaveSmsLimit(newLimit)}
        enabled={canEditSmsAllowance}
      />

      <Stack pl={2} gap={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAddressIncludedInSignature}
              disabled={!canEditSignatureType}
              onChange={(e) => onIncludeAddressInSignatureChange(e.target.checked)}
            />
          }
          label={
            <Typography variant="body1">
              Include{' '}
              <Typography display="inline" component="strong" fontWeight="medium">
                primary practice address
              </Typography>{' '}
              in E-mail signature
            </Typography>
          }
        />
      </Stack>

      <Typography pl={2} color="text.secondary" variant="body1">
        Your e-mail signature will display as
      </Typography>

      <Stack maxWidth={600} pl={2}>
        <Paper square variant="outlined">
          <Stack bgcolor="grey.100" pl={2} minHeight="48px" component="header">
            <Typography my="12px" variant="subtitle1">
              E-mail signature
            </Typography>
          </Stack>
          <Divider />
          <Typography p={2} color="text.secondary" variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
            {buildEmailSignature(organisationDetails, signatureType)}
          </Typography>
        </Paper>
      </Stack>

      <Box pl={2}>
        <SmsSenderIdConfig
          senderId={senderId}
          setSenderId={onSetSenderId}
          canEditSenderId={canEditSenderId}
          canClearSenderId={canClearSenderId}
        />
      </Box>
    </LoadingSection>
  );
}
