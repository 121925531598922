import Chip from '@mui/material/Chip';
import Grid2 from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { OrganisationEmail } from '../../../../domain/Organisation';
import { LoadingActionButton } from '../../../shared/LoadingActionButton';

interface Props {
  emails: OrganisationEmail[];
  onRemoveEmail: (email: string) => Promise<void>;
  onResendVerificationEmail(email: string): Promise<void>;
  canManage: boolean;
}

export function OrganisationEmailsTable({ emails, onRemoveEmail, onResendVerificationEmail, canManage }: Props) {
  if (emails.length === 0) {
    return <></>;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Organisation e-mail</TableCell>
            <TableCell>Invite Status</TableCell>
            <TableCell align="center">Manage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emails
            .sort((a, b) => a.email.localeCompare(b.email))
            .map(({ email, verified }) => (
              <TableRow key={email}>
                <TableCell>{email}</TableCell>
                <TableCell>
                  {verified ? (
                    <Chip label="Verified" color="success" />
                  ) : (
                    <Chip label="Pending Verification" color="warning" />
                  )}
                </TableCell>
                <TableCell>
                  <Grid2 container direction="row" spacing={4}>
                    <Grid2 size={6}>
                      {!verified && (
                        <Stack direction="row" justifyContent="right">
                          <LoadingActionButton
                            color="info"
                            onClick={async () => await onResendVerificationEmail(email)}
                            disabled={!canManage}
                          >
                            Resend
                          </LoadingActionButton>
                        </Stack>
                      )}
                    </Grid2>
                    <Grid2 size={6}>
                      <Stack direction="row">
                        <LoadingActionButton
                          color="error"
                          onClick={async () => await onRemoveEmail(email)}
                          disabled={!canManage}
                        >
                          Remove
                        </LoadingActionButton>
                      </Stack>
                    </Grid2>
                  </Grid2>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
