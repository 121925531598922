import { AxiosInstance } from 'axios';
import { AUTH_API_NAME } from '../../util/constants';
import { callApi, getApiClient, resetApiClient } from '../../util/api';

function client(): AxiosInstance {
  return getApiClient(AUTH_API_NAME);
}

export async function verifyAccount(username: string, code: string, audience: string): Promise<void> {
  return callApi(
    client().get,
    '/verify',
    undefined,
    {
      params: {
        username,
        code,
        clientId: audience,
      },
    },
    false,
    true,
  ) as Promise<void>;
}

export function resetClient() {
  resetApiClient(AUTH_API_NAME);
}
