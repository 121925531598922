import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useContext, useMemo, useState } from 'react';
import rulesContext from '../../../../context/RulesContext/RulesContext';
import Switch from '@mui/material/Switch';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';
import { Rule, RuleOrganisationConfig } from '../../../../domain/ScheduledMessaging';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AddRuleDialog } from '../AddRuleDialog/AddRuleDialog';
import { useDialog } from '../../../../hooks/useDialog/useDialog';
import userContext from '../../../../context/UserContext';

export function getSortedRules(params: {
  orgId: string;
  rules: Record<string, Rule> | undefined;
  configuredRules: Record<string, RuleOrganisationConfig> | undefined;
}) {
  const { orgId, rules, configuredRules } = params;

  if (!rules || !configuredRules) return;

  const configuredIds = Object.keys(configuredRules);

  if (!configuredIds.length) return;

  const unsorted = configuredIds.map((id) => {
    const rule: Rule | undefined = rules[id];
    const config = configuredRules[id];
    return [id, rule, config] as const;
  });

  const generatedPrefix = `${orgId}_`;

  function isGeneratedName(name: string) {
    return name.startsWith(generatedPrefix);
  }

  function getGeneratedNameIndex(name: string) {
    const indexStr = name.replace(generatedPrefix, '');
    const index = parseInt(indexStr);
    return isNaN(index) ? null : index;
  }

  return unsorted.sort(([idA, ruleA], [idB, ruleB]) => {
    const nameA = ruleA?.name ?? idA;
    const nameB = ruleB?.name ?? idB;
    const isGeneratedNameA = isGeneratedName(nameA);
    const isGeneratedNameB = isGeneratedName(nameB);
    const indexA = isGeneratedNameA ? getGeneratedNameIndex(nameA) : null;
    const indexB = isGeneratedNameB ? getGeneratedNameIndex(nameB) : null;

    if (indexA !== null && indexB !== null) {
      return indexA - indexB;
    }

    if (isGeneratedNameA !== isGeneratedNameB) {
      return isGeneratedNameA ? -1 : 1;
    }

    return nameA.localeCompare(nameB);
  });
}

export function RulesList() {
  const { rules, configuredRules, selectedRule, swapRule, addRuleConfig, updateRuleConfig, hasUnsavedChanges } =
    useContext(rulesContext);
  const currentOrgDetails = useCurrentOrganisationDetails();
  const { user } = useContext(userContext);
  const isSuperUser = user?.superUser ?? false;
  const isSupportUser = user?.supportUser ?? false;
  const canAddRuleConfig = isSuperUser || isSupportUser;

  const [toggling, setToggling] = useState(false);

  const dispatch = useDispatch();

  const { open, connector } = useDialog<{}, string>();

  function ensureRuleCanBeEnabled(rule: RuleOrganisationConfig): boolean {
    if (rule.patientSelectionCriteria.maxAllowedByAbtrace <= 0) {
      dispatch(
        enqueueErrorNotification(
          'This rule is disabled for your organisation by Abtrace. Please contact Abtrace at support@abtrace.co',
        ),
      );
      return false;
    }

    if (rule.patientSelectionCriteria.maxDefinedByPractice <= 0) {
      dispatch(
        enqueueErrorNotification('Please increase the maximum number of patients to contact before enabling the rule'),
      );
      return false;
    }

    if (!rule.preference) {
      dispatch(enqueueErrorNotification('Please select between E-Mail or SMS messages before enabling the rule'));
      return false;
    }

    return true;
  }

  async function toggleRule(ruleId: string, enabled: boolean) {
    if (configuredRules && configuredRules[ruleId]) {
      if (enabled && !ensureRuleCanBeEnabled(configuredRules[ruleId])) {
        return;
      }

      setToggling(true);
      try {
        await updateRuleConfig(ruleId, {
          ...configuredRules[ruleId],
          enabled,
        });
      } catch (error) {
        dispatch(enqueueErrorNotification('Unable to toggle the rule', error));
      } finally {
        setToggling(false);
      }
    }
  }

  const openAddRuleConfigDialog = () => {
    open({}).then(async (ruleId) => {
      try {
        await addRuleConfig(ruleId);
        dispatch(enqueueSuccessNotification('Rule config added'));
      } catch (error) {
        dispatch(enqueueErrorNotification('Unable to add the rule config to the practice', error));
      }
    });
  };

  const sortedRules = useMemo(
    () =>
      getSortedRules({
        orgId: currentOrgDetails.organisation.organisation,
        rules,
        configuredRules,
      }),
    [configuredRules, rules],
  );

  return (
    <Box display="flex" flexDirection="column" gap={3} pb={3} width={300} maxHeight="75vh">
      <Backdrop open={toggling} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress data-testid="spinner" />
      </Backdrop>
      <List disablePadding sx={{ overflow: 'auto' }}>
        {!sortedRules ? (
          <ListItem disablePadding>
            <ListItemButton disabled={true}>
              <ListItemText primary="No rules configured" />
            </ListItemButton>
          </ListItem>
        ) : (
          sortedRules.map(([ruleId, ruleDefinition, ruleConfig]) => {
            const selected = selectedRule?.id === ruleId;

            return (
              <ListItem key={ruleId} disablePadding>
                <ListItemButton
                  selected={selectedRule?.id === ruleId}
                  disabled={(hasUnsavedChanges && !selected) || !rules?.[ruleId]}
                  onClick={() => swapRule(ruleId)}
                >
                  <ListItemText
                    primary={
                      <Stack direction="row" gap={0.5} alignItems="baseline">
                        {hasUnsavedChanges && selected ? <Typography color="primary">*</Typography> : undefined}
                        <Typography>{ruleDefinition?.name ?? ruleId}</Typography>
                      </Stack>
                    }
                    secondary={!rules?.[ruleId] ? 'Nonexistent rule' : ruleDefinition?.description}
                    secondaryTypographyProps={{ textOverflow: 'ellipsis', overflow: 'hidden', noWrap: true }}
                  />
                  <Switch
                    edge="end"
                    checked={ruleConfig.enabled}
                    onChange={(_event, checked) => toggleRule(ruleId, checked)}
                    disabled={hasUnsavedChanges}
                  />
                </ListItemButton>
              </ListItem>
            );
          })
        )}
      </List>
      {canAddRuleConfig && (
        <>
          <Button onClick={openAddRuleConfigDialog} sx={{ alignSelf: 'end' }}>
            Add rule config
          </Button>
          <AddRuleDialog connector={connector} />
        </>
      )}
    </Box>
  );
}
