import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MessagingConfigTab } from './MessagingConfigTab/MessagingConfigTab';
import { BulkMessageReportsTab } from './BulkMessageReportsTab/BulkMessageReportsTab';
import { MessagingStatsTab } from './MessagingStatsTab/MessagingStatsTab';

export function Messaging() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box position="relative" display="flex" flexDirection="column" gap={3}>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Configuration" />
        <Tab label="Statistics" />
        <Tab label="Bulk Message Reports" />
      </Tabs>

      {selectedTab === 0 && <MessagingConfigTab />}
      {selectedTab === 1 && <MessagingStatsTab />}
      {selectedTab === 2 && <BulkMessageReportsTab />}
    </Box>
  );
}
