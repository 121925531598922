import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { MultipleChoiceSelect } from '../../../../shared/MultipleChoiceSelect/MultipleChoiceSelect';

import { useAppointmentSlotsConfig } from '../../../../../hooks/useAppointmentSlotsConfig/useAppointmentSlotsConfig';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Refresh from '@mui/icons-material/Refresh';

export function GpSelect() {
  const form = useFormContext();

  const doubleBookingPreventionEnabled = form.watch('doubleBookingPreventionEnabled');

  const {
    loading: appointmentSlotsConfigLoading,
    config: appointmentSlotsConfig,
    error: appointmentSlotsError,
    refetch: appointmentSlotsRefetch,
  } = useAppointmentSlotsConfig(true);

  const sortedHolders = appointmentSlotsConfig?.holders.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Controller
        name="doubleBookingPreventionGpIds"
        control={form.control}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <FormControlLabel
                label={<Typography>With any of these GPs:</Typography>}
                sx={{ display: 'flex', gap: 1, margin: 0 }}
                labelPlacement="start"
                control={
                  <Box flexGrow="1">
                    <MultipleChoiceSelect
                      noOptionsText="No GPs found"
                      options={sortedHolders ?? []}
                      value={sortedHolders?.filter((gp) => value.includes(gp.id.toString())) ?? []}
                      onChange={(newValue) => {
                        onChange(newValue.map((holder) => holder.id.toString()));
                      }}
                      getPrimaryText={(holder) => holder.name}
                      label="GPs"
                      placeholder="GPs"
                      loading={appointmentSlotsConfigLoading}
                      disabled={!doubleBookingPreventionEnabled || !!appointmentSlotsError}
                      getInputParams={(params) => ({
                        ...params,
                        error: !!appointmentSlotsError,
                      })}
                      slotProps={{
                        paper: {
                          sx: {
                            border: '1px solid',
                            boxShadow: 1,
                          },
                        },
                      }}
                    />
                  </Box>
                }
              />
            </>
          );
        }}
        defaultValue={[]}
      />

      {!!appointmentSlotsError && (
        <Box marginBottom={2}>
          <Alert
            severity="error"
            action={
              <Button color="inherit" size="small" startIcon={<Refresh />} onClick={() => appointmentSlotsRefetch()}>
                Retry
              </Button>
            }
          >
            There was an error loading GPs
          </Alert>
        </Box>
      )}
    </>
  );
}
