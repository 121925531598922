import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MESSAGING_QUERY_KEY, MESSSAGING_QUERY_STALE_TIME } from '../../util/queries';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { loadMessagingConfig, updateMessagingConfig } from '../../apis/messaging/MessagingApi';
import { MessagingConfig, SignatureType } from '../../domain/Messaging';

interface UseMessagingConfigResult {
  config?: MessagingConfig;
  loading: boolean;
  error: unknown;
  updateConfig: (payload: {
    singleMessagingEnabled?: boolean;
    bulkMessagingEnabled?: boolean;
    templateSignatureType?: SignatureType;
    limits?: {
      sms: number;
    };
  }) => Promise<void>;
}

export function useMessagingConfig(): UseMessagingConfigResult {
  const { organisation: organisationDetails } = useCurrentOrganisationDetails();
  const { organisation } = organisationDetails;

  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: [MESSAGING_QUERY_KEY, organisation],
    queryFn: () => loadMessagingConfig(organisation),
    staleTime: MESSSAGING_QUERY_STALE_TIME,
    retry: false,
  });

  const updateMessagingConfigMutation = useMutation({
    mutationFn: ({
      singleMessagingEnabled,
      bulkMessagingEnabled,
      templateSignatureType,
      limits,
    }: {
      singleMessagingEnabled?: boolean;
      bulkMessagingEnabled?: boolean;
      templateSignatureType?: SignatureType;
      limits?: {
        sms: number;
      };
    }) =>
      updateMessagingConfig(organisation, {
        singleMessagingEnabled,
        bulkMessagingEnabled,
        templateSignatureType,
        limits,
      }),
    onSuccess: (_result, variables) => {
      queryClient.setQueryData<MessagingConfig>(
        [MESSAGING_QUERY_KEY, organisation],
        (previousConfig: MessagingConfig | undefined) =>
          previousConfig
            ? {
                ...previousConfig,
                config: {
                  ...previousConfig.config,
                  singleMessagingEnabled:
                    variables.singleMessagingEnabled ?? previousConfig.config.singleMessagingEnabled,
                  bulkMessagingEnabled: variables.bulkMessagingEnabled ?? previousConfig.config.bulkMessagingEnabled,
                  templateSignatureType: variables.templateSignatureType ?? previousConfig.config.templateSignatureType,
                },
                stats: {
                  ...previousConfig.stats,
                  SMS: {
                    ...previousConfig.stats.SMS,
                    limit: variables.limits?.sms ?? previousConfig.stats.SMS.limit,
                  },
                },
              }
            : previousConfig,
      );
    },
  });

  async function updateConfig(payload: {
    singleMessagingEnabled?: boolean;
    bulkMessagingEnabled?: boolean;
    limits?: {
      sms: number;
    };
  }): Promise<void> {
    await updateMessagingConfigMutation.mutateAsync(payload);
  }

  return {
    config: data ?? undefined,
    loading: isLoading,
    error,
    updateConfig,
  };
}
