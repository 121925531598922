import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { DependencyList, ReactNode, UIEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import NavBar from '../../components/navigation/NavBar/NavBar';
import { ContractExpiryDate } from '../../components/organisations/ContractExpiryDate';
import { EhrSelector } from '../../components/organisations/EhrSelector';
import { Messaging } from '../../components/organisations/Messaging/Messaging';
import { OptimisationConfig } from '../../components/organisations/OptimisationConfig/OptimisationConfig';
import OrganisationApplications from '../../components/organisations/OrganisationApplications/OrganisationApplications';
import OrganisationInvitations from '../../components/organisations/OrganisationInvitations/OrganisationInvitations';
import { OrganisationName } from '../../components/organisations/OrganisationName/OrganisationName';
import OrganisationUsers from '../../components/organisations/OrganisationUsers/OrganisationUsers';
import { ScheduledMessagingConfig } from '../../components/organisations/ScheduledMessagingConfig/ScheduledMessagingConfig';
import OrganisationUsersContext, {
  OrganisationUsersContextProvider,
} from '../../context/OrganisationUsersContext/OrganisationUsersContext';
import userContext from '../../context/UserContext';
import { isOrgAdmin } from '../../domain/User';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { PatientFacingServices } from '../../components/organisations/PatientFacingServices/PatientFacingServices';

function Memo(props: { children: ReactNode; deps: DependencyList }) {
  const { children, deps } = props;

  const memo = useMemo(() => children, [...deps]);

  return memo;
}

function useThrottle<T extends (...params: any[]) => any>(fn: T, delay: number) {
  const { clearTimeout, setTimeout } = window;
  const lastExecution = useRef(0);
  const timeoutId = useRef(-1);

  function execute(...args) {
    lastExecution.current = Date.now();

    fn(...args);
  }

  function control(...args) {
    clearTimeout(timeoutId.current);

    const timeout = delay - (Date.now() - lastExecution.current);

    if (timeout > 0) {
      timeoutId.current = setTimeout(() => execute(...args), timeout);
    } else {
      execute(...args);
    }
  }

  return control;
}

export default function OrganisationPage() {
  const match = useMatch('/organisations/:name');
  const organisation = match?.params.name!;
  const { user } = useContext(userContext);
  const [currentlyViewedSection, setCurrentlyViewedSection] = useState<HTMLElement>();

  const isAdmin = isOrgAdmin(user!, organisation);
  const isSuperUser = user?.superUser ?? false;
  const isSupportUser = user?.supportUser ?? false;
  const canAdmin = isSuperUser || isSupportUser || isAdmin;
  const canEditConfig = isSuperUser || isSupportUser;
  const membersSectionTitle = isAdmin ? 'Members' : 'Admins';

  const ehrSectionRef = useRef<HTMLDivElement | null>(null);
  const contractExpirySectionRef = useRef<HTMLDivElement | null>(null);
  const membersSectionRef = useRef<HTMLDivElement | null>(null);
  const invitationsSectionRef = useRef<HTMLDivElement | null>(null);
  const applicationsSectionRef = useRef<HTMLDivElement | null>(null);
  const optimisationSectionRef = useRef<HTMLDivElement | null>(null);
  const patientFacingSectionRef = useRef<HTMLDivElement | null>(null);
  const messagingSectionRef = useRef<HTMLDivElement | null>(null);
  const scheduledMessagingSectionRef = useRef<HTMLDivElement | null>(null);

  const sections = [
    {
      ref: ehrSectionRef,
      title: 'EHR',
      isVisible: canEditConfig,
      component: <EhrSelector />,
    },
    {
      ref: contractExpirySectionRef,
      title: 'Contract expiry date',
      isVisible: true,
      component: <ContractExpiryDate />,
    },
    {
      ref: membersSectionRef,
      title: membersSectionTitle,
      isVisible: true,
      component: <OrganisationUsers organisation={organisation} />,
    },
    {
      ref: invitationsSectionRef,
      title: 'Pending invitations',
      isVisible: canAdmin,
      component: <OrganisationInvitations organisation={organisation} />,
    },
    {
      ref: applicationsSectionRef,
      title: 'Applications',
      isVisible: canAdmin,
      component: (
        <OrganisationUsersContext.Consumer>
          {({ refresh }) => (
            // Refresh users when an application is approved
            <OrganisationApplications organisation={organisation} onApplicationApproved={refresh} />
          )}
        </OrganisationUsersContext.Consumer>
      ),
    },
    {
      ref: optimisationSectionRef,
      title: 'Optimisation configuration',
      isVisible: canAdmin,
      component: <OptimisationConfig organisation={organisation} enabled={canEditConfig} />,
    },
    {
      ref: patientFacingSectionRef,
      title: 'Patient facing services',
      isVisible: canAdmin,
      component: <PatientFacingServices organisation={organisation} enabled={isSuperUser || isAdmin} />,
    },
    {
      ref: messagingSectionRef,
      title: 'Messaging',
      isVisible: canAdmin,
      component: <Messaging />,
    },
    {
      ref: scheduledMessagingSectionRef,
      title: 'Automated recalls',
      isVisible: canAdmin,
      component: <ScheduledMessagingConfig />,
    },
  ].map((section) => ({
    ...section,
    isCurrentlyViewed: section.ref.current === currentlyViewedSection,
    scrollIntoView: () => {
      if (!section.ref.current) return;

      section.ref.current.scrollIntoView({ behavior: 'instant' });

      setCurrentlyViewedSection(section.ref.current);
    },
  }));

  useEffect(() => {
    // Set the initially selected element to the first section in the list when the page is loaded
    setCurrentlyViewedSection(sections[0].ref.current!);
  }, []);

  const onScroll = useThrottle((e: UIEvent) => {
    const container = e.target as HTMLElement;

    for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i];
      const element = section.ref.current!;
      const offsetTop = i === 0 ? 0 : element.offsetTop;
      const isCurrentlyViewed = offsetTop <= container.scrollTop;

      if (isCurrentlyViewed) {
        return setCurrentlyViewedSection(element);
      }
    }
  }, 100);

  return (
    <>
      <NavBar />

      <Box onScroll={onScroll} position="relative" display="flex" gap={6} flex={1} overflow="auto">
        <Box
          component="nav"
          position="sticky"
          top={0}
          left={0}
          zIndex={2}
          width={300}
          flexShrink={0}
          overflow="auto"
          borderRight="1px solid rgba(0, 0, 0, 0.12)"
          sx={(theme) => ({ background: theme.palette.background.paper })}
        >
          <List
            sx={(theme) => ({
              padding: 0,
              '& .Mui-selected': { borderRight: `2px solid ${theme.palette.primary.main}` },
            })}
          >
            {sections
              .filter((section) => section.isVisible)
              .map((section) => (
                <ListItem key={`${section.title}-list-item`} disablePadding>
                  <ListItemButton selected={section.isCurrentlyViewed} onClick={section.scrollIntoView}>
                    <ListItemText
                      primary={section.title}
                      primaryTypographyProps={{ fontWeight: 500, fontSize: 18, padding: '10px' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>

        <Memo deps={[organisation]}>
          <Box component="main" display="flex" flexDirection="column" mt={6}>
            <OrganisationName />
            <OrganisationUsersContextProvider organisation={organisation}>
              {sections
                .filter((section) => section.isVisible)
                .map((section) => (
                  // pt=6 instead of gap=6 on the parent is to streamline 'currently viewed section' logic and tests
                  <Box ref={section.ref} key={`${section.title}-item`} pt={6}>
                    <Typography variant="h5">{section.title}</Typography>
                    {section.component}
                  </Box>
                ))}
            </OrganisationUsersContextProvider>
          </Box>
        </Memo>
      </Box>
    </>
  );
}
