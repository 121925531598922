import { useQuery } from '@tanstack/react-query';
import { BULK_MESSAGE_REPORT_QUERY_KEY, BULK_MESSAGE_REPORTS_STALE_TIME } from '../../util/queries';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { loadBulkMessageReport } from '../../apis/messaging/MessagingApi';
import { BulkMessageDetailedReport, BulkMessageSummaryReport } from '../../domain/Messaging';
import { useContext } from 'react';
import UserContext from '../../context/UserContext';
import { isAdminAt } from '../../domain/User';

interface UseBulkMessageReportsResult {
  timestamp: Date | undefined;
  summary: BulkMessageSummaryReport | undefined;
  downloadAllowed: boolean;
  details: BulkMessageDetailedReport | undefined;
  loading: boolean;
  error: unknown;
}

export function useBulkMessageReport(reportId: string): UseBulkMessageReportsResult {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const context = useContext(UserContext);
  const user = context.user;

  const downloadAllowed = !!user && isAdminAt(user, organisation);

  const { data, isPending, isLoading, error } = useQuery({
    queryKey: [BULK_MESSAGE_REPORT_QUERY_KEY, organisation, reportId],
    queryFn: () => loadBulkMessageReport(organisation, reportId, downloadAllowed),
    staleTime: BULK_MESSAGE_REPORTS_STALE_TIME,
    retry: false,
  });

  return {
    timestamp: data?.timestamp,
    summary: data?.summary,
    downloadAllowed,
    details: data?.details,
    loading: isPending || isLoading,
    error,
  };
}
