import React, { MouseEvent } from 'react';
import { RuleExecutionSummaryReport } from '../../../../../domain/ScheduledMessaging';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import RuleIcon from '@mui/icons-material/Rule';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import { generateReportUrl } from '../../../../../apis/scheduledMessaging/ScheduledMessagingApi';
import Button from '@mui/material/Button';
import { enqueueErrorNotification } from '../../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';

interface Props {
  id: string;
  summary: RuleExecutionSummaryReport;
}

export function SuccessfulExecutionDetails({ id, summary }: Props) {
  const { matchedPatients, selected, emailed, texted, unreachable, patientRecordUpdateErrors } = summary;

  const dispatch = useDispatch();

  return (
    <Box m={1}>
      <List subheader={<ListSubheader component="div">Execution report</ListSubheader>}>
        <ListItem data-testid="matched-report">
          <ListItemIcon>
            <RuleIcon />
          </ListItemIcon>
          <ListItemText
            primary={`${matchedPatients} patient${matchedPatients === 1 ? '' : 's'} matched the criteria`}
            primaryTypographyProps={{
              variant: 'body2',
            }}
          />
        </ListItem>

        <ListItem data-testid="selected-report">
          <ListItemIcon>
            <HowToRegIcon />
          </ListItemIcon>
          <ListItemText
            primary={`${selected} patient${selected === 1 ? '' : 's'} selected for messaging`}
            primaryTypographyProps={{
              variant: 'body2',
            }}
          />
        </ListItem>

        {!!emailed && emailed > 0 && (
          <ListItem data-testid="email-report">
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${emailed} e-mail${emailed === 1 ? '' : 's'} sent`}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {emailed === 0 && (
          <ListItem data-testid="email-error-report">
            <ListItemIcon>
              <EmailIcon color="error" />
            </ListItemIcon>
            <ListItemText primary={'No e-mails were sent'} />
          </ListItem>
        )}

        {!!texted && texted > 0 && (
          <ListItem data-testid="sms-report">
            <ListItemIcon>
              <SmsIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${texted} SMS message${texted === 1 ? '' : 's'} sent`}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {texted === 0 && (
          <ListItem data-testid="sms-error-report">
            <ListItemIcon>
              <SmsIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={'No SMS messages were sent'}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {!!unreachable && unreachable > 0 && (
          <ListItem data-testid="unreachable-report">
            <ListItemIcon>
              <WarningIcon color="warning" />
            </ListItemIcon>
            <ListItemText
              primary={`${unreachable} patient${selected === 1 ? '' : 's'} not contacted`}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}

        {!!patientRecordUpdateErrors && patientRecordUpdateErrors > 0 && (
          <ListItem data-testid="record-update-errors-report">
            <ListItemIcon>
              <ErrorIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={`${patientRecordUpdateErrors} patient record${
                patientRecordUpdateErrors === 1 ? '' : 's'
              } failed to update`}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>
        )}
      </List>

      <Button
        href="#"
        onClick={async (event: MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();
          try {
            window.location.assign(await generateReportUrl(id));
          } catch (error) {
            dispatch(enqueueErrorNotification('Unable to download the report', error));
          }
        }}
        size="small"
        sx={{
          marginLeft: 1,
        }}
      >
        Download full report
      </Button>
    </Box>
  );
}
