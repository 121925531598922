export default function groupBy<T extends AnyRecord, K extends keyof T>(array: T[], key: K): Result<T, T[K]> {
  const result = {} as Result<T, T[K]>;

  array.forEach((item) => {
    const value = item[key];

    result[value] ??= [];
    result[value].push(item);
  });

  return result;
}

type Result<T extends AnyRecord, K extends keyof any> = Record<K, T[]>;

type AnyRecord = Record<any, any>;
