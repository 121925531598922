import { AxiosInstance } from 'axios';
import { OptimisationConfig } from '../../domain/Optimisation';
import { QOFOPT_API_NAME } from '../../util/constants';
import { callApi, getApiClient, resetApiClient } from '../../util/api';

function client(): AxiosInstance {
  return getApiClient(QOFOPT_API_NAME);
}

export async function loadOptimisationConfig(organisation: string): Promise<OptimisationConfig | null> {
  return callApi(client().get, `/organisations/${organisation}/config`, undefined, undefined, true);
}

export async function saveOptimisationConfig(organisation: string, config: OptimisationConfig): Promise<void> {
  return callApi(client().put, `/organisations/${organisation}/config`, config) as Promise<void>;
}

export function resetClient() {
  resetApiClient(QOFOPT_API_NAME);
}
