import { fetchAuthSession } from 'aws-amplify/auth';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import userContext from '../../../context/UserContext';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';
import * as Api from '../../../apis/website/Api';
import { ApplyDialog } from '../ApplyDialog/ApplyDialog';

interface Props {
  compact?: boolean;
  onNewApplication: (organisation: string) => Promise<void>;
}

export function ApplicationRequest({ compact, onNewApplication }: Props) {
  const dispatch = useDispatch();
  const { open, connector } = useDialog<{}, string>();

  const context = useContext(userContext);
  const user = context.user;
  const isSuperUser = user?.superUser ?? false;

  async function onApply(organisation: string) {
    if (isSuperUser) {
      // Super users can join the organisation immediately
      try {
        await Api.updateUser(user!.userId, [], organisation);
        await fetchAuthSession({ forceRefresh: true });
        dispatch(enqueueInfoNotification(`Joined ${organisation}`));
      } catch (error) {
        dispatch(enqueueErrorNotification(`Unable to join ${organisation}`, error));
        throw error;
      }
    } else {
      try {
        await onNewApplication(organisation);
        dispatch(enqueueInfoNotification(`Applied to join ${organisation}`));
      } catch (error) {
        dispatch(enqueueErrorNotification(`Unable to apply to ${organisation}`, error));
      }
    }
  }

  const openApplyDialog = () => {
    open({}).then((organisation) => onApply(organisation.trim()));
  };

  return (
    <>
      {compact ? (
        <Tooltip title="Join new organisation...">
          <IconButton color="primary" onClick={openApplyDialog}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button variant="contained" onClick={openApplyDialog} startIcon={<AddCircleIcon />}>
          Join new organisation...
        </Button>
      )}

      <ApplyDialog connector={connector} />
    </>
  );
}
