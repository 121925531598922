import { GET_RULE_ANALYTICS_TIMEOUT, SCHEDULED_MESSAGING_API_NAME } from '../../util/constants';
import {
  Rule,
  RuleAnalytics,
  RuleExecutionReport,
  RuleExecutions,
  RuleOrganisationConfig,
  RuleStats,
  ScheduledMessagingOrganisationConfig,
} from '../../domain/ScheduledMessaging';
import { AxiosInstance } from 'axios';
import { getApiClient, httpDelete, httpGet, httpPost, httpPut, httpPatch, resetApiClient } from '../../util/api';
import {
  parseRulesResponse,
  parseOrganisationConfigResponse,
  parseRuleResponse,
  parseRuleExecutionHistoryResponse,
  parseRuleExecutionReportResponse,
  parseCsvReportResponse,
  parseRuleStatsResponse,
  parseRuleAnalyticsResponse,
} from './model/response';

function client(): AxiosInstance {
  return getApiClient(SCHEDULED_MESSAGING_API_NAME);
}

export async function loadRules(): Promise<Rule[]> {
  const data = await httpGet(client(), '/rules');
  return parseRulesResponse(data);
}

export async function removeRule(organisation: string, ruleId: string): Promise<ScheduledMessagingOrganisationConfig> {
  const data = await httpDelete(client(), `/organisations/${organisation}/rules/${ruleId}`);
  return parseOrganisationConfigResponse(data);
}

export async function updateRule(organisation: string, ruleId: string, data: Rule): Promise<Rule> {
  const { metadata, ...rest } = data;

  const response = await httpPut(client(), `/organisations/${organisation}/rules/${ruleId}`, rest);
  return parseRuleResponse(response);
}

export async function loadScheduledMessagingConfig(
  organisation: string,
): Promise<ScheduledMessagingOrganisationConfig> {
  const data = await httpGet(client(), `/organisations/${organisation}/config`);
  return parseOrganisationConfigResponse(data);
}

export async function updateScheduledMessagingOrganisationConfig(
  organisation: string,
  available?: boolean,
  reportsRecipientUserId?: string | null,
  partnerApiCredentials?: { username: string; password: string },
): Promise<ScheduledMessagingOrganisationConfig> {
  const data = await httpPatch(client(), `/organisations/${organisation}/config`, {
    available,
    reportsRecipientUserId,
    partnerApiCredentials,
  });

  return parseOrganisationConfigResponse(data);
}

export async function updateRuleConfig(
  organisation: string,
  ruleId: string,
  ruleConfig: RuleOrganisationConfig,
): Promise<ScheduledMessagingOrganisationConfig> {
  const data = await httpPut(client(), `/organisations/${organisation}/rules/${ruleId}/config`, ruleConfig);
  return parseOrganisationConfigResponse(data);
}

export async function removeRuleConfig(
  organisation: string,
  ruleId: string,
): Promise<ScheduledMessagingOrganisationConfig> {
  const data = await httpDelete(client(), `/organisations/${organisation}/rules/${ruleId}/config`);
  return parseOrganisationConfigResponse(data);
}

export async function loadRuleExecutionHistory(organisation: string, ruleId: string): Promise<RuleExecutions> {
  const data = await httpGet(client(), `/organisations/${organisation}/rules/${ruleId}/executions`);
  return parseRuleExecutionHistoryResponse(data);
}

export async function loadRuleExecution(
  organisation: string,
  ruleId: string,
  executionDate: string,
): Promise<RuleExecutionReport> {
  const data = await httpGet(client(), `/organisations/${organisation}/rules/${ruleId}/executions/${executionDate}`);
  return parseRuleExecutionReportResponse(data);
}

export async function downloadReport(executionId: string): Promise<string> {
  const [, organisation, ruleId, date] = executionId.match(/(.+)#(.+)#(.+)/)!;

  const data = await httpGet(client(), `/organisations/${organisation}/rules/${ruleId}/executions/${date}/report`);
  return parseCsvReportResponse(data);
}

export async function triggerRule(organisation: string, ruleId: string): Promise<void> {
  await httpPost(client(), `/organisations/${organisation}/rules/${ruleId}/executions`, {});
}

export async function loadRuleStats(organisation: string, ruleId: string): Promise<RuleStats> {
  const data = await httpGet(client(), `/organisations/${organisation}/rules/${ruleId}/stats`);
  return parseRuleStatsResponse(data);
}

export async function loadRuleAnalytics(
  organisation: string,
  ruleId: string,
  includePatients: boolean,
): Promise<RuleAnalytics> {
  const data = await httpGet(client(), `/organisations/${organisation}/rules/${ruleId}/analytics`, {
    config: {
      timeout: GET_RULE_ANALYTICS_TIMEOUT,
      params: {
        includePatients,
      },
    },
  });
  return parseRuleAnalyticsResponse(data);
}

export async function checkPartnerApiCredentials(
  organisation: string,
  username: string,
  password: string,
): Promise<void> {
  await httpPost(client(), `/organisations/${organisation}/partner-api/check`, {
    username,
    password,
  });
}

export function resetClient() {
  resetApiClient(SCHEDULED_MESSAGING_API_NAME);
}
