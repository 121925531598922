import { Box } from '@mui/system';
import React from 'react';
import NavBar from '../../components/navigation/NavBar/NavBar';
import UserApplications from '../../components/applications/UserApplications/UserApplications';

export default function ApplicationsPage() {
  return (
    <>
      <NavBar />
      <Box component="main" overflow="auto" p={2}>
        <UserApplications />
      </Box>
    </>
  );
}
