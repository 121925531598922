import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { UseDialogConnector } from '../../../hooks/useDialog/useDialog';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { extractEmailAddresses } from './helper';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';

const initialValue = { accepted: [], rejected: [] };

export function InviteDialog({ connector }: { connector: UseDialogConnector<unknown, string> }) {
  const [open, setOpen] = useState(connector.config !== null);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState<string>('');
  const [parsedEmailAddresses, setParsedEmailAddresses] = useState<{ accepted: string[]; rejected: string[] }>(
    initialValue,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const result = extractEmailAddresses(value);
    setParsedEmailAddresses(result);
  }, [value]);

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
    }
  }, [connector.config]);

  const close = () => {
    setValue('');
    setParsedEmailAddresses(initialValue);
    setOpen(false);
  };

  const sendInvite = async () => {
    setSubmitting(true);

    try {
      await Promise.all(parsedEmailAddresses.accepted.map((emailAddress) => connector.submit(emailAddress)));
    } catch {
      // Ignore
    }

    if (parsedEmailAddresses.rejected.length) {
      dispatch(
        enqueueInfoNotification(
          `${
            parsedEmailAddresses.rejected.length === 1
              ? `Not able to add user ${parsedEmailAddresses.rejected} - not a valid NHS email address.`
              : `Not able to add users ${parsedEmailAddresses.rejected.join(',')} - not valid NHS email addresses.`
          } For assistance, contact support@abtrace.co.`,
        ),
      );
    }

    close();
    setSubmitting(false);
  };

  if (!connector.config) {
    return <></>;
  }

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Invite new users</DialogTitle>
        <Box sx={{ width: '290px' }} px={3}>
          <Typography variant="caption">
            Please enter one or more NHS email addresses. Lists of emails must be separated by semi-colons and can be
            copied from NHS email distribution lists.
          </Typography>
        </Box>
        <DialogContent>
          <FormControl sx={{ marginTop: '8px', width: '290px' }}>
            <TextField
              multiline
              value={value}
              onChange={(e) => setValue(e.target.value)}
              label="E-mails"
              placeholder="E-mails"
              autoFocus={open}
            />
          </FormControl>
          {!!parsedEmailAddresses.accepted.length &&
            parsedEmailAddresses.accepted.map((email) => (
              <Box pt={1} key={email}>
                <Chip label={email} variant="outlined" />
              </Box>
            ))}
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} m={2} mt={0}>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>
            <Box sx={{ position: 'relative' }}>
              <Button
                disabled={!parsedEmailAddresses.accepted.length}
                onClick={sendInvite}
                variant="contained"
                loading={submitting}
                data-testid="invite"
              >
                Invite
              </Button>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
