import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useState } from 'react';
import { UseDialogConnector } from '../../../../hooks/useDialog/useDialog';
import RulesContext from '../../../../context/RulesContext/RulesContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { RuleDefinition } from '../RuleDefinition/RuleDefinition';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Rule } from '../../../../domain/ScheduledMessaging';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export function AddRuleDialog({ connector }: { connector: UseDialogConnector<unknown, string> }) {
  const [open, setOpen] = useState(connector.config !== null);
  const [selected, setSelected] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const { rules, configuredRules } = useContext(RulesContext);

  const availableRules: Rule[] = Object.entries(rules ?? {})
    ?.filter(([ruleId]) => !configuredRules?.[ruleId])
    .sort(([ruleIdA], [ruleIdB]) => ruleIdA.localeCompare(ruleIdB))
    .map(([, rule]) => rule);

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
    }
  }, [connector.config]);

  function close() {
    setOpen(false);
  }

  async function addRule() {
    if (!selected) {
      return;
    }

    setSubmitting(true);
    try {
      await connector.submit(selected);
      close();
    } finally {
      setSubmitting(false);
    }
  }

  if (!connector.config) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="md"
      slotProps={{
        transition: {
          onExited: () => {
            // Called only when the dialog disappears completely
            setSelected(null);
            connector.close();
          },
        },
      }}
    >
      {selected && rules?.[selected] ? (
        <DialogTitle sx={{ paddingLeft: 0 }}>
          <Stack direction="row" alignItems="start">
            <Box mx={1}>
              <Button onClick={() => setSelected(null)} startIcon={<ArrowBackIcon />} size="small">
                Back
              </Button>
            </Box>
            {rules[selected].name ?? selected}
          </Stack>
        </DialogTitle>
      ) : (
        <DialogTitle>Please select a rule below</DialogTitle>
      )}
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {selected && rules?.[selected] ? (
          <Box width="550px">
            <Typography variant="body1" color="text.secondary" gutterBottom>
              {rules[selected].description}
            </Typography>

            <RuleDefinition rule={rules[selected]} />
          </Box>
        ) : (
          <Box width="350px" maxHeight="350px" overflow="scroll">
            {availableRules.length === 0 ? (
              <Stack direction="row" gap={1}>
                <ErrorOutlineIcon color="error" />
                <Typography color="error.main">No rules available</Typography>
              </Stack>
            ) : (
              <List>
                {availableRules.map((rule) => {
                  return (
                    <ListItem key={rule.id} disablePadding>
                      <ListItemButton selected={selected === rule.id} onClick={() => setSelected(rule.id)}>
                        <ListItemText
                          primary={<Typography>{rule.name}</Typography>}
                          secondary={rule.description}
                          slotProps={{
                            secondary: { textOverflow: 'ellipsis', overflow: 'hidden', noWrap: true },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} m={2} mt={0}>
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button disabled={!selected} onClick={addRule} variant="contained" loading={submitting}>
            Add
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
