import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

type Props = BoxProps & {
  loading: boolean;
};

export function SectionSpinner(props: Omit<Props, 'loading'>) {
  return (
    <Box display="flex" alignItems="center" gap={1} {...props}>
      <CircularProgress data-testid="spinner" size="0.8em" />
      <Typography>Loading...</Typography>
    </Box>
  );
}

export function LoadingSection(props: Props) {
  const { children, loading, ...rest } = props;

  if (loading) return <SectionSpinner ml={2} mt={2} {...rest} />;

  return children;
}
