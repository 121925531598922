import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MultipleChoiceSelect, Option } from '../../../shared/MultipleChoiceSelect/MultipleChoiceSelect';
import { useFrontendDefinitions } from '../../../../hooks/useFrontendDefinitions/useFrontendDefinitions';

function buildOptions(messagingCodes: Record<string, string> | undefined): Option<string>[] {
  return Object.entries(messagingCodes ?? {}).map(([code, name]) => ({
    value: code,
    label: name,
  }));
}

export function MessagingCodeSelect() {
  const { frontendDefinitions, loading, error } = useFrontendDefinitions();
  const messagingCodes = frontendDefinitions?.data.messaging_codes;

  const { control } = useFormContext();

  const options: Option<string>[] = useMemo(() => buildOptions(messagingCodes), [messagingCodes]);

  return (
    <Box display="flex">
      <Controller
        name="extraCodes"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <FormControl sx={{ flex: 'auto' }}>
                <MultipleChoiceSelect
                  label="Add codes to the patient record"
                  values={value}
                  options={options}
                  loading={loading}
                  deletedOptionText="This code no longer seems to exist"
                  onChange={onChange}
                  slotProps={{
                    paper: {
                      sx: {
                        border: '1px solid',
                        boxShadow: 1,
                      },
                    },
                  }}
                  getInputParams={(params) => ({
                    ...params,
                    ...(error
                      ? {
                          helperText: 'Unable to load messaging codes',
                          FormHelperTextProps: { sx: { color: 'warning.main' } },
                        }
                      : {}),
                  })}
                />
              </FormControl>
            </>
          );
        }}
        defaultValue={[]}
      />
    </Box>
  );
}
