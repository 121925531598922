import { AxiosInstance } from 'axios';
import {
  OrganisationContactDetails,
  PatientTasksConfiguration,
  PatientTasksDefaultDescriptionPerStatus,
} from '../../domain/PatientFacing';
import { getApiClient, httpGet, httpPut, resetApiClient } from '../../util/api';
import { PATIENT_FACING_API_NAME } from '../../util/constants';
import {
  parseOrganisationContactDetails,
  parseParsePatientTasksConfigurationResponse,
  parsePatientTasksConfigurationSchema,
} from './model/response';

function client(): AxiosInstance {
  return getApiClient(PATIENT_FACING_API_NAME);
}

export async function loadContactUsPageConfig(organisation: string): Promise<OrganisationContactDetails | null> {
  const data = await httpGet(client(), `/organisations/${organisation}/contact-us-page`, { nullOnError: true });
  return data === null ? null : parseOrganisationContactDetails(data);
}

export async function saveContactUsPageConfig(
  organisation: string,
  config: OrganisationContactDetails,
): Promise<OrganisationContactDetails> {
  const data = await httpPut(client(), `/organisations/${organisation}/contact-us-page`, config);
  return parseOrganisationContactDetails(data);
}

export async function loadPatientTasksConfig(organisation: string): Promise<PatientTasksConfiguration | null> {
  const data = await httpGet(client(), `/organisations/${organisation}/patient-tasks`, { nullOnError: true });
  return data === null ? null : parsePatientTasksConfigurationSchema(data);
}

export async function savePatientTasksConfig(
  organisation: string,
  config: PatientTasksConfiguration,
): Promise<PatientTasksConfiguration> {
  const data = await httpPut(client(), `/organisations/${organisation}/patient-tasks`, config);
  return parsePatientTasksConfigurationSchema(data);
}

export async function loadPatientTasksDefaultDescriptions(): Promise<PatientTasksDefaultDescriptionPerStatus> {
  const data = await httpGet(client(), '/patient-tasks/default-descriptions');
  return parseParsePatientTasksConfigurationResponse(data);
}

export function resetClient() {
  resetApiClient(PATIENT_FACING_API_NAME);
}
