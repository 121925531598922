import { AxiosInstance } from 'axios';
import { MessagingConfig } from '../../domain/Messaging';
import { MESSAGING_API_NAME } from '../../util/constants';
import { callApi, getApiClient, resetApiClient } from '../../util/api';
import {
  GetBulkMessageReportResponse,
  GetBulkMessageReportsResponse,
  parseGetBulkMessageReportResponse,
  parseGetBulkMessageReportsResponse,
} from './model/response';

function client(): AxiosInstance {
  return getApiClient(MESSAGING_API_NAME);
}

export async function loadMessagingConfig(organisation: string): Promise<MessagingConfig | null> {
  return callApi(client().get, `/organisations/${organisation}/messages/config`, undefined, undefined, true);
}

export async function updateMessagingConfig(
  organisation: string,
  payload: { singleMessagingEnabled?: boolean; bulkMessagingEnabled?: boolean; limits?: { sms: number } },
): Promise<void> {
  return callApi(client().patch, `/organisations/${organisation}/messages/config`, payload) as Promise<void>;
}

export async function loadBulkMessageReports(
  organisation: string,
  senderFilter: string | undefined,
  limit: number | undefined,
  cursor: string | undefined,
): Promise<GetBulkMessageReportsResponse> {
  const response = await callApi(client().get, `/organisations/${organisation}/reports`, undefined, {
    params: { senderFilter, limit, cursor },
  });

  return parseGetBulkMessageReportsResponse(response);
}

export async function loadBulkMessageReport(
  organisation: string,
  reportId: string,
): Promise<GetBulkMessageReportResponse> {
  const response = await callApi(client().get, `/organisations/${organisation}/reports/${reportId}`);

  return parseGetBulkMessageReportResponse(response);
}

export function resetClient() {
  resetApiClient(MESSAGING_API_NAME);
}
