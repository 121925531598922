import { useQuery } from '@tanstack/react-query';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { RuleAnalytics } from '../../domain/ScheduledMessaging';
import { loadRuleAnalytics } from '../../apis/scheduledMessaging/ScheduledMessagingApi';
import {
  SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_KEY,
  SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_STALE_TIME,
} from '../../util/queries';
import { useContext } from 'react';
import UserContext from '../../context/UserContext';
import { isAdminAt } from '../../domain/User';

interface UseRuleAnalyticsResult {
  analytics?: RuleAnalytics;
  downloadAllowed: boolean;
  loading: boolean;
  error: unknown;
}

export function useRuleAnalytics(ruleId: string | undefined): UseRuleAnalyticsResult {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const context = useContext(UserContext);
  const user = context.user;

  const downloadAllowed = !!user && isAdminAt(user, organisation);

  const { data, isPending, isLoading, error } = useQuery<RuleAnalytics>({
    queryKey: [SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_KEY, organisation, ruleId],
    queryFn: async () => loadRuleAnalytics(organisation, ruleId!, downloadAllowed),
    staleTime: SCHEDULED_MESSAGING_RULE_ANALYTICS_QUERY_STALE_TIME,
    enabled: !!ruleId && !!organisation,
    retry: false,
  });

  return {
    analytics: data,
    downloadAllowed,
    loading: isPending || isLoading,
    error,
  };
}
