import React, { MouseEvent, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import dayjs from 'dayjs';
import { useBulkMessageReport } from '../../../../hooks/useBulkMessageReport/useBulkMessageReport';
import { BulkMessageDetailedReport } from '../../../../domain/Messaging';
import LinearProgress from '@mui/material/LinearProgress';

interface Props {
  reportId: string;
}

export const CSV_TIMESTAMP_FORMAT = 'YYYY-MM-DD_HH-mm-ss';

async function exportToCsv(timestamp: Date, details: BulkMessageDetailedReport) {
  const filename = `BulkMessageReport-${dayjs(timestamp).format(CSV_TIMESTAMP_FORMAT)}`;

  const csvConfig = mkConfig({ useKeysAsHeaders: true, filename });

  const csv = generateCsv(csvConfig)(
    details.map(({ nhsNumber, emailStatus, smsStatus, notes }) => ({
      'NHS Number': nhsNumber,
      'E-Mail status': emailStatus ?? '',
      'SMS status': smsStatus ?? '',
      Notes: notes ?? '',
    })),
  );

  download(csvConfig)(csv);
}

function getSmsSummary(
  smsSent: number,
  smsDelivered: number,
  smsDeliveryFailed: number,
): [string, 'success' | 'warning' | 'error'] {
  const sentPart = `${smsSent} SMS message${smsSent === 1 ? '' : 's'} sent`;
  if (smsDelivered === 0 && smsDeliveryFailed === 0) {
    return [sentPart, 'success'];
  } else if (smsDelivered === 0) {
    if (smsDeliveryFailed === smsSent) {
      return [`${sentPart}, ${smsDeliveryFailed === 1 ? '' : 'all of '}which failed to deliver`, 'error'];
    } else {
      return [
        `${sentPart}, ${smsDeliveryFailed} of which failed to deliver (${smsSent - smsDeliveryFailed} pending)`,
        'warning',
      ];
    }
  } else if (smsDeliveryFailed === 0) {
    if (smsDelivered === smsSent) {
      return [`${sentPart}, ${smsDelivered === 1 ? 'which was' : 'all of which were'} delivered`, 'success'];
    } else {
      return [
        `${sentPart}, ${smsDelivered} of which ${smsDelivered === 1 ? 'was' : 'were'} delivered (${smsSent - smsDelivered} pending)`,
        'success',
      ];
    }
  } else if (smsSent === smsDelivered + smsDeliveryFailed) {
    return [
      `${sentPart}, ${smsDelivered} of which ${smsDelivered === 1 ? 'was' : 'were'} delivered and ${smsDeliveryFailed} failed to deliver`,
      'warning',
    ];
  }

  return [
    `${sentPart}, ${smsDelivered} of which ${smsDelivered === 1 ? 'was' : 'were'} delivered and ${smsDeliveryFailed} failed to deliver (${smsSent - smsDelivered - smsDeliveryFailed} pending)`,
    'warning',
  ];
}

function getEmailSummary(emailsSent: number, emailsDeliveryFailed: number): [string, 'success' | 'warning' | 'error'] {
  const sentPart = `${emailsSent} e-mail${emailsSent === 1 ? '' : 's'} sent`;
  if (emailsDeliveryFailed === 0) {
    return [sentPart, 'success'];
  } else {
    if (emailsDeliveryFailed === emailsSent) {
      return [`${sentPart}, ${emailsDeliveryFailed === 1 ? '' : 'all of '}which failed to deliver`, 'error'];
    } else {
      return [`${sentPart}, ${emailsDeliveryFailed} of which failed to deliver`, 'warning'];
    }
  }
}

export function ReportSummary({ reportId }: Props) {
  const { timestamp, summary, downloadAllowed, details, loading, error } = useBulkMessageReport(reportId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load the report', error));
    }
  }, [error]);

  if (loading) {
    return <LinearProgress color="info" />;
  }

  if (!timestamp || !summary) {
    return <></>;
  }
  const {
    patientsSelected,
    emailsSent,
    emailsDeliveryFailed,
    smsSent,
    smsDelivered,
    smsDeliveryFailed,
    notContacted,
    patientRecordUpdateErrors,
    hadPatientsOpenInTpp,
  } = summary;

  return (
    <Box m={1}>
      <List subheader={<ListSubheader component="div">Summary</ListSubheader>}>
        <ListItem>
          <ListItemIcon>
            <HowToRegIcon />
          </ListItemIcon>
          <ListItemText
            primary={`${patientsSelected} patient${patientsSelected === 1 ? '' : 's'} selected for messaging`}
            slotProps={{
              primary: {
                variant: 'body2',
              },
            }}
          />
        </ListItem>

        {emailsSent !== 'NOT_APPLICABLE' &&
          emailsSent > 0 &&
          (() => {
            const [text, colour] = getEmailSummary(emailsSent, emailsDeliveryFailed as number);
            return (
              <ListItem>
                <ListItemIcon>
                  <EmailIcon color={colour} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  slotProps={{
                    primary: {
                      variant: 'body2',
                    },
                  }}
                />
              </ListItem>
            );
          })()}

        {emailsSent === 0 && (
          <ListItem>
            <ListItemIcon>
              <EmailIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={'No e-mails were sent'}
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}

        {smsSent !== 'NOT_APPLICABLE' &&
          smsSent > 0 &&
          (() => {
            const [text, colour] = getSmsSummary(smsSent, smsDelivered as number, smsDeliveryFailed as number);
            return (
              <ListItem>
                <ListItemIcon>
                  <SmsIcon color={colour} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  slotProps={{
                    primary: {
                      variant: 'body2',
                    },
                  }}
                />
              </ListItem>
            );
          })()}

        {smsSent === 0 && (
          <ListItem>
            <ListItemIcon>
              <SmsIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={'No SMS messages were sent'}
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}

        {notContacted > 0 && (
          <ListItem>
            <ListItemIcon>
              <WarningIcon color="warning" />
            </ListItemIcon>
            <ListItemText
              primary={`${notContacted} patient${notContacted === 1 ? '' : 's'} not contacted`}
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}

        {!!patientRecordUpdateErrors && patientRecordUpdateErrors > 0 && (
          <ListItem>
            <ListItemIcon>
              <ErrorIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={`${patientRecordUpdateErrors} patient record${
                patientRecordUpdateErrors === 1 ? '' : 's'
              } failed to update`}
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}

        {hadPatientsOpenInTpp && (
          <ListItem>
            <ListItemIcon>
              <WarningIcon color="warning" />
            </ListItemIcon>
            <ListItemText
              primary="Some patients were open in SystmOne and had to be saved manually for the messages to be recorded"
              slotProps={{
                primary: {
                  variant: 'body2',
                },
              }}
            />
          </ListItem>
        )}
      </List>

      {downloadAllowed && !!details && (
        <Button
          href="#"
          onClick={async (event: MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            exportToCsv(timestamp, details);
          }}
          size="small"
          sx={{
            marginLeft: 1,
          }}
        >
          Download full report
        </Button>
      )}
    </Box>
  );
}
