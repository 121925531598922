import { RefetchOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { APPOINTMENT_SLOTS_CONFIG_QUERY_KEY, APPOINTMENT_SLOTS_CONFIG_QUERY_STALE_TIME } from '../../util/queries';
import { getAppointmentSlotsConfig } from '../../apis/appointments/AppointmentsApi';
import { AppointmentSlotsConfig } from '../../domain/Appointments';
import dayjs from 'dayjs';

interface UseAppointmentSlotsConfigReturn {
  loading: boolean;
  config?: AppointmentSlotsConfig;
  error: unknown;
  refetch: (options?: RefetchOptions | undefined) => void;
}

export function useAppointmentSlotsConfig(active: boolean): UseAppointmentSlotsConfigReturn {
  const { organisation: organisationDetails } = useCurrentOrganisationDetails();
  const { organisation, ehr } = organisationDetails ?? { organisation: undefined, ehr: undefined };

  const dateFrom = dayjs().startOf('day').toDate();
  const dateTo = dayjs().add(7, 'day').startOf('day').toDate();

  const { isFetching, data, error, refetch } = useQuery({
    queryKey: [APPOINTMENT_SLOTS_CONFIG_QUERY_KEY, organisation],
    queryFn: async () =>
      // The dates don't seem to matter, so just pass in this week
      getAppointmentSlotsConfig(organisation, ehr!, dateFrom, dateTo),
    enabled: !!ehr && active,
    placeholderData: keepPreviousData,
    retry: false,
    staleTime: APPOINTMENT_SLOTS_CONFIG_QUERY_STALE_TIME,
  });

  return {
    loading: isFetching,
    config: data,
    error,
    refetch,
  };
}
