import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import OrganisationUsersContext from '../../../context/OrganisationUsersContext/OrganisationUsersContext';
import UserContext from '../../../context/UserContext';
import { OrganisationMember, Permission } from '../../../domain/Organisation';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';
import * as Api from '../../../apis/website/Api';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import OrganisationUsersTable from '../OrganisationUsersTable/OrganisationUsersTable';
import { fetchAuthSession } from 'aws-amplify/auth';

interface Props {
  organisation: string;
}

export default function OrganisationUsers({ organisation }: Props) {
  const [updating, setUpdating] = useState(false);

  const dispatch = useDispatch();
  const { users, setUsers, loading: usersLoading } = useContext(OrganisationUsersContext);
  const { user: currentUser } = useContext(UserContext);

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  const updateUserPermissions =
    (user: OrganisationMember, permission: Permission) => async (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newPermissions = new Set(user.permissions);
      if (ev.target.checked) {
        newPermissions.add(permission);
      } else {
        newPermissions.delete(permission);
      }

      setUpdating(true);

      try {
        const updatedUser = await Api.updateUser(user.id, [...newPermissions], organisation);
        setUsers(users.map((u) => (u.id === user.id ? updatedUser : u)));
        dispatch(enqueueInfoNotification(`Updated ${user.email}`));

        if (currentUser?.email === user.email) {
          await fetchAuthSession({ forceRefresh: true });
        }
      } catch (error) {
        dispatch(enqueueErrorNotification(`Unable to update permissions for ${user.email}`, error));
      } finally {
        setUpdating(false);
      }
    };

  const deleteUser = async (user: OrganisationMember) => {
    try {
      await Api.deleteUser(user.id, organisation);

      setUsers(users.filter((u) => user.id !== u.id));
      dispatch(enqueueInfoNotification(`Deleted ${user.email}`));

      if (currentUser?.email === user.email) {
        await fetchAuthSession({ forceRefresh: true });
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(`Unable to delete ${user.email}`, error));
    }
  };

  const askBeforeDeletingUser = (user: OrganisationMember) => {
    open({
      title: 'Delete user',
      text: `Are you sure you want to delete ${user.email}?`,
    }).then(() => deleteUser(user));
  };

  return (
    <LoadingSection loading={usersLoading}>
      {updating && (
        <Backdrop open={updating} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress data-testid="spinner" />
        </Backdrop>
      )}
      <Box mt={2}>
        <OrganisationUsersTable
          users={users}
          updateUserPermissions={updateUserPermissions}
          deleteUser={askBeforeDeletingUser}
          organisation={organisation}
        />
        <ConfirmationDialog connector={connector} />
      </Box>
    </LoadingSection>
  );
}
