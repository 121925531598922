import { FetchQueryOptions, RefetchOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { APPOINTMENTS_QUERY_KEY, APPOINTMENTS_QUERY_STALE_TIME } from '../../util/queries';
import {
  getAppointmentsConfig,
  updateAppointmentConfig,
  verifyOrganisationEmail,
} from '../../apis/appointments/AppointmentsApi';
import { AppointmentsConfig } from '../../domain/Appointments';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface UseAppointmentBookingConfigOptions {
  enabled?: boolean;
}

interface UseAppointmentBookingConfigResult {
  config: AppointmentsConfig | undefined;
  loading: boolean;
  error: unknown;
  refetch: (options?: RefetchOptions | undefined) => void;
  fetch: () => Promise<AppointmentsConfig>;
  updateConfig: (enabled: boolean) => Promise<void>;
  updateEmail: (organisationEmail: string | null) => Promise<void>;
  verifyEmail: (verificationCode: string) => Promise<void>;
}

export function useAppointmentBookingConfig({
  enabled,
}: Partial<UseAppointmentBookingConfigOptions> = {}): UseAppointmentBookingConfigResult {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const queryClient = useQueryClient();
  const queryOptions: FetchQueryOptions<AppointmentsConfig> = {
    queryKey: [APPOINTMENTS_QUERY_KEY, organisation],
    queryFn: () => getAppointmentsConfig(organisation),
    staleTime: APPOINTMENTS_QUERY_STALE_TIME,
    retry: false,
  };

  const { data, isLoading, error, refetch } = useQuery({
    ...queryOptions,
    enabled,
  });

  function fetch(): Promise<AppointmentsConfig> {
    return queryClient.fetchQuery({ ...queryOptions, staleTime: 0 });
  }

  const updateAppointmentConfigMutation = useMutation({
    mutationFn: ({ enabled }: { enabled: boolean }) =>
      updateAppointmentConfig(organisation, enabled, data?.organisationEmail?.address ?? null),
    onSuccess: (_result, variables) => {
      queryClient.setQueryData<AppointmentsConfig>(
        [APPOINTMENTS_QUERY_KEY, organisation],
        (previousConfig: AppointmentsConfig | undefined) => ({
          ...previousConfig!,
          enabled: variables.enabled,
        }),
      );
    },
  });

  const updateEmailMutation = useMutation({
    mutationFn: (organisationEmail: string | null) =>
      updateAppointmentConfig(organisation, data!.enabled, organisationEmail),
    onSuccess: (_result, organisationEmail) => {
      queryClient.setQueryData(
        [APPOINTMENTS_QUERY_KEY, organisation],
        (previousConfig: AppointmentsConfig | undefined) => ({
          ...previousConfig!,
          organisationEmail: {
            address: organisationEmail,
            verified: false,
          },
        }),
      );
    },
  });

  const verifyEmailMutation = useMutation({
    mutationFn: (verificationCode: string) => verifyOrganisationEmail(organisation, verificationCode),
    onSuccess: () => {
      queryClient.setQueryData(
        [APPOINTMENTS_QUERY_KEY, organisation],
        (previousConfig: AppointmentsConfig | undefined) => ({
          ...previousConfig!,
          enabled: true,
          organisationEmail: {
            address: previousConfig!.organisationEmail!.address!,
            verified: true,
          },
        }),
      );
    },
  });

  async function updateConfig(enabled: boolean): Promise<void> {
    await updateAppointmentConfigMutation.mutateAsync({ enabled });
  }

  async function updateEmail(organisationEmail: string | null): Promise<void> {
    await updateEmailMutation.mutateAsync(organisationEmail);
  }

  async function verifyEmail(verificationCode: string): Promise<void> {
    await verifyEmailMutation.mutateAsync(verificationCode);
  }

  return {
    config: data,
    loading: isLoading,
    error,
    refetch,
    fetch,
    updateConfig,
    updateEmail,
    verifyEmail,
  };
}
