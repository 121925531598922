import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/system/Stack';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useEffect, useState } from 'react';
import { UseDialogConnector } from '../../../hooks/useDialog/useDialog';
import Typography from '@mui/material/Typography';
import { LoadingActionButton } from '../../shared/LoadingActionButton';

export interface RemoveRecipientDialogConfig {
  recipientType: 'e-mail address' | 'user';
  recipientValue: string;
  recipientUsageMetadata: RecipientUsageMetadata;
}

export type RecipientUsageMetadata = {
  appointments: boolean;
  scheduledMessaging: {
    fallback: boolean;
    rules: {
      id: string;
      name: string;
      description: string;
      enabled: boolean;
    }[];
  };
};

type RecipientUsageItem = {
  id: string;
  primaryText: string;
  tooltip: string;
  secondaryText?: string;
  statusChip?: 'ACTIVE' | 'OFF' | 'DEFAULT';
};

export function RemoveRecipientDialog({ connector }: { connector: UseDialogConnector<RemoveRecipientDialogConfig> }) {
  const [open, setOpen] = useState(connector.config !== null);

  useEffect(() => {
    if (connector.config) {
      setOpen(true);
    }
  }, [connector.config]);

  const close = () => {
    setOpen(false);
  };

  if (!connector.config) {
    return <></>;
  }

  const { recipientType, recipientValue, recipientUsageMetadata } = connector.config;

  // Transform usage metadata into a displayable list
  const warningItems: RecipientUsageItem[] = [];

  recipientUsageMetadata.scheduledMessaging.rules.forEach((rule) => {
    warningItems.push({
      id: rule.id,
      primaryText: rule.name,
      tooltip: rule.description,
      secondaryText: 'Automated recall report',
      statusChip: rule.enabled ? 'ACTIVE' : 'OFF',
    });
  });

  if (recipientUsageMetadata.scheduledMessaging.fallback) {
    warningItems.push({
      id: 'automated-reports',
      primaryText: 'Automated recall reports',
      tooltip:
        'The default address for automated recall reports is used for any automated recall that does not have its own report recipients defined. Set in the "Automated Recalls" section.',
      statusChip: 'DEFAULT',
    });
  }

  if (recipientUsageMetadata.appointments) {
    warningItems.push({
      id: 'appointment-request',
      primaryText: 'Appointment request form',
      tooltip: 'An optional address for free text appointment requests from patients. Set in the "Messaging" section.',
    });
  }

  const isConfigured = warningItems.length > 0;

  return (
    <Dialog
      open={open}
      onClose={close}
      slotProps={{
        transition: {
          onExited: () => {
            // Called only when the dialog disappears completely
            connector.close();
          },
        },
      }}
    >
      <DialogTitle>
        Are you sure you want to remove the {recipientType} {recipientValue}?
      </DialogTitle>
      <DialogContent>
        {isConfigured ? (
          <Stack spacing={2}>
            <Typography>
              The {recipientType}{' '}
              <Typography display="inline" component="strong" fontWeight="medium">
                {recipientValue}
              </Typography>{' '}
              is being used as a recipient for the following items:
            </Typography>
            <Paper variant="outlined" sx={{ px: 2, border: '1px dashed #9747FF' }}>
              <List>
                {warningItems.map((item) => (
                  <ListItem key={item.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ListItemText primary={item.primaryText} secondary={item.secondaryText} />
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {item.statusChip === 'ACTIVE' && <Chip label="ACTIVE" size="small" color="success" />}
                      {item.statusChip === 'OFF' && <Chip label="OFF" size="small" color="default" />}
                      {item.statusChip === 'DEFAULT' && (
                        <Chip label="DEFAULT" size="small" color="primary" variant="outlined" />
                      )}
                      <Tooltip title={item.tooltip}>
                        <InfoOutlinedIcon fontSize="small" color="action" />
                      </Tooltip>
                    </Stack>
                  </ListItem>
                ))}
              </List>
            </Paper>
            <Alert severity="warning" sx={{ backgroundColor: '#FFF6CF' }}>
              <AlertTitle>
                On confirmation the {recipientType} will be removed as a recipient for the items listed.
              </AlertTitle>
              <Stack spacing={2}>
                {recipientUsageMetadata.scheduledMessaging.fallback ? (
                  <Typography>
                    Removing the default report recipient address for automated recalls will mean the report will not be
                    sent until a new address is set. Reports will be available for download from the “Automated recalls”
                    section of this website.
                  </Typography>
                ) : (
                  recipientUsageMetadata.scheduledMessaging.rules.length > 0 && (
                    <Typography>
                      Automated recalls will instead use the default report recipient address if it is set.
                    </Typography>
                  )
                )}

                {recipientUsageMetadata.appointments && (
                  <Typography>
                    Removing the appointment request form recipient address will mean appointment request forms will not
                    be available to your patients. This address can be set in the “Messaging” section of this website.
                  </Typography>
                )}
              </Stack>
            </Alert>
          </Stack>
        ) : (
          <Typography sx={{ textAlign: 'center', py: 2 }}>
            {/* Empty state - only title and buttons remain */}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} m={2} mt={0}>
          <LoadingActionButton variant="outlined" onClick={close}>
            Cancel
          </LoadingActionButton>
          <LoadingActionButton
            variant="contained"
            data-testid="confirm"
            disabled={!open}
            onClick={async () => {
              await connector
                .submit()
                .then(close)
                .catch(() => {});
            }}
          >
            Remove
          </LoadingActionButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
