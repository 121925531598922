import React, { ReactNode, useEffect, useRef, useState } from 'react';
import * as rhf from 'react-hook-form';
import Button, { ButtonProps } from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

type Params<V extends rhf.FieldValues, R> = Omit<rhf.UseFormProps<V>, 'defaultValues'> & {
  onSubmit?: (values: V) => Promise<R>;
  onSuccess?: (state: State<V, R>) => void;
  defaultValues: rhf.DefaultValues<V>;
  onDefaultValuesChange?: (state: State<V, R>) => void;
};

type State<V extends rhf.FieldValues, R> = ReturnType<typeof useEditState<V, R>>;

function useEditState<V extends rhf.FieldValues, R>(params: Params<V, R>) {
  const { onSubmit, onSuccess, onDefaultValuesChange, defaultValues, ...rest } = params;
  const form = rhf.useForm<V>({ defaultValues, ...rest });
  const [isEditMode, setEditMode] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submissionResult, setSubmissionResult] = useState<R>();
  const [submissionError, setSubmissionError] = useState<unknown>();

  function onSubmitWrapper(values: V) {
    setSubmitting(true);

    return onSubmit?.(values)
      .then((res) => {
        setSubmissionResult(res);

        onSuccess?.({ ...stateRef.current, submissionResult: res });
      })
      .catch(setSubmissionError)
      .finally(() => setSubmitting(false));
  }

  function submit() {
    return form.handleSubmit(onSubmitWrapper)();
  }

  function reset() {
    form.reset(defaultValues);
    setSubmissionResult(undefined);
    setSubmissionError(undefined);
  }

  function startViewMode() {
    reset();
    setEditMode(false);
  }

  function startEditMode() {
    reset();
    setEditMode(true);
  }

  function discard() {
    startViewMode();
  }

  const state = {
    form,
    isEditMode,
    setEditMode,
    isViewMode: !isEditMode,
    isSubmitting,
    submissionResult,
    setSubmissionResult,
    submissionError,
    setSubmissionError,
    submit,
    reset,
    startViewMode,
    startEditMode,
    discard,
  };

  const stateRef = useRef(state);

  // eslint-disable-next-line react-compiler/react-compiler
  stateRef.current = state;

  useEffect(() => onDefaultValuesChange?.(state), [defaultValues]);

  return state;
}

type Props<V extends rhf.FieldValues, R> = Params<V, R> & {
  children: (state: State<V, R>) => ReactNode;
};

export function EditHelper<V extends rhf.FieldValues, R>(props: Props<V, R>) {
  const { children, ...params } = props;
  const state = useEditState(params);

  return children(state);
}

export function useTextFieldController<V extends rhf.FieldValues, N extends rhf.FieldPath<V>, R>(
  props: rhf.UseControllerProps<V, N> & State<V, R>,
) {
  const { form } = props;
  const { field, fieldState } = rhf.useController({ ...props, control: form.control });
  const error = Boolean(fieldState.error);
  const helperText = fieldState.error?.message as ReactNode;

  return { ...field, error, helperText };
}

function EditButton<V extends rhf.FieldValues, R>(props: Omit<ButtonProps, 'form'> & State<V, R>) {
  const { startEditMode, size = 'medium', children = 'Edit', sx } = props;

  return (
    <Button onClick={startEditMode} startIcon={<EditIcon />} size={size} sx={sx}>
      {children}
    </Button>
  );
}

function SubmitButton<V extends rhf.FieldValues, R>(props: Omit<ButtonProps, 'form'> & State<V, R>) {
  const { form, isSubmitting, submit, children = 'Save' } = props;
  const { formState } = form;
  const { isDirty, submitCount, isValid } = formState;
  let { disabled } = formState;

  if (!isDirty || isSubmitting) disabled = true;
  if (submitCount > 0 && !isValid) disabled = true;

  return (
    <Button onClick={() => submit()} loading={isSubmitting} disabled={disabled} variant="contained">
      {children}
    </Button>
  );
}

function DiscardButton<V extends rhf.FieldValues, R>(props: Omit<ButtonProps, 'form'> & State<V, R>) {
  const { discard } = props;

  return (
    <Button onClick={discard} variant="outlined">
      Cancel
    </Button>
  );
}

EditHelper.EditButton = EditButton;
EditHelper.SubmitButton = SubmitButton;
EditHelper.DiscardButton = DiscardButton;

export type { Props as EditHelperProps, State as EditHelperState };
