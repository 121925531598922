import { AxiosInstance } from 'axios';
import { Application } from '../../domain/Application';
import { DownloadUrls } from '../../domain/DownloadUrls';
import { Invitation } from '../../domain/Invitation';
import { OrganisationMember, OrganisationMemberWithConfig } from '../../domain/Organisation';
import { OrgConfig } from '../../pages/overview/helpers';
import { getApiClient, httpDelete, httpGet, httpPatch, httpPost, resetApiClient } from '../../util/api';
import { WEBSITE_API_NAME } from '../../util/constants';
import {
  parseAcceptInvitationResponse,
  parseAllOrganisationsResponse,
  parseApplicationResponse,
  parseDownloadUrlsResponse,
  parseInvitationResponse,
  parseInvitationsResponse,
  parseOrganisationApplicationsResponse,
  parseOrganisationMemberResponse,
  parseOrganisationMembersResponse,
  parseOrganisationMemberWithConfigResponse,
  parseOrgConfigsResponse,
  parseUserApplicationsResponse,
  parseUserlessOrganisationsResponse,
} from './model/response';

function client(): AxiosInstance {
  return getApiClient(WEBSITE_API_NAME);
}

export async function loadUserlessOrganisations(): Promise<{ organisations: string[] }> {
  const data = await httpGet(client(), '/organisations?orgsWithoutUsers=true');
  return parseUserlessOrganisationsResponse(data);
}

export async function loadAllOrganisations(): Promise<{ organisations: string[] }> {
  const data = await httpGet(client(), '/organisations');
  return parseAllOrganisationsResponse(data);
}

export async function loadUsers(organisation: string): Promise<OrganisationMember[]> {
  const data = await httpGet(client(), `/organisations/${organisation}/users`);
  return parseOrganisationMembersResponse(data);
}

export async function updateUser(
  userId: string,
  newPermissions: string[],
  organisation: string,
): Promise<OrganisationMember> {
  const data = await httpPost(client(), `/organisations/${organisation}/users/${userId}`, {
    permissions: newPermissions,
  });
  return parseOrganisationMemberResponse(data);
}

export async function deleteUser(userId: string, organisation: string): Promise<void> {
  await httpDelete(client(), `/organisations/${organisation}/users/${userId}`);
}

export async function forceDeleteUser(userId: string, organisation: string): Promise<void> {
  await httpDelete(client(), `/organisations/${organisation}/users/${userId}?force=true`);
}

export async function getUser(userId: string, organisation: string): Promise<OrganisationMemberWithConfig> {
  const data = await httpGet(client(), `/organisations/${organisation}/users/${userId}`);
  return parseOrganisationMemberWithConfigResponse(data);
}

export async function loadOrganisationInvitations(organisation: string): Promise<Invitation[]> {
  const data = await httpGet(client(), `/organisations/${organisation}/invitations`);
  return parseInvitationsResponse(data);
}

export async function invite(email: string, organisation: string): Promise<Invitation> {
  const data = await httpPost(client(), `/organisations/${organisation}/invitations`, { email });
  return parseInvitationResponse(data);
}

export async function deleteInvitation(email: string, organisation: string): Promise<void> {
  await httpDelete(client(), `/organisations/${organisation}/invitations/${email}`);
}

export async function loadUserInvitations(): Promise<Invitation[]> {
  const data = await httpGet(client(), '/organisations/invitations');
  return parseInvitationsResponse(data);
}

export async function acceptInvitation(organisation: string): Promise<OrganisationMember> {
  const data = await httpPatch(client(), `/organisations/${organisation}/invitations`, {
    accept: true,
  });
  return parseAcceptInvitationResponse(data);
}

export async function declineInvitation(organisation: string): Promise<void> {
  await httpPatch(client(), `/organisations/${organisation}/invitations`, { accept: false });
}

export async function loadOrganisationApplications(organisation: string): Promise<Application[]> {
  const data = await httpGet(client(), `/organisations/${organisation}/applications`);
  return parseOrganisationApplicationsResponse(data);
}

export async function loadUserApplications(): Promise<Application[]> {
  const data = await httpGet(client(), '/organisations/my/applications');
  return parseUserApplicationsResponse(data);
}

export async function apply(organisation: string): Promise<Application> {
  const data = await httpPost(client(), `/organisations/${organisation}/applications`, {});
  return parseApplicationResponse(data);
}

export async function withdrawApplication(application: Application): Promise<Application> {
  const data = await httpPatch(client(), `/organisations/${application.organisation}/applications/${application.id}`, {
    status: 'WITHDRAWN',
  });
  return parseApplicationResponse(data);
}

export async function approveApplication(application: Application): Promise<Application> {
  const data = await httpPatch(client(), `/organisations/${application.organisation}/applications/${application.id}`, {
    status: 'APPROVED',
  });
  return parseApplicationResponse(data);
}

export async function denyApplication(application: Application): Promise<Application> {
  const data = await httpPatch(client(), `/organisations/${application.organisation}/applications/${application.id}`, {
    status: 'DENIED',
  });
  return parseApplicationResponse(data);
}

export async function confirmQuestionnaireSubmission(organisation: string, questionnaireId: string): Promise<void> {
  await httpPost(client(), `/v1/organisations/${organisation}/questionnaire_submission/${questionnaireId}`, {});
}

export async function discardQuestionnaireSubmission(organisation: string, questionnaireId: string): Promise<void> {
  await httpDelete(client(), `/v1/organisations/${organisation}/questionnaire_submission/${questionnaireId}`);
}

export async function getDownloadUrls(): Promise<DownloadUrls> {
  const data = await httpGet(client(), '/latest', { publicApi: true });
  return parseDownloadUrlsResponse(data);
}

export async function loadOrgConfigs(organisations: string[]): Promise<Record<string, OrgConfig>> {
  const data = await httpGet(client(), '/configs', {
    config: {
      params: { organisations: organisations.join(',') },
      timeout: 40_000,
    },
  });
  return parseOrgConfigsResponse(data);
}

export function resetClient() {
  resetApiClient(WEBSITE_API_NAME);
}
