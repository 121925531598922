import React from 'react';
import { AppointmentBookingConfig } from '../../AppointmentBookingConfig';
import { MessagingConfig } from '../MessagingConfig/MessagingConfig';

export function MessagingConfigTab() {
  return (
    <>
      <MessagingConfig />
      <AppointmentBookingConfig />
    </>
  );
}
