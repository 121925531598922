import { GET_RULE_ANALYTICS_TIMEOUT, SCHEDULED_MESSAGING_API_NAME } from '../../util/constants';
import {
  Rule,
  RuleAnalytics,
  RuleExecutions,
  RuleOrganisationConfig,
  RuleStats,
  ScheduledMessagingOrganisationConfig,
} from '../../domain/ScheduledMessaging';
import { AxiosInstance } from 'axios';
import { callApi, getApiClient, resetApiClient } from '../../util/api';

function client(): AxiosInstance {
  return getApiClient(SCHEDULED_MESSAGING_API_NAME);
}

export async function loadRules(): Promise<Rule[]> {
  return callApi(client().get, '/rules') as Promise<Rule[]>;
}

export async function removeRule(organisation: string, ruleId: string): Promise<ScheduledMessagingOrganisationConfig> {
  return callApi(client().delete, `/organisations/${organisation}/rules/${ruleId}`);
}

export async function updateRule(organisation: string, ruleId: string, data: Rule): Promise<Rule> {
  const { metadata, ...rest } = data;

  return callApi(client().put, `/organisations/${organisation}/rules/${ruleId}`, rest);
}

export async function loadScheduledMessagingConfig(
  organisation: string,
): Promise<ScheduledMessagingOrganisationConfig> {
  return callApi(
    client().get,
    `/organisations/${organisation}/config`,
  ) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function updateScheduledMessagingOrganisationConfig(
  organisation: string,
  available?: boolean,
  reportsRecipientUserId?: string | null,
  partnerApiCredentials?: { username: string; password: string },
): Promise<ScheduledMessagingOrganisationConfig> {
  return callApi(client().patch, `/organisations/${organisation}/config`, {
    available,
    reportsRecipientUserId,
    partnerApiCredentials,
  }) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function updateRuleConfig(
  organisation: string,
  ruleId: string,
  ruleConfig: RuleOrganisationConfig,
): Promise<ScheduledMessagingOrganisationConfig> {
  return callApi(
    client().put,
    `/organisations/${organisation}/rules/${ruleId}/config`,
    ruleConfig,
  ) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function removeRuleConfig(
  organisation: string,
  ruleId: string,
): Promise<ScheduledMessagingOrganisationConfig> {
  return callApi(
    client().delete,
    `/organisations/${organisation}/rules/${ruleId}/config`,
  ) as Promise<ScheduledMessagingOrganisationConfig>;
}

export async function loadRuleExecutionHistory(organisation: string, ruleId: string): Promise<RuleExecutions> {
  return callApi(client().get, `/organisations/${organisation}/rules/${ruleId}/executions`) as Promise<RuleExecutions>;
}

export async function generateReportUrl(executionId: string): Promise<string> {
  const [, organisation, ruleId, date] = executionId.match(/(.+)#(.+)#(.+)/)!;

  return callApi(
    client().get,
    `/organisations/${organisation}/rules/${ruleId}/executions/${date}/report`,
  ) as Promise<string>;
}

export async function triggerRule(organisation: string, ruleId: string): Promise<unknown> {
  return callApi(client().post, `/organisations/${organisation}/rules/${ruleId}/executions`, {});
}

export async function loadRuleStats(organisation: string, ruleId: string): Promise<RuleStats> {
  return callApi(client().get, `/organisations/${organisation}/rules/${ruleId}/stats`) as Promise<RuleStats>;
}

export async function loadRuleAnalytics(organisation: string, ruleId: string): Promise<RuleAnalytics> {
  return callApi(client().get, `/organisations/${organisation}/rules/${ruleId}/analytics`, undefined, {
    timeout: GET_RULE_ANALYTICS_TIMEOUT,
    params: {
      includePatients: true,
    },
  }) as Promise<RuleAnalytics>;
}

export async function checkPartnerApiCredentials(
  organisation: string,
  username: string,
  password: string,
): Promise<void> {
  return callApi(client().post, `/organisations/${organisation}/partner-api/check`, {
    username,
    password,
  }) as Promise<void>;
}

export function resetClient() {
  resetApiClient(SCHEDULED_MESSAGING_API_NAME);
}
