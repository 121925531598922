import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import React, { useContext } from 'react';
import RulesContext from '../../../../context/RulesContext/RulesContext';
import { useDialog } from '../../../../hooks/useDialog/useDialog';
import { useRuleExecutionHistory } from '../../../../hooks/useRuleExecutions/useRuleExecutions';
import { LoadingActionButton } from '../../../shared/LoadingActionButton';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../../shared/ConfirmationDialog/ConfirmationDialog';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../../../../redux/reducers/notificationsReducer';

function DeleteRecallConfirmationContent(name: string) {
  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        Are you sure you want to delete the recall{' '}
        <Typography variant="body1" display="inline" component="strong" fontWeight="medium">
          {name}
        </Typography>
        ?
      </Typography>
      <Typography variant="body1">
        Confirming will permanently delete this recall for all members of your organisation. All associated reports
        (executions and analytics) will no longer be available.
      </Typography>
      <Alert severity="info">
        To temporarily prevent the recall from executing, you can disable the recall instead.
      </Alert>
    </Stack>
  );
}

export function RuleManagementActions() {
  const { getRequiredSelectedRule, removeRuleConfig, removeRule } = useContext(RulesContext);
  const { open, connector } = useDialog<ConfirmationDialogConfig, void>();
  const dispatch = useDispatch();

  const { id: ruleId, definition: rule } = getRequiredSelectedRule();

  const { history, loading, error } = useRuleExecutionHistory();

  const isOrganisationRecall = Boolean(rule.organisation);

  function handleDeleteRecall() {
    open({
      title: 'Delete recall',
      content: DeleteRecallConfirmationContent(rule.name),
    }).then(async () => {
      try {
        if (isOrganisationRecall) {
          // This is an organisation-specific recall, so we remove both the rule and the configuration
          await removeRule(ruleId); // The backend will cascade and also remove the configuration
        } else {
          // This recall has a shared rule, so we only remove the configuration for this organisation
          await removeRuleConfig(ruleId);
        }

        dispatch(enqueueSuccessNotification(`Recall ${rule.name} deleted`));
      } catch (error) {
        dispatch(enqueueErrorNotification(`Unable to delete recall ${rule.name}`, error));
      }
    });
  }

  if (loading) {
    return (
      <Button variant="contained" loading startIcon={<DeleteIcon />}>
        Delete recall
      </Button>
    );
  }

  if (history === undefined || error) {
    return (
      <>
        <Alert severity="error">
          This recall cannot safely be deleted due to an error loading its execution history. Please try reloading the
          page or contact{' '}
          <Link underline="always" target="_blank" href="mailto:support@abtrace.co">
            support@abtrace.co
          </Link>
          .
        </Alert>

        <Button variant="contained" disabled startIcon={<DeleteIcon />}>
          Delete recall
        </Button>
      </>
    );
  }

  const hasExecutionCurrentlyRunning = history.some((execution) => execution.status === 'RUNNING');

  if (hasExecutionCurrentlyRunning) {
    return (
      <>
        <Alert severity="warning">
          This recall is currently executing and cannot safely be deleted. Please disable the recall to prevent further
          executions and try again later.
        </Alert>

        <Button variant="contained" disabled startIcon={<DeleteIcon />}>
          Delete recall
        </Button>
      </>
    );
  }

  return (
    <>
      <LoadingActionButton variant="contained" onClick={handleDeleteRecall} startIcon={<DeleteIcon />}>
        Delete recall
      </LoadingActionButton>

      <ConfirmationDialog connector={connector} />
    </>
  );
}
