import { z } from 'zod';

const bulkMessagingReportSummary = z.object({
  emailsSent: z.number().or(z.literal('NOT_APPLICABLE')),
  smsSent: z.number().or(z.literal('NOT_APPLICABLE')),
  notContacted: z.number(),
  patientRecordUpdateErrors: z.number(),
  hadPatientsOpenInTpp: z.boolean(),
});

const bulkMessageReportOverview = z.object({
  reportId: z.string(),
  organisation: z.string(),
  sender: z.string(),
  timestamp: z.string().pipe(z.coerce.date()),
  summary: bulkMessagingReportSummary,
});

export type BulkMessageReportOverview = z.infer<typeof bulkMessageReportOverview>;

const getBulkMessageReportsResponse = z.object({
  reports: z.array(bulkMessageReportOverview),
  cursor: z.string().nullish(),
});

export type GetBulkMessageReportsResponse = z.infer<typeof getBulkMessageReportsResponse>;

export function parseGetBulkMessageReportsResponse(response: unknown): GetBulkMessageReportsResponse {
  return getBulkMessageReportsResponse.parse(response);
}

const getBulkMessageReportResponse = z.object({
  reportId: z.string(),
  timestamp: z.string().pipe(z.coerce.date()),
  details: z.array(
    z.object({
      nhsNumber: z.string(),
      emailStatus: z.string().nullish(),
      smsStatus: z.string().nullish(),
      notes: z.string().nullish(),
    }),
  ),
  summary: bulkMessagingReportSummary,
});

export type GetBulkMessageReportResponse = z.infer<typeof getBulkMessageReportResponse>;

export function parseGetBulkMessageReportResponse(response: unknown): GetBulkMessageReportResponse {
  return getBulkMessageReportResponse.parse(response);
}
