import { OrganisationDetails } from '../../domain/Organisation';
import dayjs from 'dayjs';
import { ScheduledMessagingOrganisationConfig } from '../../domain/ScheduledMessaging';
import { MessagingConfig } from '../../domain/Messaging';
import { loadOrgConfigs } from '../../services/Api';

type WebsiteConfig = {
  beta_user_count: number;
};

type QofoptConfig = {
  maxRecommendations: number;
};

type AppointmentsConfig = {
  enabled: boolean;
  organisationEmail: {
    address: string;
    verified: boolean;
  };
};

export type OrgConfig = {
  website: WebsiteConfig;
  scheduled: ScheduledMessagingOrganisationConfig;
  messaging: MessagingConfig;
  qofopt: QofoptConfig;
  appointments: AppointmentsConfig;
};

export type OrgDetails = {
  name: string;
  id: string;
  ehr: string;
  contractExpiryDate: Date | null;
  bulkMessagingEnabled: boolean;
  singleMessagingEnabled: boolean;
  enabled: boolean;
  email: string | null;
  numOfBetaUsers: number;
  maxRecommendations: number | string;
  smsAllowance: number;
  automatedMessagingEnabled: boolean;
  numOfAutomatedMessagingSetUp: number;
  partnerApiSetUp: boolean;
  lastFullExtractDate?: Date;
  lastDelta?: Date;
  validDate?: Date;
  onSiteEncryption?: boolean;
  populationTabBetaVisible?: boolean;
};

const createOrgDetails = (
  orgId: string,
  orgConfig: OrgConfig,
  organisationInfo: OrganisationDetails | null,
): OrgDetails => {
  const { website, scheduled, messaging, qofopt, appointments } = orgConfig;

  const numberOfEnabledRules = scheduled.rules
    ? Object.values(scheduled.rules).filter((rule) => rule.enabled).length
    : 0;

  return {
    name: organisationInfo?.name ?? '',
    id: orgId,
    ehr: organisationInfo?.ehr ?? '',
    contractExpiryDate: organisationInfo?.contractExpiryDate
      ? dayjs(organisationInfo?.contractExpiryDate).toDate()
      : null,
    bulkMessagingEnabled: messaging.config.bulkMessagingEnabled,
    singleMessagingEnabled: messaging.config.singleMessagingEnabled,
    enabled: appointments.enabled,
    email: appointments.organisationEmail ? appointments.organisationEmail.address : null,
    numOfBetaUsers: website.beta_user_count,
    maxRecommendations: qofopt.maxRecommendations,
    smsAllowance: messaging.stats.SMS.limit ?? 0,
    automatedMessagingEnabled: scheduled.available,
    numOfAutomatedMessagingSetUp: numberOfEnabledRules,
    partnerApiSetUp: !!scheduled.partnerApiCredentials?.username && !!scheduled.partnerApiCredentials?.password,
    onSiteEncryption: organisationInfo?.onSiteEncryption,
    populationTabBetaVisible: organisationInfo?.populationTabBetaVisible,
  };
};

export const loadOrganisationDetails = async (
  organisations: string[],
  organisationInfo: Record<string, OrganisationDetails | null>,
): Promise<OrgDetails[]> => {
  const orgConfigs = await loadOrgConfigs(organisations);
  return Object.keys(orgConfigs).map((orgId) => createOrgDetails(orgId, orgConfigs[orgId], organisationInfo[orgId]));
};
