import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useCurrentOrganisationDetails } from '../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';

export function OrganisationName() {
  const { organisation, loading } = useCurrentOrganisationDetails();

  return (
    <Box role="title">
      <Stack direction="row" gap={2} alignItems="center">
        <Stack direction="row" gap={1} alignItems="baseline">
          {organisation?.name ? (
            <>
              <Typography variant="h4">{organisation?.name}</Typography>
              <Typography variant="h5" color="text.secondary">
                {organisation.organisation}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h4">{organisation?.organisation}</Typography>
              {loading && <CircularProgress data-testid="spinner" size="1.5rem" />}
            </>
          )}
        </Stack>

        {organisation?.ehr && <Chip size="small" color="info" label={organisation?.ehr.toLocaleUpperCase()} />}
      </Stack>
    </Box>
  );
}
