import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  forceDeleteOrganisationEmail,
  loadOrganisationEmails,
  registerOrResendOrganisationEmail,
} from '../../apis/app/AppApi';
import { OrganisationEmail } from '../../domain/Organisation';
import { ORGANISATION_EMAILS_QUERY_KEY, ORGANISATION_EMAILS_QUERY_STALE_TIME } from '../../util/queries';

interface UseOrganisationEmailsResult {
  emails: OrganisationEmail[];
  isLoading: boolean;
  error: Error | null;
  registerEmail: (email: string) => Promise<void>;
  removeEmail: (email: string) => Promise<void>;
  resendVerificationEmail(email: string): Promise<void>;
}

export function useOrganisationEmails(organisation: string): UseOrganisationEmailsResult {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: [ORGANISATION_EMAILS_QUERY_KEY, organisation],
    queryFn: () => loadOrganisationEmails(organisation),
    staleTime: ORGANISATION_EMAILS_QUERY_STALE_TIME,
    retry: false,
  });

  const registerEmailMutation = useMutation({
    mutationFn: async (email: string) => await registerOrResendOrganisationEmail(organisation, email),
    onSuccess: (data, _variables) => {
      queryClient.setQueryData<OrganisationEmail[]>(
        [ORGANISATION_EMAILS_QUERY_KEY, organisation],
        (previousEmails: OrganisationEmail[] | undefined) =>
          previousEmails ? [...previousEmails, data] : previousEmails,
      );
    },
  });

  const removeEmailMutation = useMutation({
    mutationFn: async (email: string) => forceDeleteOrganisationEmail(organisation, email),
    onSuccess: (_data, variables) => {
      queryClient.setQueryData<OrganisationEmail[]>(
        [ORGANISATION_EMAILS_QUERY_KEY, organisation],
        (previousEmails: OrganisationEmail[] | undefined) =>
          previousEmails ? previousEmails.filter((email) => email.email !== variables) : previousEmails,
      );
    },
  });

  async function registerEmail(email: string): Promise<void> {
    await registerEmailMutation.mutateAsync(email);
  }

  async function removeEmail(email: string): Promise<void> {
    await removeEmailMutation.mutateAsync(email);
  }

  async function resendVerificationEmail(email: string): Promise<void> {
    await registerOrResendOrganisationEmail(organisation, email);
  }

  return {
    emails: data ?? [],
    isLoading,
    error,
    registerEmail,
    removeEmail,
    resendVerificationEmail,
  };
}
