import React, { useContext, useEffect, useState } from 'react';
import rulesContext from '../../../../context/RulesContext/RulesContext';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { RuleDefinition } from '../RuleDefinition/RuleDefinition';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../../hooks/useDialog/useDialog';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../../../../redux/reducers/notificationsReducer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import userContext from '../../../../context/UserContext';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useRuleStats } from '../../../../hooks/useRuleStats/useRuleStats';
import Tooltip from '@mui/material/Tooltip';
import { EditHelper } from '../../../shared/EditHelper/EditHelper';
import { isOrgAdmin } from '../../../../domain/User';

function TextFieldWithCharCount(props: TextFieldProps & { value: string; charLimit: number }) {
  const { charLimit, value, error, helperText, ...rest } = props;

  return (
    <Box sx={(theme) => ({ ':focus-within': { '.CharCount': { color: theme.palette.primary.main } } })} width="100%">
      <Box position="relative" width="100%">
        <TextField {...rest} value={value} error={error} fullWidth />
        <FormHelperText
          className="CharCount"
          error={error}
          sx={{
            position: 'absolute',
            background: 'white',
            bottom: 0,
            right: 8,
            margin: 0,
            padding: '5px',
            translate: '0 50%',
          }}
        >
          Character count: {value.length}/{charLimit}
        </FormHelperText>
      </Box>
      {helperText && (
        <FormHelperText error={error} sx={{ ml: '14px', mt: '3px' }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

export function RuleOverviewTab() {
  const { getRequiredSelectedRule, removeRuleConfig, removeRule, updateRule } = useContext(rulesContext);

  const [isRemovingConfig, setRemovingConfig] = useState(false);
  const [isRemovingRule, setRemovingRule] = useState(false);

  const { id: ruleId, definition: rule, config } = getRequiredSelectedRule();

  const { stats, calculatingStats, calculateStats, statsError } = useRuleStats(ruleId);

  const { open, connector } = useDialog<ConfirmationDialogConfig, void>();

  const { user } = useContext(userContext);
  const isSuperUser = user?.superUser ?? false;
  const isSupportUser = user?.supportUser ?? false;
  const isAdmin = isOrgAdmin(user, rule.organisation);
  const canRemoveStuff = isSuperUser || isSupportUser;
  const canRemoveRule = canRemoveStuff && !config.enabled;

  const dispatch = useDispatch();

  useEffect(() => {
    if (statsError) {
      dispatch(enqueueErrorNotification('Unable to calculate the number of matching patients', statsError));
    }
  }, [statsError]);

  function verifyConfigRemoval() {
    open({
      title: 'Rule config removal',
      text: `Are you sure you want to remove the config for rule ${rule.name} from this organisation?`,
    }).then(async () => {
      setRemovingConfig(true);

      try {
        await removeRuleConfig(ruleId);
        dispatch(enqueueSuccessNotification('Rule config removed'));
      } catch (error) {
        dispatch(enqueueErrorNotification('Unable to remove the rule config', error));
      } finally {
        setRemovingConfig(false);
      }
    });
  }

  function verifyRuleRemoval() {
    open({
      title: 'Rule removal',
      text: `This will remove the actual global rule, not just the configuration associated with the organisation.
             Are you sure you want to remove the rule ${rule.name} and its associated configuration from this organisation?`,
    }).then(async () => {
      setRemovingRule(true);

      try {
        await removeRule(ruleId);
        dispatch(enqueueSuccessNotification('Rule removed'));
      } catch (error) {
        dispatch(enqueueErrorNotification('Unable to remove the rule', error));
      } finally {
        setRemovingRule(false);
      }
    });
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="start" gap={3}>
      <EditHelper
        defaultValues={rule}
        onDefaultValuesChange={(state) => state.startViewMode()}
        onSubmit={async (values) => {
          values = {
            ...values,
            name: values.name.trim(),
            description: values.description.trim(),
          };

          return updateRule(rule.id, values);
        }}
        onSuccess={(state) => state.startViewMode()}
      >
        {(state) => {
          const { EditButton, SubmitButton, DiscardButton, useTextFieldController } = EditHelper;
          const { isEditMode, submissionError } = state;
          const name = useTextFieldController({
            ...state,
            name: 'name',
            rules: {
              required: 'Recall name cannot be empty',
              maxLength: { value: 100, message: 'Recall name cannot exceed 100 characters' },
            },
          });
          const description = useTextFieldController({
            ...state,
            name: 'description',
            rules: {
              required: 'Recall description cannot be empty',
              maxLength: { value: 800, message: 'Recall description cannot exceed 800 characters' },
            },
          });
          const isOrgRule = Boolean(rule.organisation);
          const isEditable = isOrgRule && (isAdmin || isSuperUser);

          if (isEditMode) {
            return (
              <Box display="flex" flexDirection="column" alignItems="start" gap={3} width="100%">
                <TextFieldWithCharCount {...name} label="Recall name" charLimit={100} multiline />
                <TextFieldWithCharCount
                  {...description}
                  charLimit={800}
                  label="Recall description"
                  minRows={3}
                  multiline
                />

                {submissionError && (
                  <Alert severity="error" sx={{ p: { color: 'inherit' } }}>
                    <Typography mb={1}>There was an error updating recall name or description.</Typography>
                    <Typography>
                      Please contact{' '}
                      <Link underline="always" href="mailto:support@abtrace.co" target="_blank">
                        support@abtrace.co
                      </Link>{' '}
                      if the issue persists.
                    </Typography>
                  </Alert>
                )}

                <Box display="flex" gap={3}>
                  <SubmitButton {...state} />
                  <DiscardButton {...state} />
                </Box>
              </Box>
            );
          }

          return (
            <Box display="flex" flexDirection="column" alignItems="start" gap={3} width="100%">
              <Typography
                maxWidth={600}
                variant="body1"
                whiteSpace="pre-wrap"
                textAlign="justify"
                color="text.secondary"
              >
                {description.value}
              </Typography>
              {isEditable && (
                <EditButton {...state} size="small" sx={{ textTransform: 'none' }}>
                  Edit recall name and description
                </EditButton>
              )}
            </Box>
          );
        }}
      </EditHelper>

      <Alert severity="info" icon={false}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography>Number of patients matching the rule:</Typography>
          <Typography>
            <strong>{stats ? Number(stats!.matchingPatients).toLocaleString() : 'Undetermined'}</strong>
          </Typography>

          <Tooltip title="Refresh number">
            <IconButton color="info" disabled={calculatingStats} onClick={calculateStats}>
              <RefreshIcon
                role={calculatingStats ? 'progressbar' : ''}
                sx={
                  calculatingStats
                    ? {
                        animation: 'spin 1s linear infinite',
                        '@keyframes spin': {
                          '100%': {
                            transform: 'rotate(360deg)',
                          },
                        },
                      }
                    : {}
                }
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Alert>

      <RuleDefinition rule={rule} />

      {canRemoveStuff && (
        <>
          <Divider sx={{ width: '100%' }} />

          <Box display="flex" gap={3}>
            <LoadingButton
              loading={isRemovingConfig}
              variant="contained"
              onClick={verifyConfigRemoval}
              startIcon={<DeleteIcon />}
            >
              Remove rule config
            </LoadingButton>

            <LoadingButton
              loading={isRemovingRule}
              variant="contained"
              onClick={verifyRuleRemoval}
              disabled={!canRemoveRule}
              startIcon={<DeleteIcon />}
            >
              Remove rule
            </LoadingButton>
          </Box>

          <ConfirmationDialog connector={connector} />
        </>
      )}
    </Box>
  );
}
