import { AxiosInstance } from 'axios';
import {
  OrganisationContactDetails,
  PatientTasksConfiguration,
  PatientTasksDefaultDescriptionPerStatus,
} from '../../domain/PatientFacing';
import { getApiClient, httpGet, httpPut, resetApiClient } from '../../util/api';
import { PATIENT_FACING_API_NAME } from '../../util/constants';

function client(): AxiosInstance {
  return getApiClient(PATIENT_FACING_API_NAME);
}

export async function loadContactUsPageConfig(organisation: string): Promise<OrganisationContactDetails | null> {
  return httpGet(client(), `/organisations/${organisation}/contact-us-page`, { nullOnError: true });
}

export async function saveContactUsPageConfig(
  organisation: string,
  config: OrganisationContactDetails,
): Promise<OrganisationContactDetails> {
  return httpPut(
    client(),
    `/organisations/${organisation}/contact-us-page`,
    config,
  ) as Promise<OrganisationContactDetails>;
}

export async function loadPatientTasksConfig(organisation: string): Promise<PatientTasksConfiguration | null> {
  return httpGet(client(), `/organisations/${organisation}/patient-tasks`, { nullOnError: true });
}

export async function savePatientTasksConfig(
  organisation: string,
  config: PatientTasksConfiguration,
): Promise<PatientTasksConfiguration> {
  return httpPut(
    client(),
    `/organisations/${organisation}/patient-tasks`,
    config,
  ) as Promise<PatientTasksConfiguration>;
}

export async function loadPatientTasksDefaultDescriptions(): Promise<PatientTasksDefaultDescriptionPerStatus> {
  return httpGet(client(), '/patient-tasks/default-descriptions') as Promise<PatientTasksDefaultDescriptionPerStatus>;
}

export function resetClient() {
  resetApiClient(PATIENT_FACING_API_NAME);
}
