import { SignatureType } from '../domain/Messaging';
import { OrganisationDetails } from '../domain/Organisation';

export function buildEmailSignature(organisationDetails: OrganisationDetails, signatureType: SignatureType) {
  const { address, name, organisation } = organisationDetails;

  if (signatureType === 'NAME_ONLY') {
    return name || organisation;
  }

  const { line1, line2, line3, line4, town, postcode } = address || {};
  const line0 = name || organisation;
  let line5 = '';

  if (town && postcode) {
    line5 = `${town}, ${postcode}`;
  }

  return [line0, line1, line2, line3, line4, line5].filter((line) => line).join('\n');
}
