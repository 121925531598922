import { useInfiniteQuery } from '@tanstack/react-query';
import { BULK_MESSAGE_REPORTS_QUERY_KEY, BULK_MESSAGE_REPORTS_STALE_TIME } from '../../util/queries';
import { useCurrentOrganisationDetails } from '../useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { BulkMessageReportOverview } from '../../apis/messaging/model/response';
import { loadBulkMessageReports } from '../../apis/messaging/MessagingApi';

interface UseBulkMessageReportsInput {
  senderFilter?: string;
  limit?: number;
  cursor?: string | null;
}

interface UseBulkMessageReportsResult {
  reports: BulkMessageReportOverview[] | undefined;
  cursor: string | null | undefined;
  loading: boolean;
  error: unknown;
  fetchMore: () => void;
}

export function useBulkMessageReports({
  senderFilter,
  limit,
}: UseBulkMessageReportsInput): UseBulkMessageReportsResult {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const { data, isPending, isLoading, isFetchingNextPage, error, fetchNextPage } = useInfiniteQuery({
    queryKey: [BULK_MESSAGE_REPORTS_QUERY_KEY, organisation, senderFilter, limit],
    queryFn: (context) => loadBulkMessageReports(organisation, senderFilter, limit, context.pageParam),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage.cursor,
    staleTime: BULK_MESSAGE_REPORTS_STALE_TIME,
    retry: false,
  });

  function fetchMore() {
    fetchNextPage();
  }

  const reports = data?.pages.flatMap((page) => page.reports);
  const cursor = data?.pages[data?.pages.length - 1].cursor;

  return {
    reports,
    cursor,
    loading: isPending || isLoading || isFetchingNextPage,
    error,
    fetchMore,
  };
}
