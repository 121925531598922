import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as domain from '../../../domain/Optimisation';
import { enqueueErrorNotification } from '../../../redux/reducers/notificationsReducer';
import * as QofOptApi from '../../../apis/qofOpt/QofOptApi';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import { LoadingSlider } from '../../shared/LoadingSlider/LoadingSlider';

const ALL = 'ALL';
const ALL_VALUE = 225;

const StyledFormControlLabel = styled(FormControlLabel)({
  '& .slider-label': {
    minWidth: 250,
  },
});

interface Props {
  organisation: string;
  enabled: boolean;
}

export function OptimisationConfig({ organisation, enabled }: Props) {
  const marks = [
    {
      value: 0,
      label: 'None',
    },
    {
      value: 25,
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 75,
    },
    {
      value: 100,
      label: '100',
    },
    {
      value: 125,
    },
    {
      value: 150,
      label: '150',
    },
    {
      value: 175,
    },
    {
      value: 200,
      label: '200',
    },
    {
      value: ALL_VALUE,
      label: 'All',
    },
  ];

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [config, setConfig] = useState<domain.OptimisationConfig | null>(null);

  const dispatch = useDispatch();

  const maxRecommendationsToSlider: (maxRecommendations: number | string) => number = (
    maxRecommendations: number | string,
  ) => (maxRecommendations === 'ALL' ? ALL_VALUE : (maxRecommendations as number));

  const sliderToMaxRecommendations = (maxRecommendations: number): number | string =>
    maxRecommendations === ALL_VALUE ? ALL : maxRecommendations;

  const saveConfig = (newConfig: domain.OptimisationConfig) => {
    setSaving(true);
    QofOptApi.saveOptimisationConfig(organisation, newConfig)
      .catch((error) => {
        dispatch(enqueueErrorNotification('Unable to save the changes', error));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    QofOptApi.loadOptimisationConfig(organisation)
      .then((newConfig) => setConfig(newConfig ?? { maxRecommendations: 0 }))
      .catch((error) => {
        dispatch(enqueueErrorNotification('Unable to load the optimisation configuration', error));
      })
      .finally(() => setLoading(false));
  }, [organisation]);

  return (
    <LoadingSection loading={loading}>
      <Box maxWidth={700}>
        <FormControl fullWidth>
          <StyledFormControlLabel
            control={
              <LoadingSlider
                aria-label="Maximum recommendations"
                marks={marks}
                min={0}
                max={ALL_VALUE}
                step={null}
                value={maxRecommendationsToSlider(config?.maxRecommendations ?? 0)}
                onChange={(_ev, value) =>
                  setConfig({ ...config, maxRecommendations: sliderToMaxRecommendations(value as number) })
                }
                onChangeCommitted={(_ev, value) =>
                  saveConfig({ ...config, maxRecommendations: sliderToMaxRecommendations(value as number) })
                }
                loading={saving}
                disabled={!enabled || saving}
              />
            }
            label="Maximum recommendations:"
            labelPlacement="start"
            classes={{
              label: 'slider-label',
            }}
          />
        </FormControl>
      </Box>
    </LoadingSection>
  );
}
