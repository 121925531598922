import { useQuery } from '@tanstack/react-query';
import { SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_KEY } from '../../util/queries';
import { RuleExecutionReport } from '../../domain/ScheduledMessaging';
import { downloadReport, loadRuleExecution } from '../../apis/scheduledMessaging/ScheduledMessagingApi';
import { useContext } from 'react';
import UserContext from '../../context/UserContext';
import { isAdminAt } from '../../domain/User';

interface UseRuleExecutionResult {
  report?: RuleExecutionReport;
  downloadAllowed: boolean;
  downloadCsv?: () => Promise<{
    fileName: string;
    content: string;
  }>;
  loading: boolean;
  error: unknown;
}

export function useRuleExecution(executionId: string): UseRuleExecutionResult {
  const [, organisation, ruleId, date] = executionId.match(/(.+)#(.+)#(.+)/)!;

  const context = useContext(UserContext);
  const user = context.user;

  const downloadAllowed = !!user && isAdminAt(user, organisation);

  const { data, isPending, isLoading, error } = useQuery({
    queryKey: [SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_KEY, organisation, ruleId, date],
    queryFn: () => loadRuleExecution(organisation, ruleId, date),
    retry: false,
  });

  const downloadCsv = async () => {
    const content = await downloadReport(executionId);
    return {
      fileName: `report_${date}.csv`,
      content,
    };
  };

  return {
    report: data,
    downloadAllowed,
    downloadCsv: data && downloadAllowed ? downloadCsv : undefined,
    loading: isPending || isLoading,
    error,
  };
}
