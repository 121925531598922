import { AxiosInstance } from 'axios';
import { MessagingConfig, SignatureType } from '../../domain/Messaging';
import { MESSAGING_API_NAME } from '../../util/constants';
import { getApiClient, httpGet, httpPatch, resetApiClient } from '../../util/api';
import {
  GetBulkMessageReportResponse,
  GetBulkMessageReportsResponse,
  parseGetBulkMessageReportResponse,
  parseGetBulkMessageReportsResponse,
  parseMessagingConfigResponse,
} from './model/response';

function client(): AxiosInstance {
  return getApiClient(MESSAGING_API_NAME);
}

export async function loadMessagingConfig(organisation: string): Promise<MessagingConfig | null> {
  const data = await httpGet(client(), `/organisations/${organisation}/messages/config`, { nullOnError: true });
  return parseMessagingConfigResponse(data);
}

export async function updateMessagingConfig(
  organisation: string,
  payload: {
    singleMessagingEnabled?: boolean;
    bulkMessagingEnabled?: boolean;
    templateSignatureType?: SignatureType;
    senderId?: string | null;
    limits?: { sms: number };
  },
): Promise<void> {
  await httpPatch(client(), `/organisations/${organisation}/messages/config`, payload);
}

export async function loadBulkMessageReports(
  organisation: string,
  senderFilter: string | undefined,
  limit: number | undefined,
  cursor: string | undefined,
): Promise<GetBulkMessageReportsResponse> {
  const response = await httpGet(client(), `/organisations/${organisation}/reports`, {
    config: {
      params: { senderFilter, limit, cursor },
    },
  });

  return parseGetBulkMessageReportsResponse(response);
}

export async function loadBulkMessageReport(
  organisation: string,
  reportId: string,
  includeDetails: boolean,
): Promise<GetBulkMessageReportResponse> {
  const response = await httpGet(client(), `/organisations/${organisation}/reports/${reportId}`, {
    config: {
      params: {
        includeDetails,
      },
    },
  });

  return parseGetBulkMessageReportResponse(response);
}

export function resetClient() {
  resetApiClient(MESSAGING_API_NAME);
}
