import Refresh from '@mui/icons-material/Refresh';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { FieldError, FieldValues, useController, useFormContext, UseFormReturn } from 'react-hook-form';
import { useAppointmentBookingConfig } from '../../../../hooks/useAppointmentBookingConfig/useAppointmentBookingConfig';
import { useAppointmentSlotsConfig } from '../../../../hooks/useAppointmentSlotsConfig/useAppointmentSlotsConfig';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { getDuplicatesSet } from '../../../../util/array';
import { MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS } from '../../../../util/constants';
import { MultipleChoiceSelect } from '../../../shared/MultipleChoiceSelect/MultipleChoiceSelect';
import TextField from '../../../shared/TextField/TextField';
import { AppointmentBookingCheckbox } from '../AppointmentBookingCheckbox/AppointmentBookingCheckbox';

function AppointmentInvitationFiltersSlotTypeSelector({
  form,
  slotTypes,
  appointmentSlotsConfigLoading,
}: {
  form: UseFormReturn<FieldValues>;
  slotTypes?: Array<{ id: string; name: string }>;
  appointmentSlotsConfigLoading: boolean;
}) {
  const { field, fieldState } = useController({
    control: form.control,
    name: 'appointmentsSlotTypeIds',
    defaultValue: [],
    rules: {
      validate: (value, formValues) => {
        const { appointmentsSiteIds, appointmentsStaffIds } = formValues;

        if (appointmentsSiteIds.length === 0 && appointmentsStaffIds.length === 0 && value.length === 0) {
          return 'At least one site, staff, or slot type must be selected.';
        }

        return true;
      },
    },
  });

  const repeatedNames = useMemo(() => getDuplicatesSet(slotTypes?.map((slotType) => slotType.name) ?? []), [slotTypes]);

  const slotTypeOptions = useMemo(
    () =>
      slotTypes?.map((slotType) => ({
        value: slotType.id,
        label: slotType.name,
        sublabel: repeatedNames.has(slotType.name) ? slotType.id : '',
      })) ?? [],
    [slotTypes],
  );

  const handleOnChange = (appointmentSlotTypeIds: Array<string>) => {
    field.onChange(appointmentSlotTypeIds);

    form.trigger(['appointmentsSiteIds', 'appointmentsStaffIds', 'appointmentsSlotTypeIds']);
  };

  return (
    <FormControl sx={{ flex: 'auto' }}>
      <MultipleChoiceSelect
        label="Slot types"
        placeholder="Slot types"
        values={field.value}
        options={slotTypeOptions}
        noOptionsText="No slot types available"
        deletedOptionText="This slot type no longer seems to exist"
        loading={appointmentSlotsConfigLoading}
        onChange={handleOnChange}
        getInputParams={(params) => ({ ...params, error: !!fieldState.error })}
        slotProps={{ paper: { sx: { border: '1px solid', boxShadow: 1 } } }}
      />
    </FormControl>
  );
}

function AppointmentInvitationFiltersStaffSelector({
  form,
  staffs,
  appointmentSlotsConfigLoading,
}: {
  form: UseFormReturn<FieldValues>;
  staffs?: Array<{ id: string; name: string }>;
  appointmentSlotsConfigLoading: boolean;
}) {
  const { field, fieldState } = useController({
    control: form.control,
    name: 'appointmentsStaffIds',
    defaultValue: [],
    rules: {
      validate: (value, formValues) => {
        const { appointmentsSiteIds, appointmentsSlotTypeIds } = formValues;

        if (appointmentsSiteIds.length === 0 && value.length === 0 && appointmentsSlotTypeIds.length === 0) {
          return 'At least one site, staff, or slot type must be selected.';
        }

        return true;
      },
    },
  });

  const repeatedNames = useMemo(() => getDuplicatesSet(staffs?.map((staff) => staff.name) ?? []), [staffs]);

  const staffOptions = useMemo(
    () =>
      staffs?.map((staff) => ({
        value: staff.id,
        label: staff.name,
        sublabel: repeatedNames.has(staff.name) ? staff.id : '',
      })) ?? [],
    [staffs],
  );

  const handleOnChange = (appointmentStaffIds: Array<string>) => {
    field.onChange(appointmentStaffIds);

    form.trigger(['appointmentsSiteIds', 'appointmentsStaffIds', 'appointmentsSlotTypeIds']);
  };

  return (
    <FormControl sx={{ flex: 'auto' }}>
      <MultipleChoiceSelect
        label="Staff"
        placeholder="Staff"
        values={field.value}
        options={staffOptions}
        noOptionsText="No staff members available"
        deletedOptionText="This staff member no longer seems to exist"
        loading={appointmentSlotsConfigLoading}
        onChange={handleOnChange}
        getInputParams={(params) => ({ ...params, error: !!fieldState.error })}
        slotProps={{ paper: { sx: { border: '1px solid', boxShadow: 1 } } }}
      />
    </FormControl>
  );
}

function AppointmentInvitationFiltersSiteSelector({
  form,
  sites,
  appointmentSlotsConfigLoading,
}: {
  form: UseFormReturn<FieldValues>;
  sites?: Array<{ id: string; name: string }>;
  appointmentSlotsConfigLoading: boolean;
}) {
  const { field, fieldState } = useController({
    control: form.control,
    name: 'appointmentsSiteIds',
    defaultValue: [],
    rules: {
      validate: (value, formValues) => {
        const { appointmentsStaffIds, appointmentsSlotTypeIds } = formValues;

        if (value.length === 0 && appointmentsStaffIds.length === 0 && appointmentsSlotTypeIds.length === 0) {
          return 'At least one site, staff, or slot type must be selected.';
        }

        return true;
      },
    },
  });

  const repeatedNames = useMemo(() => getDuplicatesSet(sites?.map((site) => site.name) ?? []), [sites]);

  const siteOptions = useMemo(
    () =>
      sites?.map((site) => ({
        value: site.id,
        label: site.name,
        sublabel: repeatedNames.has(site.name) ? site.id : '',
      })) ?? [],
    [sites],
  );

  const handleOnChange = (appointmentSiteIds: Array<string>) => {
    field.onChange(appointmentSiteIds);

    form.trigger(['appointmentsSiteIds', 'appointmentsStaffIds', 'appointmentsSlotTypeIds']);
  };

  return (
    <FormControl sx={{ flex: 'auto' }}>
      <MultipleChoiceSelect
        label="Sites"
        placeholder="Sites"
        values={field.value}
        options={siteOptions}
        noOptionsText="No sites available"
        deletedOptionText="This site no longer seems to exist"
        loading={appointmentSlotsConfigLoading}
        onChange={handleOnChange}
        getInputParams={(params) => ({ ...params, error: !!fieldState.error, helperText: fieldState.error?.message })}
        slotProps={{ paper: { sx: { border: '1px solid', boxShadow: 1 } } }}
      />
    </FormControl>
  );
}

export function AppointmentBooking() {
  const form = useFormContext();

  const errors = form.formState.errors;

  const addInvitationLink = form.watch('appointmentsAddInvitationLink');

  const { config, loading } = useAppointmentBookingConfig();
  const { enabled } = config ?? { enabled: false };

  const {
    loading: appointmentSlotsConfigLoading,
    config: appointmentSlotsConfig,
    error: appointmentSlotsError,
    refetch: appointmentSlotsRefetch,
  } = useAppointmentSlotsConfig(!!addInvitationLink);

  const { organisation } = useCurrentOrganisationDetails();

  return (
    <Box sx={{ '& .MuiAlert-root': { color: 'text.primary' } }}>
      <AppointmentBookingCheckbox disabled={!enabled} opening={appointmentSlotsConfigLoading} />

      {!loading && !enabled && (
        <Box marginBottom={2}>
          <Alert severity="info">
            <Typography marginBottom={0.75}>
              Self-book is currently not available for your organisation — contact{' '}
              <Link underline="always" target="_blank" href="mailto:support@abtrace.co">
                support@abtrace.co
              </Link>{' '}
              for more information.
            </Typography>
            <Typography>
              Patients clicking this link will be taken to a web form where they can detail their availability. This
              will then be emailed to your practice.
            </Typography>
          </Alert>
        </Box>
      )}

      <Collapse in={addInvitationLink && !appointmentSlotsConfigLoading} timeout="auto" unmountOnExit={true}>
        {(() => {
          if (appointmentSlotsError) {
            return (
              <Box marginBottom={2}>
                <Box display="flex" flexDirection="column" gap={3}>
                  <Alert severity="error">
                    <Typography mb={1}>There was an error loading appointment slot configuration.</Typography>
                    <Typography>
                      You can retry or contact{' '}
                      <Link underline="always" href="mailto:support@abtrace.co" target="_blank">
                        support@abtrace.co
                      </Link>{' '}
                      if the issue persists.
                    </Typography>
                  </Alert>

                  <Button
                    startIcon={<Refresh />}
                    onClick={() => appointmentSlotsRefetch()}
                    sx={{ alignSelf: 'center', marginTop: -2, color: 'text.primary' }}
                  >
                    Click here to retry
                  </Button>
                </Box>
              </Box>
            );
          }

          if (appointmentSlotsConfigLoading) return null;

          return (
            <Stack gap={1.5} marginLeft="27px" marginBottom={2}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ marginBottom: 1 }}>
                  Allow patients to self-book an appointment within
                </FormLabel>
                <FormControlLabel
                  sx={{ marginLeft: 0 }}
                  control={
                    <TextField
                      name="appointmentsBookableWithinDays"
                      label="Days"
                      type="number"
                      control={form.control}
                      error={errors.appointmentsBookableWithinDays as FieldError}
                      rules={{
                        required: 'Days are required',
                        min: {
                          value: 1,
                          message:
                            organisation.ehr === 'emis'
                              ? `Must be between 1 and ${MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS} days, inclusive`
                              : 'Must be ≥ 1',
                        },
                        max:
                          organisation.ehr === 'emis'
                            ? {
                                value: MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS,
                                message: `Must be between 1 and ${MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS} days, inclusive`,
                              }
                            : undefined,
                      }}
                      helperText={(errors.appointmentsBookableWithinDays as FieldError)?.message}
                      size="small"
                      slotProps={{
                        htmlInput: { sx: { width: '60px' }, min: 1 },
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="span"
                      sx={{
                        marginLeft: 1,
                      }}
                    >
                      days after receiving the invitation
                    </Typography>
                  }
                />
              </FormControl>

              <AppointmentInvitationFiltersSlotTypeSelector
                form={form}
                slotTypes={appointmentSlotsConfig?.slotTypes}
                appointmentSlotsConfigLoading={appointmentSlotsConfigLoading}
              />
              <AppointmentInvitationFiltersStaffSelector
                form={form}
                staffs={appointmentSlotsConfig?.holders}
                appointmentSlotsConfigLoading={appointmentSlotsConfigLoading}
              />
              <AppointmentInvitationFiltersSiteSelector
                form={form}
                sites={appointmentSlotsConfig?.sites}
                appointmentSlotsConfigLoading={appointmentSlotsConfigLoading}
              />

              <TextField
                name={'appointmentsDescription'}
                control={form.control}
                error={errors.appointmentsDescription as FieldError}
                rules={{
                  required: 'Description is required',
                }}
                label="Description"
                required
                variant="outlined"
                size="small"
                fullWidth
                helperText={(errors.appointmentsDescription as FieldError)?.message ?? 'e.g., "Routine tests"'}
              />
            </Stack>
          );
        })()}
      </Collapse>
    </Box>
  );
}
