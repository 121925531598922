import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../util/urls';
import DownloadsPanel from '../../components/downloads/DownloadsPanel';

export const PostVerification: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box pt={8} mt={8}>
      <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Typography variant="h4">Thank you for verifying your account</Typography>
        <Typography variant="h5">You are now able to log in, please select an option from below</Typography>
        <Stack
          py={4}
          sx={{ width: '350px' }}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Box sx={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
            <Button fullWidth variant="contained" onClick={() => window.open('abtrace://')}>
              Launch the Abtrace tool
            </Button>
            <Typography variant="body1">Already installed on my computer</Typography>
          </Box>
          <Button fullWidth variant="contained" onClick={() => navigate(URLS.LOGIN)}>
            Go to the User Management website
          </Button>
        </Stack>
        <DownloadsPanel />
      </Stack>
    </Box>
  );
};
