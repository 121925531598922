import React, { useContext, useEffect } from 'react';
import RulesContext from '../../../../context/RulesContext/RulesContext';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { RuleDefinition } from '../RuleDefinition/RuleDefinition';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import UserContext from '../../../../context/UserContext';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useRuleStats } from '../../../../hooks/useRuleStats/useRuleStats';
import Tooltip from '@mui/material/Tooltip';
import { EditHelper, EditHelperState, useTextFieldController } from '../../../shared/EditHelper/EditHelper';
import { isOrgAdmin } from '../../../../domain/User';
import { RuleManagementActions } from '../RuleManagementActions/RuleManagementActions';
import { Rule } from '../../../../domain/ScheduledMessaging';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';

function TextFieldWithCharCount(props: TextFieldProps & { value: string; charLimit: number }) {
  const { charLimit, value, error, helperText, ...rest } = props;

  return (
    <Box sx={(theme) => ({ ':focus-within': { '.CharCount': { color: theme.palette.primary.main } } })} width="100%">
      <Box position="relative" width="100%">
        <TextField {...rest} value={value} error={error} fullWidth />
        <FormHelperText
          className="CharCount"
          error={error}
          sx={{
            position: 'absolute',
            background: 'white',
            bottom: 0,
            right: 8,
            margin: 0,
            padding: '5px',
            translate: '0 50%',
          }}
        >
          Character count: {value.length}/{charLimit}
        </FormHelperText>
      </Box>
      {helperText && (
        <FormHelperText error={error} sx={{ ml: '14px', mt: '3px' }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

function RuleEditor({ state, isEditable }: { state: EditHelperState<Rule, unknown>; isEditable: boolean }) {
  const { EditButton, SubmitButton, DiscardButton } = EditHelper;
  const { isEditMode, submissionError } = state;

  const name = useTextFieldController({
    ...state,
    name: 'name',
    rules: {
      required: 'Recall name cannot be empty',
      maxLength: { value: 100, message: 'Recall name cannot exceed 100 characters' },
    },
  });
  const description = useTextFieldController({
    ...state,
    name: 'description',
    rules: {
      required: 'Recall description cannot be empty',
      maxLength: { value: 800, message: 'Recall description cannot exceed 800 characters' },
    },
  });

  if (isEditMode) {
    return (
      <Box display="flex" flexDirection="column" alignItems="start" gap={3} width="100%">
        <TextFieldWithCharCount {...name} label="Recall name" charLimit={100} multiline />
        <TextFieldWithCharCount {...description} charLimit={800} label="Recall description" minRows={3} multiline />

        {!!submissionError && (
          <Alert severity="error" sx={{ p: { color: 'inherit' } }}>
            <Typography mb={1}>There was an error updating recall name or description.</Typography>
            <Typography>
              Please contact{' '}
              <Link underline="always" href="mailto:support@abtrace.co" target="_blank">
                support@abtrace.co
              </Link>{' '}
              if the issue persists.
            </Typography>
          </Alert>
        )}

        <Box display="flex" gap={3}>
          <SubmitButton {...state} />
          <DiscardButton {...state} />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="start" gap={3} width="100%">
      <Typography maxWidth={600} variant="body1" whiteSpace="pre-wrap" textAlign="justify" color="text.secondary">
        {description.value}
      </Typography>
      {isEditable && (
        <EditButton {...state} size="small" sx={{ textTransform: 'none' }}>
          Edit recall name and description
        </EditButton>
      )}
    </Box>
  );
}

export function RuleOverviewTab() {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();
  const { getRequiredSelectedRule, updateRule } = useContext(RulesContext);

  const { id: ruleId, definition: rule } = getRequiredSelectedRule();

  const { stats, calculatingStats, calculateStats, statsError } = useRuleStats(ruleId);

  const { user } = useContext(UserContext);
  const isSuperUser = user?.superUser ?? false;
  const isAdmin = isOrgAdmin(user, organisation);

  const isOrganisationRecall = organisation === rule.organisation;

  const canManageAbtraceRecalls = isSuperUser;
  const canManageOrganisationRecalls = isSuperUser || isAdmin;
  const canManageThisRecall = isOrganisationRecall ? canManageOrganisationRecalls : canManageAbtraceRecalls;

  const dispatch = useDispatch();

  useEffect(() => {
    if (statsError) {
      dispatch(enqueueErrorNotification('Unable to calculate the number of matching patients', statsError));
    }
  }, [statsError]);

  return (
    <Box display="flex" flexDirection="column" alignItems="start" gap={3}>
      <EditHelper
        defaultValues={rule}
        onDefaultValuesChange={(state) => state.startViewMode()}
        onSubmit={async (values) => {
          values = {
            ...values,
            name: values.name.trim(),
            description: values.description.trim(),
          };

          return updateRule(rule.id, values);
        }}
        onSuccess={(state) => state.startViewMode()}
      >
        {(state) => <RuleEditor state={state} isEditable={isOrganisationRecall && canManageOrganisationRecalls} />}
      </EditHelper>

      <Alert severity="info" icon={false}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography>Number of patients matching the rule:</Typography>
          <Typography>
            <strong>{stats ? Number(stats!.matchingPatients).toLocaleString() : 'Undetermined'}</strong>
          </Typography>

          <Tooltip title="Refresh number">
            <IconButton color="info" disabled={calculatingStats} onClick={calculateStats}>
              <RefreshIcon
                role={calculatingStats ? 'progressbar' : ''}
                sx={
                  calculatingStats
                    ? {
                        animation: 'spin 1s linear infinite',
                        '@keyframes spin': {
                          '100%': {
                            transform: 'rotate(360deg)',
                          },
                        },
                      }
                    : {}
                }
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Alert>

      <RuleDefinition rule={rule} />

      {(canManageAbtraceRecalls || canManageOrganisationRecalls) && (
        <>
          <Divider sx={{ width: '100%' }} />

          {canManageThisRecall ? (
            <RuleManagementActions />
          ) : (
            <Alert severity="info">
              This is an Abtrace preset automated recall. You can disable it to prevent it from executing. It can be
              permanently removed on request. Contact{' '}
              <Link underline="always" target="_blank" href="mailto:support@abtrace.co">
                support@abtrace.co
              </Link>{' '}
              for assistance.
            </Alert>
          )}
        </>
      )}
    </Box>
  );
}
